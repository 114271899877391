import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import useSetSelectValue from 'apps/PhoneSystem/hooks/useSetSelectValue';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { nameFormatter, remapEndpoints } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchMediasQuery } from 'models/Media';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { SelectInstance } from 'react-select';
import { DialogType } from 'shared/components/Dialog';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { DragIndex, TableType } from 'shared/components/Table';
import { selectOptionMapper } from '../../utility';
import CallflowActionsDialog from '../CallflowActionDialog';
import AddMediaDialog from '../MediaDialog/AddMediaDialog';
import defaultProps, { defaultEndpointValues, defaultValues } from './default';
import { Data, RingGroupDialogProps as Props, RingGroupNodeData } from './definition';
import schema from './schema';
import translations from './translations';
import { columns, getLargestTimeout } from './utility';

const CONSTANTS = {
  WIDTH: {
    TABLE: {
      LEFT: 380,
      RIGHT: 608,
    },
  },
};

const RingGroupDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { data: mediaData, isLoading: isLoadingMedia } = useFetchMediasQuery();
  const [mediaOptions, setMediaOptions] = useState<Array<SelectOption>>([]);
  const [itemName, setItemName] = useState<string>();
  const selectRef = useRef<SelectInstance>(null);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<Data>({
    defaultValues,
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const endpointsFieldArray = useFieldArray({
    control,
    name: 'endpoints',
  });

  const {
    columns: tableColumns,
    confirmations,
    name,
    repeats,
    ringback,
    strategy,
    strategies,
    title,
  } = useMemo(() => translations(), []);

  const ringBackOptions = [...ringback.options, ...mediaOptions];

  const onItem = {
    add: (item: EndpointItemType, endpointType: EndpointType) => {
      const endpoint = {
        endpoint_type: endpointType,
        key: item.id,
        delay: defaultEndpointValues.delay,
        timeout: defaultEndpointValues.timeout,
        name: nameFormatter(endpointType, item, true),
      };
      return endpointsFieldArray.append(endpoint);
    },
    reorder: (index: DragIndex) => {
      endpointsFieldArray.move(index.old, index.new);
    },
  };

  const submitHandler = (formData: Data) => {
    const mappedEndpoints: Array<GroupEndpoint> = remapEndpoints(formData);
    const nodeData: RingGroupNodeData = {
      metadata: {
        name: formData.name,
        pvt_type: 'ring_group',
      },
      data: {
        ...formData,
        timeout: getLargestTimeout(mappedEndpoints),
        endpoints: mappedEndpoints,
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    if (data) {
      reset(
        enhancedFormUtility.transformDataToFormData(
          {
            ...data,
            endpoints: data.endpoints?.map((endpoint: GroupEndpoint) => ({
              ...endpoint,
              key: endpoint.id,
            })),
          },
          defaultValues,
        ),
      );
    }
  }, [data, reset]);

  useEffect(() => {
    if (mediaData) {
      setMediaOptions(mediaData.map(selectOptionMapper));
    }
  }, [mediaData]);

  useSetSelectValue({ itemName, selectRef, options: ringBackOptions });

  return (
    <CallflowActionsDialog
      isLoading={isLoadingMedia}
      title={title}
      type={DialogType.XLarge}
      handleClose={onClose.bind(null, handleSubmit, submitHandler) as never}
    >
      <div role="row" className="split split-input-fields">
        <div role="cell">
          <HookFormInputWrapper name="name" control={control}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                isDirty={isDirty}
                feedback={feedback}
                label={name.label}
                inputProps={{
                  ...formProps,
                  id: 'input-ring-group-name',
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormSelectWrapper name="strategy" control={control} options={strategies}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isLabelAbove
                isDirty={isDirty}
                feedback={feedback}
                label={strategy.label}
                selectProps={{
                  ...formProps,
                  id: 'select-ring-group-strategy',
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <div role="row" className="split split-input-fields">
        <div role="cell">
          <HookFormInputWrapper name="repeats" control={control}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                isDirty={isDirty}
                feedback={feedback}
                label={repeats.label}
                inputProps={{
                  ...formProps,
                  type: 'number',
                  id: 'input-ring-group-repeats',
                }}
                inputWidth="small"
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <AddMediaDialog
            selectComponent={
              <HookFormSelectWrapper name="ringback" control={control} options={ringBackOptions}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledSelect
                    isDirty={isDirty}
                    feedback={feedback}
                    isLabelAbove
                    label={ringback.label}
                    selectProps={{
                      ...formProps,
                      id: 'select-ring-group-ringback',
                    }}
                    ref={selectRef}
                  />
                )}
              </HookFormSelectWrapper>
            }
            setItemName={setItemName}
          />
        </div>
      </div>

      <TableSelector
        disableSortBy
        manualSortBy
        hasActionRow
        hasAdmins={false}
        hasPagination={{
          content: false,
        }}
        hasUsersExtension
        isRightTableDraggable
        columnsSelected={columns(control, tableColumns, endpointsFieldArray)}
        confirmations={confirmations}
        id="ring-group"
        selectionFieldArray={endpointsFieldArray}
        selectionTableType={TableType.SelectionDraggable}
        width={{
          left: CONSTANTS.WIDTH.TABLE.LEFT,
          right: CONSTANTS.WIDTH.TABLE.RIGHT,
        }}
        addItemHandler={onItem.add}
        reorderItemsHandler={onItem.reorder}
      />
    </CallflowActionsDialog>
  );
};

export default RingGroupDialog;
