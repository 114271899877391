import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import uniqBy from 'lodash/uniqBy';
import { useFetchSharedVoicemailsQuery, useFetchVoicemailsByOwnerIdQuery } from 'models/Voicemail';
import { useFormContext } from 'react-hook-form';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { FormInput } from '../../../definition';
import { columns } from './utility';

type Props = TabPanelProps<FormInput>;

const TabVoicemail = ({ id = ADD_KEY }: Props) => {
  const { getValues } = useFormContext();
  const { data, isLoading } = useFetchVoicemailsByOwnerIdQuery({ id });
  const { data: sharedData, isLoading: isSharedDataLoading } = useFetchSharedVoicemailsQuery();

  if (isLoading || isSharedDataLoading) {
    return <Loading />;
  }

  const tableData =
    uniqBy([...Object.values(getValues('voicemail')), ...(data as any)], 'id').filter(
      (item) => !getValues(`voicemail.${item.id}._toDelete`),
    ) || [];

  return (
    <Table
      hasActionRow={false}
      columns={columns(sharedData)}
      data={tableData}
      initialState={{ sortBy: { id: 'name' } }}
    />
  );
};

export default TabVoicemail;
