import { HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledRadioGroup } from 'shared/components/Labeled';
import { RadioProps } from 'shared/components/Radio';
import { SectionProps as Props } from '../../../definition';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = ['whitelabel.carrier'];

const CarrierOptionsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const formContext = useFormContext();
  const { control, formState } = formContext;

  const OPTIONS: { [key: string]: RadioProps[] } = {
    CARRIER: [
      {
        info: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_system_wide_carriers.info',
        ),
        label: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_system_wide_carriers.label',
        ),
        value: 'useBlended',
      },
      {
        info: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_provider_carriers.info',
        ),
        label: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_provider_carriers.label',
        ),
        value: 'useReseller',
      },
      {
        info: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_account_defined_carriers.info',
        ),
        label: t(
          'accounts_manager:containers.accounts.section.carrier_options.field.use_account_defined_carriers.label',
        ),
        value: 'byoc',
      },
    ],
  };

  return (
    <>
      <h2>
        {t(
          'accounts_manager:containers.accounts.section.carrier_options.available_no_match_rule_options.heading',
        )}
      </h2>
      <p>
        {t(
          'accounts_manager:containers.accounts.section.carrier_options.available_no_match_rule_options.intro',
        )}
      </p>
      <div role="row">
        <div role="cell">
          {/* Carriers Radio Group */}
          <HookFormRadioGroupWrapper name="whitelabel.carrier" options={OPTIONS.CARRIER}>
            {({ ref, isDirty, ...formProps }) => {
              return (
                <LabeledRadioGroup
                  isLabelAbove
                  isDirty={isDirty}
                  label={t(
                    'accounts_manager:containers.accounts.section.carrier_options.available_no_match_rule_options.label',
                  )}
                  radioGroupProps={{ ...formProps }}
                  radioProps={OPTIONS.CARRIER}
                />
              );
            }}
          </HookFormRadioGroupWrapper>
        </div>
      </div>
    </>
  );
};

export default CarrierOptionsSection;
