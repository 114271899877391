import { useMemo } from 'react';
import { FilterOption, FilterValues } from 'shared/components/FilterSelect';
import { getInitialFiltersValue } from '../../utility';
import {
  FilterSharedUtilities,
  FilterSpecificUtilities,
  FilterSpecificUtilitiesBase,
  FilterUtilities,
} from './definition';

export type {
  FilterSharedUtilities,
  FilterSpecificUtilities,
  FilterSpecificUtilitiesBase,
  FilterUtilities,
};

/**
 * @name useFilterUtilities
 * @description The hook that returns the filter utilities.
 *
 * @param specificUtilities The utilities passed down by consumer
 */
export const useFilterUtilities = (specificUtilities: FilterSpecificUtilities): FilterUtilities => {
  const { filterDefaults, filterGroup, filterType, getLabel } = specificUtilities;

  return useMemo(() => {
    // The utilities shared across all type of specificUtilities
    const defaultUtilities: FilterSharedUtilities = {
      getBreadcrumb: (filterValues: FilterValues) =>
        filterValues.applied[filterType].length !== filterDefaults[filterType].length
          ? filterValues.applied[filterType]
              .filter((value: string) => value && value !== FilterOption.All)
              .map((value: string) => getLabel(value))
          : [],
      getInitialFiltersValue: () => getInitialFiltersValue(filterGroup, filterDefaults),
      onApplyFilter: (filterValues: FilterValues) => {
        filterValues.applied[filterType] = [...filterValues.selected[filterType]];
      },
      onFilterOpen: (filterValues: FilterValues) => {
        filterValues.selected[filterType] = [...filterValues.applied[filterType]];
      },
      onResetSelectedValues: (filterValues: FilterValues) => {
        filterValues.selected[filterType] = [...filterDefaults[filterType]];
      },
    };

    // Compose the utilities for the specified type
    return { ...defaultUtilities, ...specificUtilities };
  }, [filterDefaults, filterGroup, filterType, specificUtilities, getLabel]);
};
