import Page from 'apps/shared/components/Page';
import { FunctionComponent, Suspense, useEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import SideNav from './components/SideNav';
import Account from './containers/Account';
import metaAccount from './containers/Account/meta';
import CallBlocking, { meta as metaCallBlocking } from './containers/CallBlocking';
import Callflows, { meta as metaCallflows } from './containers/Callflows';
import Conferences, { meta as metaConferences } from './containers/Conferences';
import Dashboard, { meta as metaDashboard } from './containers/Dashboard';
import Devices, { meta as metaDevices } from './containers/Devices';
import Directories, { meta as metaDirectories } from './containers/Directories';
import Faxes, { meta as metaFaxes } from './containers/Faxes';
import FeatureCodes, { meta as metaFeatureCodes } from './containers/FeatureCodes';
import Groups, { meta as metaGroups } from './containers/Groups';
import Media, { meta as metaMedia } from './containers/Media';
import Menu, { meta as metaMenu } from './containers/Menu';
import Seats, { meta as metaSeats } from './containers/Seats';
import SMS from './containers/SMS';
import metaSMS from './containers/SMS/meta';
import TimeOfDay, { meta as metaTimeOfDay } from './containers/TimeOfDay';
import Voicemails, { meta as metaVoicemails } from './containers/Voicemails';
import { PhoneSystemProps as Props } from './definition';
import StyledPhoneSystem from './style';

const PhoneSystem: FunctionComponent<Props> = ({ label }: Props): JSX.Element => {
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const location = useLocation();

  const routes = useRoutes([
    { path: '/', element: <Navigate to={`./${metaDashboard.slug}`} replace /> },
    { path: `/${metaAccount.slug}/*`, element: <Account /> },
    { path: `/${metaCallBlocking.slug}/*`, element: <CallBlocking /> },
    { path: `/${metaCallflows.slug}/*`, element: <Callflows /> },
    { path: `/${metaConferences.slug}/*`, element: <Conferences /> },
    { path: `/${metaDashboard.slug}/*`, element: <Dashboard /> },
    { path: `/${metaDevices.slug}/*`, element: <Devices /> },
    { path: `/${metaDirectories.slug}/*`, element: <Directories /> },
    { path: `/${metaFaxes.slug}/*`, element: <Faxes /> },
    { path: `/${metaFeatureCodes.slug}/*`, element: <FeatureCodes /> },
    { path: `/${metaGroups.slug}/*`, element: <Groups /> },
    { path: `/${metaMedia.slug}/*`, element: <Media /> },
    { path: `/${metaMenu.slug}/*`, element: <Menu /> },
    { path: `/${metaSeats.slug}/*`, element: <Seats /> },
    { path: `/${metaSMS.slug}/*`, element: <SMS /> },
    { path: `/${metaTimeOfDay.slug}/*`, element: <TimeOfDay /> },
    { path: `/${metaVoicemails.slug}/*`, element: <Voicemails /> },
    { path: '*', element: <Error /> },
  ]);

  useEffect(() => {
    const HAS_SCROLL = [metaDashboard.slug];
    setHasScroll(HAS_SCROLL.some((slug: string) => location.pathname.includes(slug)));
  }, [location]);

  return (
    <Page label={label}>
      <StyledPhoneSystem hasScroll={hasScroll}>
        <SideNav />
        <Suspense fallback={<Loading />}>{routes}</Suspense>
      </StyledPhoneSystem>
    </Page>
  );
};

export default PhoneSystem;
