import { joiResolver } from '@hookform/resolvers/joi';
import QueueSelect from 'apps/PhoneSystem/shared/QueueSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFetchQueueQuery } from 'models/Queues';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import {
  AgentAvailabilityDialogProps as Props,
  AgentAvailabilityNodeData,
  Data,
} from './definition';
import schema from './schema';

const AgentAvailabilityDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { data: queueData, isLoading } = useFetchQueueQuery();
  const { handleSubmit, control, watch, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      route_to_another_queue: Boolean(data?.var),
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const watchEnterAsCallBack = watch('route_to_another_queue');

  const submitHandler = (formData: Data) => {
    const selected = queueData?.find(({ id }) => id === formData.id);
    const data = { ...formData };

    if (!data.route_to_another_queue) {
      data.var = '';
    }

    const nodeData: AgentAvailabilityNodeData = {
      metadata: {
        name: selected?.name,
        pvt_type: 'acdc_agent_availability',
      },
      data,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.agent_availability.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <QueueSelect
        name="id"
        control={control}
        isLabelAbove
        label={t(
          'phone_system:containers.callflows.callflow_action_dialog.agent_availability.add.queue',
        )}
      />

      <HookFormInputWrapper name="route_to_another_queue" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            feedback={feedback}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.agent_availability.add.route_to_another_queue',
            )}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
              id: 'input-agent-availability-route-to-queue',
            }}
          />
        )}
      </HookFormInputWrapper>

      {watchEnterAsCallBack && (
        <HookFormInputWrapper name="var" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              isLabelAbove
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.agent_availability.add.var',
              )}
              inputWidth="small"
              inputProps={{
                ...formProps,
                id: 'input-agent-availability-var',
              }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default AgentAvailabilityDialog;
