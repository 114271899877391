import { joiResolver } from '@hookform/resolvers/joi';
import QueueSelect from 'apps/PhoneSystem/shared/QueueSelect';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, QueueLoginDialogProps as Props, QueueLoginNodeData } from './definition';
import schema from './schema';

const QueueLoginDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const nodeData: QueueLoginNodeData = {
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t('phone_system:containers.callflows.callflow_action_dialog.queue_login.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <QueueSelect
        name="id"
        control={control}
        isLabelAbove
        label={t('phone_system:containers.callflows.callflow_action_dialog.queue_login.add.queue')}
      />
    </CallflowActionsDialog>
  );
};

export default QueueLoginDialog;
