import { joiResolver } from '@hookform/resolvers/joi';
import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, ResponseDialogProps as Props, ResponseVariablesNodeData } from './definition';
import schema from './schema';

const ResponseDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      code: data?.code ? String(data.code) : defaultValues.code,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const name = `${t(
      'phone_system:containers.callflows.callflow_action_dialog.response.add.sip_code',
    )} ${formData.code}`;
    const nodeData: ResponseVariablesNodeData = {
      metadata: {
        name,
        pvt_type: 'response',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.response.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="code" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.response.add.response_code',
            )}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-response-code',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="message" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.response.add.response_message',
            )}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-response-message',
            }}
          />
        )}
      </HookFormInputWrapper>

      <MediaSelect
        name="media"
        isLabelAbove
        control={control}
        label={t('phone_system:containers.callflows.callflow_action_dialog.response.add.media')}
      />
    </CallflowActionsDialog>
  );
};

export default ResponseDialog;
