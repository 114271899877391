export interface NumbersInUseEditProps {
  hasError: boolean;
  data?: any;
  id: string;
}

export type FormInput = {
  id: string;
  used_by: string;
  prepend: {
    name: string;
    enabled: boolean;
  };
  e911: {
    customer_name: string;
    extended_address: string;
    locality: string;
    notification_contact_emails: string;
    postal_code: string;
    region: string;
    street_address: string;
  };
  cnam: {
    inbound_lookup: boolean;
    display_name: string;
  };
  sms_owner: string;
  sms_owner_id: string;
  used_by_callflow_id: string;
};

export enum FailoverMethod {
  e164 = 'e164',
  sip = 'sip',
  inactive = 'inactive',
}

export type Failover = {
  method: FailoverMethod;
  value: string;
};

type Item = {
  hash: string;
  label: string;
  component: JSX.Element;
  isDirty: boolean;
  isError: boolean;
};

export type Items = {
  e911: Item;
  caller_id: Item;
  prepend: Item;
  failover?: Item;
  sms_enablement?: Item;
};
