import { SESSION_STORAGE } from 'constant';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { FilterValues } from 'shared/components/FilterSelect';
import { FilterGroup, FilterType } from './definition';
import { FilterSpecificUtilitiesBase } from './hooks/useFilterUtilities/definition';

/**
 * @name getFiltersSession
 * @description Get the global filters state from session storage.
 *
 * @example
 * getFiltersSession(); // { 'callflows': {...}, 'seats': {...} }
 *
 * @returns The global filters state.
 */
export const getFiltersSession = (): Record<FilterGroup, Record<FilterType, Array<string>>> =>
  JSON.parse(sessionStorage.getItem(SESSION_STORAGE.TABLE.FILTER) ?? '{}');

/**
 * @name getInitialFiltersValue
 * @description Considers the session storage and compose the filter initial values.
 *
 * @param filterGroup The group of filter types
 * @param filterDefaults The default values of the filter
 *
 * @returns The initial value of the filter.
 */
export const getInitialFiltersValue = (
  filterGroup: FilterGroup,
  filterDefaults: FilterSpecificUtilitiesBase['filterDefaults'],
) => {
  const filterValues = getFiltersSession()[filterGroup];

  return isEmpty(filterValues)
    ? {
        applied: filterDefaults,
        selected: filterDefaults,
      }
    : {
        applied: filterValues,
        selected: filterValues,
      };
};

/**
 * @name removeFiltersSession
 * @description Remove session storage filter value.
 *
 * @param [object]
 * @property filterGroup
 * @property filterType
 * @property key
 */
export const removeFiltersSession = ({
  filterGroup,
  filterType,
  key,
}: {
  filterGroup: FilterGroup;
  filterType: FilterType;
  key: string;
}) => {
  sessionStorage.setItem(
    key,
    JSON.stringify(
      produce(JSON.parse(sessionStorage.getItem(key) ?? '{}'), (draft: any) => {
        if (sessionStorage.getItem(key) && draft[filterGroup]?.[filterType]) {
          delete draft[filterGroup][filterType];
        }
      }),
    ),
  );
};

/**
 * @name setFiltersToSessionStorage
 * @description Set filters to session storage.
 *
 * @param filterGroup The group of filter types
 * @param filterValues The filter to set
 */
export const setFiltersToSessionStorage = (
  filterGroup: FilterGroup,
  filterValues: FilterValues,
) => {
  const nextFiltersSession = getFiltersSession();
  nextFiltersSession[filterGroup] = filterValues.applied; // The filter object is immutable since it's from JSON.parse
  sessionStorage.setItem(SESSION_STORAGE.TABLE.FILTER, JSON.stringify(nextFiltersSession));
};
