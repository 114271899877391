import AppExchangeSection, { fields as appExchangeFields } from './AppExchangeSection';
import BasicsSection, { fields as basicsFields } from './BasicsSection';
import CallerIdSection, { fields as callerIdFields } from './CallerIdSection';
import { Fields as CallRestrictionsFields } from './CallRestrictionsSection/definition';
import CarrierOptionsSection, { fields as carrierOptionsFields } from './CarrierOptionsSection';
import ContactsSection, { fields as contactsFields } from './ContactsSection';
import DesktopModulesSection, { fields as desktopModulesFields } from './DesktopModulesSection';
import NotesSection, { fields as notesFields } from './NotesSection';
import NotificationsSection, { fields as notificationsFields } from './NotificationsSection';

const fields = {
  appExchange: appExchangeFields,
  basics: basicsFields,
  callerId: callerIdFields,
  callRestrictions: CallRestrictionsFields,
  carrierOptions: carrierOptionsFields,
  contacts: contactsFields,
  desktopModules: desktopModulesFields,
  notes: notesFields,
  notifications: notificationsFields,
};

const Section = {
  AppExchange: AppExchangeSection,
  Basics: BasicsSection,
  CallerId: CallerIdSection,
  CarrierOptions: CarrierOptionsSection,
  Contacts: ContactsSection,
  DesktopModules: DesktopModulesSection,
  Notes: NotesSection,
  Notifications: NotificationsSection,
};

export { fields, Section };
