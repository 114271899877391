import { FilterGroup, FilterType } from 'apps/shared/hooks/useFilter';
import { FilterSpecificUtilities } from 'apps/shared/hooks/useFilter/hooks/useFilterUtilities';
import { FilterOption } from 'shared/components/FilterSelect';
import { SelectOption } from 'shared/components/Select';

export const filterUtilities = (
  filterOptions: Array<SelectOption>,
  getLocationLabelById: (id: string) => string,
): FilterSpecificUtilities => {
  const filterType = FilterType.SeatLocation;

  return {
    filterDefaults: {
      [filterType]: [FilterOption.All, ...filterOptions.map(({ value }: SelectOption) => value)],
    },
    filterGroup: FilterGroup.Seats,
    filterOptions,
    filterType,
    filterData: (data, filterValues) =>
      data.filter(({ location }: any) => filterValues.selected?.[filterType]?.includes(location)),
    getLabel: getLocationLabelById,
  };
};
