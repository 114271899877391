import {
  HookFormInputWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import get from 'lodash/get';
import { ChangeEvent, FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['name', 'mailbox', 'pin', 'timezone'];

const BasicsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    resetField,
    setValue,
  } = useFormContext();

  const handleChange = (field: string, value: string): void => {
    if (get(errors, field, false)) {
      resetField(field, { defaultValue: getValues(field), keepDirty: false });
    } else {
      setValue(field, value, { shouldDirty: true });
    }
  };

  return (
    <div role="row">
      <div role="cell">
        {/* Name */}
        <HookFormInputWrapper name="name">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-basic-name"
              label={t('phone_system:containers.voicemails.section.basics.name.label')}
              inputProps={{
                ...formProps,
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>

        {/* Voicemail Number */}
        <HookFormInputWrapper name="mailbox">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              id="input-basic-voicemail-number"
              label={t('phone_system:containers.voicemails.section.basics.voicemail_number.label')}
              inputProps={{
                ...formProps,
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>

        {/* PIN Number */}
        <HookFormInputWrapper name="pin">
          {({ ref, isDirty, feedback, name, ...formProps }) => (
            <LabeledInput
              id="input-basic-pin-number"
              label={t('phone_system:containers.voicemails.section.basics.pin_number.label')}
              inputProps={{
                ...formProps,
                onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(name, e.target.value),
              }}
              feedback={feedback}
              isDirty={isDirty}
            />
          )}
        </HookFormInputWrapper>

        {/* Timezone */}
        <HookFormTimeZoneSelectWrapper
          name="timezone"
          label={t('phone_system:containers.voicemails.section.basics.timezone.label')}
          tooltip={t('phone_system:containers.voicemails.section.basics.timezone.tooltip')}
          id="input-basic-timezone"
        />
      </div>
    </div>
  );
};

export default BasicsSection;
