import { AppMeta } from 'apps/shared/definition';
import { bizProvMutations } from 'models/BizProv/Mutations';
import store from 'store';

/**
 * TODO: Remove the APPS_META below (as it should be temporary):
 * • Refactor global layout so that the shared <Header> component
 *   is external to each app component?
 * • Find an alternate way of handling app meta data?
 */

export const APPS_META: Array<AppMeta> = [
  {
    // component: AccountsManager,
    icon: 'accounts',
    name: 'accounts',
    slug: 'accounts-manager',
  },
  {
    // component: AdvancedProvisioner,
    icon: 'provisioner',
    name: 'provisioner',
    slug: 'provisioner',
  },
  {
    // isIframeApp: true,
    i18n: {
      label: 'Advanced Reporting',
    },
    icon: 'xarios',
    name: 'xarios',
    slug: 'xarios',
  },
  {
    icon: 'csv-onboarding',
    name: 'csv-onboarding',
    slug: '',
    url: window.nemo?.apps?.csv_onboarding?.url ?? '',
    async onClick() {
      const { error } = (await store.dispatch(bizProvMutations.endpoints.login.initiate())) as any;
      if (error) {
        console.error(error);
      }
      window.open(this.url, '_blank');
    },
  },
  {
    // component: CallCenter,
    icon: 'call-center-1',
    name: 'callcenter-ooma',
    slug: 'call-center',
  },
  {
    // isIframeApp: true,
    i18n: {
      label: 'Keyman',
    },
    icon: 'dial-plans',
    name: 'keyman',
    slug: 'keyman',
  },
  {
    // isIframeApp: true,
    i18n: {
      label: 'Microsoft Teams',
    },
    icon: 'ms-teams',
    name: 'msteams',
    slug: 'msteams',
  },
  {
    // component: Numbers,
    icon: 'numbers',
    name: 'numbers',
    slug: 'numbers',
  },
  {
    // component: PhoneSystem,
    icon: 'phone-system',
    name: 'callflows',
    slug: 'phone-system',
  },
  {
    // component: PhoneSystem,
    icon: 'phone-system',
    name: 'callflows',
    slug: 'phone-system',
  },
  {
    // isIframeApp: true,
    i18n: {
      label: 'QStats',
    },
    icon: 'qstats',
    name: 'qstats',
    slug: 'qstats',
  },
  {
    // component: SipTrunking,
    icon: 'pbxs',
    name: 'pbxs',
    slug: 'sip-trunking',
  },
  {
    // isIframeApp: true,
    i18n: {
      label: 'VQ Mon',
    },
    icon: 'vqmon',
    name: 'vqmon',
    slug: 'vqmon',
  },
];
