import { SECONDS_IN_DAY, SECONDS_IN_MINUTE } from 'constant';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import last from 'lodash/last';
import merge from 'lodash/merge';
import range from 'lodash/range';
import { OptionsType, OptionTypeBase } from 'shared/components/Select/definition';
import {
  dateToGregorian,
  dateToSeconds,
  gregorianToDate,
  secondsToDate,
} from 'shared/utility/date';
import { defaultValues } from './default';

/**
 * Preserve the selected weekday when switching between `cycle` values:
 * • if `cycle` value is 'weekly' and the `wdays` value is a string... arrayify it!
 * • if `cycle` value is 'monthly' or 'yearly', and the `wdays` value is an array... grab the first value!
 *
 * @param {function} getValues - React Hook Form's optimized helper for reading form values
 * @param {OptionTypeBase} option - Currently selected `cycle` option (weekly/monthly/yearly)
 *
 * @return {string | string[]} - Weekday(s) selected
 */
export const convertWdays = (
  getValues: any,
  option: OptionTypeBase,
): string | Array<string> | undefined => {
  const wdays = getValues('wdays');
  const isString = typeof wdays === 'string';

  if (option.value === 'weekly') {
    return isString ? [wdays] : wdays;
  }

  return isString ? wdays : first(wdays);
};

/**
 * Prepare backend/database `temporal_rules` data object in order
 * for it to be consumed easily by the UI widgets
 *
 * @param {any} data - Database `temporal_rules` data object
 *
 * @return {any} - UI friendly `temporal_rules` data object
 */
export const initData = (data: any): any => {
  const _data = merge({}, defaultValues, cloneDeep(data), {
    duration: {
      start: data.time_window_start,
      stop: data.time_window_stop,
    },
    enabled:
      typeof data.enabled === 'undefined'
        ? 'based_on_time'
        : `forced_${data.enabled ? 'on' : 'off'}`,
    end_date: data.end_date ? gregorianToDate(data.end_date) : '',
    is_all_day_event:
      data.time_window_start === 0 && data.time_window_stop === SECONDS_IN_DAY - SECONDS_IN_MINUTE,
    has_no_end_date: !data.end_date,
    range_start: first(data.days),
    range_stop: last(data.days),
    start_date: gregorianToDate(data.start_date),
    time_window_start: secondsToDate(data.time_window_start),
    time_window_stop: secondsToDate(data.time_window_stop),
    wdays: data.cycle === 'weekly' ? data.wdays : first(data.wdays),
  });

  return _data;
};

/**
 * Prepare React Hook Form `temporal_rules` data object in order
 * for it to be saved to the database
 *
 * @param {any} data - UI friendly `temporal_rules` data object
 *
 * @return {any} - Database `temporal_rules` data object
 */
export const saveData = (data: any): any => {
  const getRangeValue = (value: string | Array<string>): number =>
    Array.isArray(value) ? Number(first(value)) ?? 1 : Number(value);

  let days;
  switch (data.ordinal) {
    case 'every':
      days = data.days;
      break;
    case 'range':
      days = range(
        getRangeValue(data.range_start),
        getRangeValue(data.range_stop) + 1,
      ).map((value) => String(value));
      break;
    default:
      days = [];
  }

  // if ordinal is 'range', remove days
  if (data.ordinal === 'range') {
    delete data.days;
  }

  const _data = merge({}, cloneDeep(data), {
    days,
    enabled: data.enabled?.includes('forced_') ? data.enabled === 'forced_on' : 'based_on_time',
    end_date: data.end_date ? dateToGregorian(data.end_date) : data.end_date,
    start_date: dateToGregorian(data.start_date),
    time_window_start: dateToSeconds(data.time_window_start),
    time_window_stop: dateToSeconds(data.time_window_stop),
    wdays: typeof data.wdays === 'string' ? [data.wdays] : data.wdays,
  });

  // if cycle is 'date' (never), remove days/end_date/interval/ordinal/wdays
  if (_data.cycle === 'date') {
    delete _data.days;
    delete _data.end_date;
    delete _data.interval;
    delete _data.ordinal;
    delete _data.wdays;
  }

  // if cycle is 'weekly', remove days/ordinal
  if (_data.cycle === 'weekly') {
    delete _data.days;
    delete _data.ordinal;
  }

  // if cycle is not 'yearly', remove month
  if (_data.cycle !== 'yearly') {
    delete _data.month;
  }

  if (['every', 'range'].includes(_data.ordinal)) {
    // if ordinal is 'every' or 'range', reset weekdays
    _data.wdays = [];
  } else {
    // if ordinal is not 'every' or 'range', remove days
    delete _data.days;
  }

  // if end_date is null or has_no_end_date is checked, remove end_date
  if (_data.end_date === null || _data.has_no_end_date) {
    delete _data.end_date;
  }

  // if enabled is 'based_on_time', remove enabled
  if (_data.enabled === 'based_on_time') {
    delete _data.enabled;
  }

  // always remove...
  delete _data.duration;
  delete _data.is_all_day_event;
  delete _data.has_no_end_date;
  delete _data.range_start;
  delete _data.range_stop;

  return _data;
};

/**
 * Get <select> options
 *
 * @param name - Name of <select> to be populated
 * @param startAtDay - Value to begin in the <select> to be populated (for use with days only)
 *
 * @return <select> options
 */
export const getOptions = (name: string, startAtDay = 1): OptionsType => {
  const OPTIONS: Record<string, OptionTypeBase[]> = {
    cycle: [
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.date`),
        value: 'date',
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.weekly`),
        value: 'weekly',
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.monthly`),
        value: 'monthly',
      },
      {
        label: i18next.t(`phone_system:containers.time_of_day.cycle.yearly`),
        value: 'yearly',
      },
    ],
    enabled: [
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.based_on_time'),
        value: 'based_on_time',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.forced_on'),
        value: 'forced_on',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.enabled.forced_off'),
        value: 'forced_off',
      },
    ],
    month: [
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.1'), value: '1' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.2'), value: '2' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.3'), value: '3' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.4'), value: '4' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.5'), value: '5' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.6'), value: '6' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.7'), value: '7' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.8'), value: '8' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.9'), value: '9' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.10'), value: '10' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.11'), value: '11' },
      { label: i18next.t('phone_system:containers.time_of_day.months_numerical.12'), value: '12' },
    ],
    ordinal: [
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.first'), value: 'first' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.second'), value: 'second' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.third'), value: 'third' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.fourth'), value: 'fourth' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.fifth'), value: 'fifth' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.last'), value: 'last' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.every'), value: 'every' },
      { label: i18next.t('phone_system:containers.time_of_day.ordinal.range'), value: 'range' },
    ],
    wdays: [
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.monday.long'),
        value: 'monday',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.tuesday.long'),
        value: 'tuesday',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.wensday.long'),
        value: 'wensday', // kazoo spelling
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.thursday.long'),
        value: 'thursday',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.friday.long'),
        value: 'friday',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.saturday.long'),
        value: 'saturday',
      },
      {
        label: i18next.t('phone_system:containers.time_of_day.wdays.sunday.long'),
        value: 'sunday',
      },
    ],
    days: range(startAtDay, 32).map((day: number) => ({ label: day, value: String(day) })),
  };

  return OPTIONS[name];
};
