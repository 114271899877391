import { ComponentProps } from 'shared/definition';

export enum HelpDialogType {
  List,
  Edit,
}

type Props = ComponentProps;

export interface HelpDialogProps extends Props {
  type: HelpDialogType;
}

export type StyledHelpDialogProps = Props;
