import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { closeSnackbar } from 'notistack';
import { ForwardedRef, forwardRef } from 'react';
import Icon from '../Icon';
import { CONSTANTS } from './constant';
import { AlertProps as Props } from './definition';

const Alert = forwardRef(
  (
    { action, id, message, severity: ogSeverity, variant }: Partial<Props>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const severity = ogSeverity ?? (variant as AlertColor); // notistack uses the `variant` prop for `severity` values

    return (
      <MuiAlert
        action={
          id ? (
            <Icon
              hasHover
              name="close"
              size={CONSTANTS.ICON.SIZE.CLOSE}
              onClick={() => closeSnackbar(id)}
            />
          ) : (
            action
          )
        }
        classes={id ? { root: 'MuiAlert-snackbar' } : {}}
        data-test-id={`shared-alert${id ? '-snackbar' : ''}-${severity}`}
        iconMapping={{
          error: <Icon name="error-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
          info: <Icon name="info-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
          success: <Icon name="radiobutton-checked-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
          warning: <Icon name="warning-outlined" size={CONSTANTS.ICON.SIZE.DEFAULT} />,
        }}
        ref={ref}
        severity={severity}
      >
        {message}
      </MuiAlert>
    );
  },
);

export default Alert;
