import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';
import { RootState } from 'definition';
import { selectAccountId } from 'models/Account/slice';
import { injectUiMetadataToArgs } from './injectUiMetadataToArgs';

export const dynamicBaseQuery: (
  baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  replaceAny?: boolean,
  shouldInjectUiMetadata?: boolean,
) => BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = (
  baseQuery,
  /** Replace any existence of `ACCOUNT_ID_URL_PLACEHOLDER` with the current account id  */
  replaceAny = false,
  shouldInjectUiMetadata?: boolean,
) => async (args, api, extraOptions) => {
  if (shouldInjectUiMetadata) {
    injectUiMetadataToArgs(args);
  }

  const url = typeof args === 'string' ? args : args.url;

  const matcher = `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}`;

  if (!replaceAny && !url.match(matcher)) {
    return baseQuery(args, api, extraOptions);
  }

  const accountId = selectAccountId(api.getState() as RootState);

  // gracefully handle scenarios where data to generate the URL is missing
  if (!accountId) {
    return {
      error: {
        status: 400,
        data: 'No account ID received',
      },
    };
  }

  const adjustedUrl = !replaceAny
    ? url.replace(matcher, `accounts/${accountId}`)
    : url.replaceAll(`${ACCOUNT_ID_URL_PLACEHOLDER}`, accountId);

  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return baseQuery(adjustedArgs, api, extraOptions);
};
