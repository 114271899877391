import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { AgentPauseDialogProps as Props, AgentPauseNodeData, Data } from './definition';
import schema from './schema';

const AgentPauseDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const nodeData: AgentPauseNodeData = {
      metadata: {
        name: `${formData.timeout} (${formData.presence_id})`,
        pvt_type: 'acdc_agent_pause',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t('phone_system:containers.callflows.callflow_action_dialog.agent_pause.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="timeout" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.agent_pause.add.timeout',
            )}
            inputWidth="small"
            inputProps={{
              ...formProps,
              type: 'number',
              id: 'input-agent-pause-timeout',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="presence_id" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.agent_pause.add.presence_id',
            )}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-agent-pause-presence-id',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default AgentPauseDialog;
