import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { DirtyIconProps as Props } from './definition';
import StyledDirtyIcon from './style';

export { icon } from './style';

const DirtyIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { isDirty, isDot, isError, isInline, tooltip }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const _tooltip = tooltip ?? (t('common:dirty_icon.default.field') as string);

  return (
    <>
      {(isDirty || isError) && (
        <StyledDirtyIcon isError={isError} isInline={isDot || isInline}>
          {isDot ? (
            <span>•</span>
          ) : (
            <Tooltip className="small" title={_tooltip}>
              <Icon name="error-filled" size={20} />
            </Tooltip>
          )}
        </StyledDirtyIcon>
      )}
    </>
  );
};

export default DirtyIcon;
