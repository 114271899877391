import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, SetCallPriorityDialogProps as Props, SetCallPriorityNodeData } from './definition';
import schema from './schema';

const SetCallPriorityDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const nodeData: SetCallPriorityNodeData = {
      metadata: {
        pvt_type: 'set_variable',
      },
      data: {
        ...formData,
        value: formData.value.toString(),
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.set_call_priority.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>
        {t(
          'phone_system:containers.callflows.callflow_action_dialog.set_call_priority.add.explanation',
        )}
      </p>

      <HookFormInputWrapper name="value" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.set_call_priority.add.priority',
            )}
            inputWidth="small"
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-set-call-priority',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default SetCallPriorityDialog;
