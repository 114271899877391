import { FilterGroup, FilterType } from 'apps/shared/hooks/useFilter';
import { FilterSpecificUtilities } from 'apps/shared/hooks/useFilter/hooks/useFilterUtilities';
import { FilterOption } from 'shared/components/FilterSelect';
import { SelectOption } from 'shared/components/Select';
import { getCallflowTypeLabel } from '.';
import { CallflowType } from './definition';

/**
 * @name filterOptions
 * @description Get the filter options list.
 *
 * @returns Filter options array.
 */
export const filterOptions = () =>
  Object.values(CallflowType).map((value: string) => ({
    label: getCallflowTypeLabel(value),
    value,
  }));

/**
 * @name filterUtilities
 * @description Get the filter utilities object.
 *
 * @returns Filter utilities.
 */
export const filterUtilities = (filterOptions: Array<SelectOption>): FilterSpecificUtilities => {
  const filterType = FilterType.Type;

  return {
    filterDefaults: {
      [filterType]: [FilterOption.All, ...Object.values(CallflowType)],
    },
    filterGroup: FilterGroup.Callflows,
    filterOptions,
    filterType,
    filterData: (data, filterValues) =>
      data.filter(({ modules: [module] }: any) =>
        filterValues.selected?.[filterType].includes(CallflowType.Other)
          ? filterValues.selected?.[filterType].includes(module) ||
            !Object.values(CallflowType)
              .filter((value: string) => value !== CallflowType.Other)
              .includes(module)
          : filterValues.selected?.[filterType].includes(module),
      ),
    getLabel: getCallflowTypeLabel,
  };
};
