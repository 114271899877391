import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      color: theme.palette.common.black,
      fontSize: theme.typography.fontSize,
      '&.Mui-disabled': {
        background: themeColor.core.background.grey.medium,
      },
      // for AddressAutoComplete / Date/TimePicker components
      '&.MuiInputBase-adornedEnd:not(.MuiInputBase-adornedStart) svg': {
        marginRight: theme.spacing(2),
      },
      '&.MuiInputBase-adornedStart:not(.MuiInputBase-adornedEnd) svg': {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(-1.66), // compensate for Input padding
      },
      // for SearchInput component
      '&.MuiInputBase-adornedEnd.MuiInputBase-adornedStart svg': {
        '&.adornment-start': {
          marginLeft: theme.spacing(2),
        },
        '&.adornment-end': {
          marginRight: theme.spacing(2),
        },
      },
      input: {
        '&::placeholder': { fontStyle: 'italic' },
      },
      svg: {
        color: theme.palette.common.black,
      },
      '.MuiTypography-root': {
        padding: `0 ${theme.spacing(2)}`,
        color: theme.palette.common.black,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        fontWeight: 600,
        lineHeight: '1.5em',
      },
    },
  },
};
