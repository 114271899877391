import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { BaseSyntheticEvent, ChangeEvent, FunctionComponent, useEffect } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EditableListJustify } from 'shared/components/EditableList';
import {
  LabeledCheckbox,
  LabeledEditableList,
  LabeledInput,
  LabeledSelect,
} from 'shared/components/Labeled';
import { Notifications, SectionProps as Props } from '../../../definition';
import { TEMP_PROPERTY } from '../../../utility';
import { getOptions, initSchema, NOTIFICATION_TYPE_ID } from './utility';

export let schema = {};

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = [];

const NotificationsSection: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    getValues,
    resetField,
    setValue,
    watch,
  } = useFormContext();
  const OPTIONS = getOptions();
  const { NOTIFICATIONS } = TEMP_PROPERTY;

  useEffect(() => {
    // dynamically update validation schema
    schema = initSchema();

    // dynamically update dirty fields
    NOTIFICATION_TYPE_ID.forEach((typeId: string) => fields.push(`${NOTIFICATIONS}.${typeId}`));
  }, []);

  useEffect(() => {
    if (watch(`${NOTIFICATIONS}.fax_inbound_error_to_email.use_voicemail_to_email_from`)) {
      updateField('voicemail_to_email.from', getValues(`${NOTIFICATIONS}.voicemail_to_email.from`));
    }
  }, [watch(`${NOTIFICATIONS}.fax_inbound_error_to_email.use_voicemail_to_email_from`)]);

  const updateField = (field: string, value: string) => {
    const name = `${NOTIFICATIONS}.${field}`;
    const _errors = (errors?.[NOTIFICATIONS] as unknown) as FieldError &
      Notifications; /* TODO: Review usage of `as unknown` (this feels like a version of `any`) */

    const _resetField = (name: string): void =>
      resetField(name, { defaultValue: getValues(name), keepDirty: false });

    const _setValue = (name: string, value: string): void =>
      setValue(name, value, { shouldDirty: true });

    const setField = (isError: boolean, name: string, value: string): void =>
      isError ? _resetField(name) : _setValue(name, value);

    switch (field) {
      case 'voicemail_to_email.from':
        setField(!!_errors?.voicemail_to_email?.from, name, value);

        const target = {
          base: `${NOTIFICATIONS}.fax_inbound_error_to_email`,
          get from() {
            return `${this.base}.from`;
          },
          get flag() {
            return `${this.base}.use_voicemail_to_email_from`;
          },
        };

        getValues(target.flag) && _setValue(target.from, value);
        !!_errors?.fax_inbound_error_to_email?.from && _resetField(target.from);

        break;
      case 'fax_inbound_error_to_email.from':
        setField(!!_errors?.fax_inbound_error_to_email?.from, name, value);
        break;
      case 'deregister.from':
        setField(!!_errors?.deregister?.from, name, value);
        break;
      case 'deregister.to.email_addresses':
        setField(!!_errors?.deregister?.to?.email_addresses, name, value);
        break;
    }
  };

  const handleDeregisterEnableChange = (e: BaseSyntheticEvent) => {
    const prefix = `${NOTIFICATIONS}.deregister.`;
    e.target.checked === false &&
      ['from', 'subject', 'to.type', 'to.email_addresses'].forEach((field: string) =>
        resetField(`${prefix}${field}`),
      );
    setValue(`${prefix}enabled`, e.target.checked, {
      shouldDirty: e.target.checked !== getValues(`${prefix}enabled`),
    });
  };

  return (
    <>
      <h2>
        {t('accounts_manager:containers.accounts.section.notifications.heading.voicemail_to_email')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Send From */}
          <HookFormInputWrapper name={`${NOTIFICATIONS}.voicemail_to_email.from`}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.info',
                )}
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField('voicemail_to_email.from', e.target.value),
                }}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>

          {/** Use voicemail_to_email Send From */}
          <HookFormInputWrapper
            name={`${NOTIFICATIONS}.fax_inbound_error_to_email.use_voicemail_to_email_from`}
            isCheckbox
          >
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isBelow
                isDirty={isDirty}
                indentWidth="large"
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.fax_to_email_use_same_values.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>
        {t('accounts_manager:containers.accounts.section.notifications.heading.fax_to_email')}
      </h2>
      <div role="row">
        <div role="cell">
          {/* Send From */}
          <HookFormInputWrapper name={`${NOTIFICATIONS}.fax_inbound_error_to_email.from`}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                tooltip={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.info',
                )}
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                )}
                inputProps={{
                  ...formProps,
                  placeholder: t(
                    'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                  ),
                  disabled: watch(
                    `${NOTIFICATIONS}.fax_inbound_error_to_email.use_voicemail_to_email_from`,
                  ),
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    updateField('fax_inbound_error_to_email.from', e.target.value),
                }}
                labelProps={{
                  required: !watch(
                    `${NOTIFICATIONS}.fax_inbound_error_to_email.use_voicemail_to_email_from`,
                  ),
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Fax-To-Email Notifications */}
          <HookFormInputWrapper
            name={`${NOTIFICATIONS}.fax_inbound_error_to_email.enabled`}
            isCheckbox
          >
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                indentWidth="large"
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.fax_inbound_error_to_email.label',
                )}
                checkboxProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('accounts_manager:containers.accounts.section.notifications.heading.monitoring')}</h2>
      <div role="row">
        <div role="cell">
          {/* Deregister Monitoring */}
          <HookFormInputWrapper name={`${NOTIFICATIONS}.deregister.enabled`} isCheckbox>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                isDirty={isDirty}
                hasMargin
                indentWidth="large"
                tooltip={t(
                  'accounts_manager:containers.accounts.section.notifications.field.notify_on_deregister.info',
                )}
                tooltipWidth="medium"
                label={t(
                  'accounts_manager:containers.accounts.section.notifications.field.notify_on_deregister.label',
                )}
                checkboxProps={{
                  ...formProps,
                  onChange: handleDeregisterEnableChange,
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* Send From / Emails to Notify */}
          {watch(`${NOTIFICATIONS}.deregister.enabled`) && (
            <>
              <HookFormInputWrapper name={`${NOTIFICATIONS}.deregister.from`}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    isDirty={isDirty}
                    feedback={feedback}
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.send_from.label',
                    )}
                    inputProps={{
                      ...formProps,
                      placeholder: t(
                        'accounts_manager:containers.accounts.section.notifications.field.send_from.placeholder',
                      ),
                      onChange: (e: ChangeEvent<HTMLInputElement>) =>
                        updateField('deregister.from', e.target.value),
                    }}
                    labelProps={{ required: true }}
                  />
                )}
              </HookFormInputWrapper>

              <HookFormInputWrapper name={`${NOTIFICATIONS}.deregister.subject`}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    isDirty={isDirty}
                    feedback={feedback}
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.subject.label',
                    )}
                    inputProps={{
                      ...formProps,
                      placeholder: t(
                        'accounts_manager:containers.accounts.section.notifications.field.subject.label',
                      ),
                    }}
                    labelProps={{ required: true }}
                  />
                )}
              </HookFormInputWrapper>

              <HookFormSelectWrapper
                name={`${NOTIFICATIONS}.deregister.to.type`}
                options={OPTIONS.DEREGISTER_TO_TYPE}
              >
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledSelect
                    isDirty={isDirty}
                    feedback={feedback}
                    tooltip={t(
                      'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify.info',
                    )}
                    label={t(
                      'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify.label',
                    )}
                    selectProps={{
                      ...formProps,
                    }}
                  />
                )}
              </HookFormSelectWrapper>

              {watch(`${NOTIFICATIONS}.deregister.to.type`) === 'specified' && (
                <HookFormInputWrapper name={`${NOTIFICATIONS}.deregister.to.email_addresses`}>
                  {({ ref, isDirty, feedback, value, onChange }) => (
                    <LabeledEditableList
                      isDirty={isDirty}
                      feedback={feedback}
                      editableListProps={{
                        data: {
                          columns: [
                            {
                              header: {
                                text: t(
                                  'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify_email_addresses.label',
                                ),
                                justify: EditableListJustify.Left,
                              },
                              input: {
                                validationMask: (value: string) => !!value, // TODO: Implement email validation >>> !!value.match(REGEX.EMAIL)
                              },
                            },
                          ],
                          rows: (value || []).map((item: string) => [item]),
                        },
                        onUpdate: (data: any) =>
                          onChange(data.map(([item]: Array<string>) => item)),
                      }}
                    />
                  )}
                </HookFormInputWrapper>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NotificationsSection;
