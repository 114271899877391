import { svg } from 'apps/shared/svg';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import actions from '../../images/actions.png';
import callflow from '../../images/callflow_tree.png';
import { HelpDialogProps as Props, HelpDialogType } from './definition';
import { StyledHelpDialogEdit, StyledHelpDialogList } from './style';

const HelpDialog: FunctionComponent<Props> = (props): JSX.Element => {
  const { t } = useTranslation();
  const { type } = props;

  const EditDialog = (
    <StyledHelpDialogEdit>
      <h1>{t('phone_system:containers.callflows.dialog.help.content.title.1')}</h1>
      <h3>{t('phone_system:containers.callflows.dialog.help.content.subtitle.1')}</h3>
      <div>
        <div>
          <div className="intro">
            <span>1</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.1.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.1.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-1">
            <div className="cursor callflow-1">{svg.callflow}</div>
          </div>
        </div>
        <div>
          <div className="intro">
            <span>2</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.2.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.2.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-2">
            <div className="svg callflow-2">{svg.callflow_2}</div>
            <div className="svg arrow">{svg.arrow}</div>
            <img src={actions} alt="actions" className="svg actions" />
          </div>
        </div>
        <div>
          <div className="intro">
            <span>3</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.3.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.3.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-2">
            <img src={callflow} alt="actions" className="svg callflow-tree" />
          </div>
        </div>
      </div>
    </StyledHelpDialogEdit>
  );
  const ListDialog = (
    <StyledHelpDialogList>
      <h1>{t('phone_system:containers.callflows.dialog.help.content.title.0')}</h1>
      <h3>{t('phone_system:containers.callflows.dialog.help.content.subtitle.0')}</h3>
      <div>
        <div>
          <div className="intro">
            <span>1</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.0.intro.title')}
            </h2>
          </div>
          <div className="content content-0">
            <Button startIcon={<Icon name="plus-circle-outlined" size={16} />} variant="contained">
              {t(
                'phone_system:containers.callflows.dialog.help.content.panel.0.content.add_callflow',
              )}
            </Button>
            <div className="svg cursor">{svg.cursor}</div>
          </div>
        </div>
        <div>
          <div className="intro">
            <span>2</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.1.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.1.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-1">
            <div className="svg callflow">{svg.callflow}</div>
          </div>
        </div>
        <div>
          <div className="intro">
            <span>3</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.2.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.2.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-2">
            <div className="svg callflow-2">{svg.callflow_2}</div>
            <div className="svg arrow">{svg.arrow}</div>
            <img src={actions} alt="actions" className="svg actions" />
          </div>
        </div>
        <div>
          <div className="intro">
            <span>4</span>
            <h2>
              {t('phone_system:containers.callflows.dialog.help.content.panel.3.intro.title')}
            </h2>
            <h3>
              {t('phone_system:containers.callflows.dialog.help.content.panel.3.intro.subtitle')}
            </h3>
          </div>
          <div className="content content-2">
            <img src={callflow} alt="actions" className="svg callflow-tree" />
          </div>
        </div>
      </div>
    </StyledHelpDialogList>
  );

  return type === HelpDialogType.Edit ? (
    EditDialog
  ) : type === HelpDialogType.List ? (
    ListDialog
  ) : (
    <> </>
  );
};

export default HelpDialog;
