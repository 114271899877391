import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from 'shared/components/Box';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import { useToast } from '../useToast';
import { Breadcrumb, FilterDialog } from './components';
import defaultProps from './default';
import { useActionRowProps as Props } from './definition';
import StyledActionRow from './style';
import { buildButtons, buildIcons, buildSearch } from './utility';

export type { useActionRowProps, useActionRowTableProps } from './definition';
export { CONSTANTS as ACTION_ROW_CONSTANTS } from './style';

/**
 * Hooks returning Components Resource:
 * https://dev.to/droopytersen/new-react-hooks-pattern-return-a-component-31bh
 */
const ActionRow = memo(
  ({
    hasBottomBorder,
    hasBottomMargin,
    hasDelete,
    hasHelp,
    hasPadding,
    hasSave,
    hasSearch,
    isBreadcrumbAbove,
    isDeleteDisabled,
    isDirty,
    isSaveDisabled,
    addButton,
    breadcrumbData,
    buttonAlignment,
    deleteLabel,
    deleteTooltip,
    errorMessage,
    filter,
    globalFilter,
    help,
    leftButtons,
    rightButtons,
    saveButton,
    searchPlaceholder,
    tableType,
    onCancel,
    onDelete,
    onSave,
    onSearch,
    setGlobalFilter,
  }: Props) => {
    const { showToast } = useToast();
    const navigate = useNavigate();

    const on = {
      save: () => {
        try {
          onSave?.();
        } catch (exception) {
          showToast.error(errorMessage);
          throw exception;
        }
      },
      search: {
        change: (e: ChangeEvent<HTMLInputElement>) => {
          setGlobalFilter?.(e.target.value ?? '');
          onSearch?.(e.target.value ?? '');
        },
        reset: () => {
          setGlobalFilter?.('');
          onSearch?.('');
        },
      },
    };

    const breadcrumb = useMemo(
      () => (
        <Breadcrumbs
          breadcrumbsProps={{
            classes: {
              root: `MuiBreadcrumbs-actionrow MuiBreadcrumbs-actionrow-${
                isBreadcrumbAbove ? 'above' : 'default'
              }`,
            },
          }}
          data={breadcrumbData ?? []}
        />
      ),
      [isBreadcrumbAbove, breadcrumbData],
    );

    const buttons = buildButtons({
      hasDelete,
      isDeleteDisabled,
      isSaveDisabled: isSaveDisabled || !isDirty,
      addButton,
      deleteLabel,
      deleteTooltip,
      on,
      onCancel,
      onDelete,
      navigate,
    });
    const icons = buildIcons({ filter, help });
    const search = buildSearch({
      breadcrumbData,
      placeholder: searchPlaceholder,
      value: globalFilter,
      onChange: on.search.change,
      onReset: on.search.reset,
    });

    const buttonGroup = {
      left: leftButtons,
      right: (
        <>
          {rightButtons}
          {addButton?.hasAdd ? (
            addButton.component ?? buttons.add
          ) : (
            <>
              {hasDelete && buttons.delete}
              {hasSave && (
                <>
                  {buttons.cancel}
                  {saveButton ?? buttons.save}
                </>
              )}
            </>
          )}
        </>
      ),
    };

    return (
      <>
        <StyledActionRow
          hasBottomBorder={hasBottomBorder}
          hasBottomMargin={hasBottomMargin}
          hasPadding={{ ...defaultProps.hasPadding, ...hasPadding }}
          buttonAlignment={buttonAlignment}
          tableType={tableType}
        >
          {isBreadcrumbAbove && !isEmpty(breadcrumbData) && breadcrumb}
          <Box>
            {!isBreadcrumbAbove && !isEmpty(breadcrumbData) && (
              <Breadcrumb breadcrumb={breadcrumb} filterValues={filter?.breadcrumb} />
            )}
            {buttonGroup.left && (
              <Box component="span" className="button-group button-group-left">
                {buttonGroup.left}
              </Box>
            )}
            {hasSearch && search}
            {filter?.hasFilter && icons.filter}
            {hasHelp && icons.help}
            {buttonGroup.right}
          </Box>
        </StyledActionRow>
        <FilterDialog filter={filter} />
        {help?.dialogComponent}
      </>
    );
  },
);

export const useActionRow = (props: Props) => ({
  ActionRow,
  actionRowProps: { ...defaultProps, ...props },
});
