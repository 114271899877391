import { themeColor } from 'theme';
import theme from '../core';

const getStyle = (severity: string) => ({
  backgroundColor: themeColor.brio[severity].container,
  '.MuiAlert-icon svg': {
    fill: themeColor.brio[severity].main,
  },
  '.MuiAlert-action': {
    color: themeColor.brio[severity].main,
  },
  '.MuiAlert-message': {
    color: themeColor.brio[severity].dark,
  },
});

export default {
  styleOverrides: {
    root: {
      width: '100%',
      minHeight: 48,
      '&.MuiAlert-standard': {
        alignItems: 'center',
        '&.MuiAlert-standardError': getStyle('error'),
        '&.MuiAlert-standardInfo': getStyle('info'),
        '&.MuiAlert-standardSuccess': getStyle('success'),
        '&.MuiAlert-standardWarning': getStyle('warning'),
        '.MuiAlert-action': {
          margin: '0 0 0 auto',
          padding: theme.spacing(0, 0, 0, 5),
        },
        '.MuiAlert-message': {
          strong: {
            fontWeight: 600,
          },
        },
      },
      '&.MuiAlert-snackbar': {
        width: 300,
        alignItems: 'flex-start',
        boxShadow:
          '0 2px 4px -1px rgba(0 0 0 / 20%), 0 4px 5px 0 rgba(0 0 0 / 14%), 0 1px 10px 0 rgba(0 0 0 / 12%)',
        '.MuiAlert-action': {
          margin: theme.spacing(1.5, -0.66, 0, 'auto'),
          padding: theme.spacing(0, 0, 0, 3),
        },
      },
    },
  },
};
