import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { IS_ENV, TRANSLATION_NAMESPACE } from 'constant';
import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { Suspense, useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import useSetNewRelicAttrs from 'shared/hooks/useSetNewRelicAttrs';
import {
  AlertContextProvider,
  DialogContextProvider,
  HelmetProvider,
  SnackbarProvider,
} from 'store/providers';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { themeColor, themeCore, themeMui } from 'theme';
import { getRouter } from './utility';

/**
 * Whenever a new name space (NS) is added, please make sure to also
 * add the entry in the file `src/shared/utility/testing/translation.ts`
 */
i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    debug: IS_ENV.TEST,
    defaultNS: 'common',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en-US',
    ns: TRANSLATION_NAMESPACE,
    backend: {
      /**
       * Passing build hash to translation JSON to create unique URL after each
       * build. This technique will eliminate caching on translation JSON files.
       * Reference: `https://oomacorp.atlassian.net/browse/NEMO-691`
       */
      loadPath: IS_ENV.PRODUCTION
        ? `/locales/{{lng}}/{{ns}}.json?hash=${APP_VERSION}`
        : '/locales/{{lng}}/{{ns}}.json',
    },
  });

const App = () => {
  const { setNewRelicAttrs } = useSetNewRelicAttrs();

  useEffect(() => {
    setNewRelicAttrs();
  });

  return (
    <MuiThemeProvider theme={themeMui}>
      <ScThemeProvider theme={Object.assign(themeCore, themeColor)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider>
            <DialogContextProvider>
              <Suspense fallback={<Loading />}>
                <HelmetProvider />
                <AlertContextProvider>
                  <RouterProvider router={getRouter()} />
                </AlertContextProvider>
              </Suspense>
            </DialogContextProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ScThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
