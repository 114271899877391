import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StyledDirtyIconProps as Props } from './definition';

export const icon = {
  default: (props: any): FlattenSimpleInterpolation => css`
    ${mixin.flex(props.isInline ? { isInline: true } : { justifyContent: 'flex-end' })};

    ${props.isInline
      ? css`
          margin: 0 0 0 12px;
        `
      : css`
          width: 32px; // TODO: add global constant
        `}
  `,
  svg: (props: any): FlattenSimpleInterpolation => css`
    ${!props.isInline &&
    css`
      transform: translateY(9px);
    `};
  `,
};

const StyledDirtyIcon = styled.div<Props>`
  ${(props) => css`
    ${icon.default(props)};
    --color: ${props.isError ? props.theme.core.color.red : props.theme.core.color.green.default};

    > span {
      margin: -12px 0 0;
      color: var(--color);
      font-weight: normal;
      transform: scale(2);
    }

    > svg {
      ${icon.svg(props)};
      fill: var(--color);
    }
  `}
`;

export default StyledDirtyIcon;
