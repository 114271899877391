import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchGroupsQuery } from 'models/Group';
import { useFetchUsersQuery } from 'models/User';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import {
  Data,
  GroupedSelect,
  GroupPickupDialogProps as Props,
  GroupPickupNodeData,
} from './definition';
import schema from './schema';

const extractId = (data: Data) => {
  let id = defaultValues.id;
  if (data.hasOwnProperty('group_id')) id = data.group_id ?? '';
  if (data.hasOwnProperty('device_id')) id = data.device_id ?? '';
  if (data.hasOwnProperty('user_id')) id = data.user_id ?? '';
  return id;
};

const GroupPickupDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { data: usersData, isLoading: usersLoading } = useFetchUsersQuery();
  const { data: groupsData, isLoading: groupsLoading } = useFetchGroupsQuery();
  const { data: devicesData, isLoading: devicesLoading } = useFetchDevicesQuery();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      name: data?.name,
      nodeId: data?.nodeId,
      id: data ? extractId(data) : defaultValues.id,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const groupsLabel = t(
    'phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.groups',
  );
  const usersLabel = t(
    'phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.users',
  );
  const devicesLabel = t(
    'phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.devices',
  );
  const [options, setOptions] = useState<GroupedSelect>([
    {
      label: groupsLabel,
      options: [],
    },
    {
      label: usersLabel,
      options: [],
    },
    {
      label: devicesLabel,
      options: [],
    },
  ]);
  // when adding new input field, react form does not re-render
  const [, forceUpdateState] = useState();
  // @ts-ignore
  const forceUpdate = useCallback(() => forceUpdateState({}), []);
  const formatId = useCallback((id: string | undefined, groups: GroupedSelect) => {
    const map = {
      [groupsLabel]: 'group_id',
      [usersLabel]: 'user_id',
      [devicesLabel]: 'device_id',
    };
    const group = groups.find((group) => group.options.find((option) => option.value === id));

    if (group?.label) {
      return { [map[group.label]]: id };
    }
  }, []);
  const formatName = useCallback((id: string | undefined, groups: GroupedSelect) => {
    const group = groups.find((group) => group.options.find((option) => option.value === id));
    const option = group?.options.find((option) => option.value === id);
    return option?.label ?? '';
  }, []);
  const updateState = useCallback(
    (label: string, options: SelectOption[], prevState: GroupedSelect) => {
      const index = prevState.findIndex((option) => option.label === label);

      if (index > -1) {
        prevState[index]['options'] = options;
      } else {
        prevState.push({
          label: label,
          options: options,
        });
      }
      // Not re-rendering when state is being updated.
      forceUpdate();
      return prevState;
    },
    [],
  );

  useEffect(() => {
    if (groupsData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = groupsData.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        return updateState(groupsLabel, newOptions, prevState);
      });
    }
  }, [groupsData]);

  useEffect(() => {
    if (usersData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = usersData.map((user) => ({
          value: user.id,
          label: user.username,
        }));
        return updateState(usersLabel, newOptions, prevState);
      });
    }
  }, [usersData]);

  useEffect(() => {
    if (devicesData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = devicesData.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        return updateState(devicesLabel, newOptions, prevState);
      });
    }
  }, [devicesData]);

  const submitHandler = (formData: Data) => {
    const { id, ...rest } = formData;
    const formattedId = formatId(id, options);
    const formattedName = formatName(id, options);
    const nodeData: GroupPickupNodeData = {
      data: {
        ...rest,
        ...formattedId,
        name: formattedName,
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={usersLoading || groupsLoading || devicesLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.group_pickup.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="id" control={control} options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.group_pickup.add.endpoint',
            )}
            selectProps={{
              ...formProps,
              id: 'select-group-pickup-endpoint',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default GroupPickupDialog;
