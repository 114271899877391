import i18next from 'i18next';
import { PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';
import { useBlocker } from 'react-router-dom';
import { DialogActions } from 'shared/components/Dialog';
import { DialogActionsCloseReasons } from 'shared/components/Dialog/components/DialogActions/definition';
import { DialogContext } from 'store/contexts';
import { ExitConfirmationDialogContext } from '.';
import { OnDialogActionParams, OnExit, PathWhitelist } from './definition';

const onDialogAction = ({
  blocker,
  dialogCtx,
  onIsDirtyChange,
  onExit,
}: OnDialogActionParams) => async (closeResponse: { reason: DialogActionsCloseReasons }) => {
  switch (closeResponse.reason) {
    case 'cancelClicked':
      onIsDirtyChange?.(false);
      onExit?.();
      blocker.proceed?.();
      break;
    case 'saveClicked':
      blocker.reset?.();
      break;
    default:
      break;
  }
  dialogCtx.onClose();
};

const ExitConfirmationDialogContextProvider = ({ children }: PropsWithChildren<any>) => {
  const isDirtyRef = useRef(false);
  const pathWhitelistRef = useRef<PathWhitelist>([]);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirtyRef.current &&
      currentLocation.key !== nextLocation.key &&
      !pathWhitelistRef.current.some((path) => nextLocation.pathname.match(path)),
  );
  const dialogCtx = useContext(DialogContext);

  const getIsDirty = () => isDirtyRef.current;
  const getPathWhitelist = () => pathWhitelistRef.current;

  const onIsDirtyChange = useCallback((isDirty: boolean) => {
    isDirtyRef.current = isDirty;
  }, []);

  const onPathWhitelistChange = useCallback((pathWhitelist) => {
    pathWhitelistRef.current = pathWhitelist;
  }, []);

  /**
   * @description The function to open the confirmation dialog.
   * @param onExit The callback function on exit action is executed (i.e. "Leave page" button is clicked)
   */
  const openDialog = useCallback(
    (onExit?: OnExit) => {
      dialogCtx.onOpen({
        onClose: () => {
          blocker.reset?.();
          dialogCtx.onClose();
        },
        open: true,
        confirmMessage: i18next.t('common:navigation_blocker_dialog.content'),
        title: i18next.t('common:navigation_blocker_dialog.title'),
        renderActions: (
          <DialogActions
            hasDelete={false}
            cancelButtonProps={{
              color: 'error',
              variant: 'outlined',
            }}
            cancelLabel={i18next.t('common:navigation_blocker_dialog.actions.label.leave_page')}
            saveButtonProps={{
              variant: 'contained',
              label: i18next.t('common:navigation_blocker_dialog.actions.label.continue_editing'),
            }}
            onAction={onDialogAction({
              dialogCtx,
              blocker,
              onIsDirtyChange,
              onExit,
            })}
          />
        ),
      });
    },
    [blocker, dialogCtx, onIsDirtyChange],
  );

  const createExitConfirmationDialogHandler = useCallback(
    (onExit?: OnExit) => () => {
      if (getIsDirty?.()) {
        openDialog?.(onExit);
      } else {
        onExit?.();
      }
    },
    [openDialog],
  );

  const contextValue = useMemo(
    () => ({
      blocker,
      createExitConfirmationDialogHandler,
      getIsDirty,
      getPathWhitelist,
      onIsDirtyChange,
      onPathWhitelistChange,
      openDialog,
    }),
    [
      blocker,
      createExitConfirmationDialogHandler,
      onIsDirtyChange,
      onPathWhitelistChange,
      openDialog,
    ],
  );

  return (
    <ExitConfirmationDialogContext.Provider value={contextValue}>
      {children}
    </ExitConfirmationDialogContext.Provider>
  );
};

export default ExitConfirmationDialogContextProvider;
