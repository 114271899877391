import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { REGEX } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export type SectionProps = TabPanelProps<FormInput>;

export interface sipTrunkingEditProps {
  data?: any;
  id?: string;
}

export interface FormInput {
  server: {
    server_name: string;
    auth: {
      auth_method: string;
      ip: string;
    };
    options: {
      compatibilityMode: boolean;
      media_handling: string;
      inbound_format: string;
      flags: string;
    };
    server_type: string;
    trunkImageUrl: string;
  };
  account: {
    credits: {
      prepay: string;
    };
    trunks: number;
    inbound_trunks: string;
    auth_realm: string;
  };
  id: string;
}

export const schema = () =>
  Joi.object({
    server: Joi.object({
      server_type: Joi.string().required(),
      server_name: Joi.string().required(),
      auth: Joi.object({
        auth_method: Joi.string().valid('Password', 'IP').required(),
        auth_user: Joi.when(Joi.ref('auth_method'), {
          is: Joi.valid('Password'),
          then: Joi.string().required(),
          otherwise: Joi.optional(),
        }),
        auth_password: Joi.when(Joi.ref('auth_method'), {
          is: Joi.valid('Password'),
          then: Joi.string().required(),
          otherwise: Joi.optional(),
        }),
        ip: Joi.when(Joi.ref('auth_method'), {
          is: Joi.valid('IP'),
          then: Joi.string().pattern(REGEX.STRING.IP_V4).required(),
          otherwise: Joi.optional(),
        }),
      }).unknown(true),
      options: Joi.object({
        inbound_format: Joi.string(),
        flags: Joi.string().allow(''),
        compatibilityMode: Joi.boolean().required(),
      }).unknown(true),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());

export const inviteFormatOptions = [
  { value: 'e164', label: 'E.164@Realm' },
  { value: '1npan', label: '1 NPA NXX XXXX@Realm' },
  { value: 'username', label: 'SIP_Username@Realm' },
  { value: 'npan', label: 'NPA NXX XXXX@Realm' },
];

export const manufacturers = [
  { label: 'blue.box', value: 'blue.box' },
  { label: 'FreeSWITCH', value: 'FreeSWITCH' },
  { label: 'threecom', value: 'threecom' },
  { label: 'allworks', value: 'allworks' },
  { label: 'AltiGen', value: 'AltiGen' },
  { label: 'Asterisk', value: 'Asterisk' },
  { label: 'Avaya', value: 'Avaya' },
  { label: 'Cisco', value: 'Cisco' },
  { label: 'digium', value: 'digium' },
  { label: 'epygi', value: 'epygi' },
  { label: 'FreePBX', value: 'FreePBX' },
  { label: 'Mitel', value: 'Mitel' },
  { label: 'ObjectWorld', value: 'ObjectWorld' },
  { label: 'pingtel', value: 'pingtel' },
  { label: 'Response Point', value: 'Response Point' },
  { label: 'Samsung', value: 'Samsung' },
  { label: 'ShoreTel', value: 'ShoreTel' },
  { label: 'Sutus', value: 'Sutus' },
  { label: 'talkswitch', value: 'talkswitch' },
  { label: 'taridium', value: 'taridium' },
];

export const defaultTrunkData = {
  DIDs: {},
  options: {
    enabled: true,
    inbound_format: 'e164',
    international: false,
    caller_id: {},
    e911_info: {},
    failover: {},
    media_handling: 'bypass',
    delay: 0,
    force_outbound: false,
    hunt_non_reconcilable: false,
  },
  auth: {
    auth_method: 'Password',
  },
  permissions: {
    users: [],
  },
  monitor: {
    monitor_enabled: false,
  },
  cfg: {},
};

export const defaultFormValues = {
  server: {
    options: {
      media_handling: 'process',
      compatibilityMode: false,
      inbound_format: 'e164',
      flags: '',
    },
    auth: {
      auth_method: 'Password',
    },
  },
};
