import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import {
  CallRecordingStartDialogProps as Props,
  CallRecordingStartNodeData,
  Data,
} from './definition';
import schema from './schema';
import translations from './translations';

const StartCallRecordingDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { format, formats, time_limit, title, url } = useMemo(() => translations(), []);

  const submitHandler = (formData: Data) => {
    const nodeData: CallRecordingStartNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="format" control={control} options={formats}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={format.label}
            selectProps={{
              ...formProps,
              id: 'select-start-call-recording-format',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper name="url" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={url.label}
            inputWidth="large"
            inputProps={{
              ...formProps,
              id: 'input-start-call-recording-url',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper name="time_limit" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={time_limit.label}
            inputWidth="large"
            inputProps={{
              ...formProps,
              id: 'input-start-call-recording-time-limit',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default StartCallRecordingDialog;
