import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '.MuiPaper-root': {
        width: 560,
        maxWidth: 560,
        backgroundColor: themeColor.core.color.white,
      },
      '&.MuiDialog-callflow-actions, &.MuiDialog-callflow-preview, &.MuiDialog-download-file, &.MuiDialog-help, &.MuiDialog-xlarge': {
        '.MuiPaper-root': {
          width: 1024,
          maxWidth: 1024,
        },
      },
      '&.MuiDialog-callflow-actions, &.MuiDialog-download-file, &.MuiDialog-medium, &.MuiDialog-xlarge': {
        '.MuiPaper-root': {
          backgroundColor: themeColor.core.color.white,
        },
      },
      '&.MuiDialog-filter': {
        '.MuiPaper-root': {
          position: 'absolute',
          top: 40,
          left: 'auto',
          right: 206,
          width: 320,
          maxWidth: 320,
          padding: theme.spacing(2, 2),
          boxShadow:
            '0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12)',
        },
      },
      '&.MuiDialog-help': {
        '.MuiPaper-root': {
          padding: theme.spacing(0, 2.66, 2.66),
        },
      },
      '&.MuiDialog-medium': {
        '.MuiPaper-root': {
          width: 700,
          maxWidth: 700,
        },
      },
      '&.MuiDialog-device-picker': {
        '.MuiPaper-root': {
          width: 840,
          maxWidth: 840,
        },
      },
      '&.MuiDialog-callflow-preview, &.MuiDialog-help': {
        '.MuiPaper-root': {
          backgroundColor: themeColor.core.background.grey.medium,
        },
      },
      '&.MuiDialog-callflow-preview': {
        '.MuiPaper-root': {
          padding: 0,
        },
      },
    },
  },
};
