import { CallflowContextProvider } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import FlowContainer from 'apps/PhoneSystem/containers/Callflows/Edit/components/FlowContainer';
import { useFetchCallflowByIdQuery, useFetchCallflowsByOwnerUsernameQuery } from 'models/Callflow';
import { addCallFlow } from 'models/Callflow/store/slice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Loading from 'shared/components/Loading';
import { TabCallflowProps as Props } from './definition';
import StyledTabCallflow from './style';

const TabCallflow = ({ username }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = useFetchCallflowsByOwnerUsernameQuery(
    { username },
    { skip: !username },
  );

  const { data: callflowData, isLoading: isLoadingCallflow } = useFetchCallflowByIdQuery(
    { id: data?.[0]?.id },
    { skip: !data || !data.length },
  );

  /**
   * TODO: Review/Refactor the use of `useFetchCallflowByIdQuery` above
   * and the `useEffect` call below. This pattern is also implemented in
   * the `QuickViewCallflow` component.
   *
   * `dispatch` is used here specifically in order for `CallflowTree`
   * to render properly--otherwise, CallflowTree's internal assignment
   * of `root` will return `undefined`.
   */
  useEffect(() => {
    if (callflowData) {
      dispatch(addCallFlow(callflowData));
    }
  }, [callflowData, dispatch]);

  const onCallflowClicked = () => navigate(`../../callflows/${callflowData.id}`);

  if (isLoading || isLoadingCallflow) {
    return <Loading />;
  }

  return (
    <StyledTabCallflow hasCallflow={!!callflowData}>
      {callflowData ? (
        <>
          <div>
            <Button
              color="secondary"
              variant="contained"
              onClick={onCallflowClicked}
              startIcon={<Icon name="external-link" size={21} />}
            >
              {t('phone_system:containers.seats.callflows.button.open_callflow_editor.label')}
            </Button>
          </div>
          <CallflowContextProvider isPreview callflow={callflowData}>
            <FlowContainer />
          </CallflowContextProvider>
        </>
      ) : (
        <span>{t('phone_system:containers.seats.callflows.no_callflow_linked_to_seat')}</span>
      )}
    </StyledTabCallflow>
  );
};

export default TabCallflow;
