import { FormContext } from 'apps/shared/components/FormContext';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import useTabs from 'apps/shared/hooks/useTabs';
import { FunctionComponent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getDeepKeys } from 'shared/utility';
import CallerIdSection, { fields as callerIdFields } from './components/CallerIdSection';
import Emergency911Section, { fields as e911Fields } from './components/Emergency911Section';
import FailoverSection, { fields as failoverFields } from './components/FailoverSection';
import PrependSection, { fields as prependFields } from './components/PrependSection';
import SmsEnablement, { fields as smsEnablementFields } from './components/SmsEnablementSection';
import { Items, NumbersInUseEditProps as Props } from './definition';

const Form: FunctionComponent<Props> = ({ hasError }: Props): JSX.Element => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);
  const { sip } = useParams();

  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const isTab = (mode: string, fields: string[] = []) =>
    getDeepKeys(mode === 'dirty' ? dirtyFields : errors).filter((field: string) =>
      fields.map((field: string) => `${contextName}${field}`).includes(field),
    ).length > 0;

  const items: Items = {
    e911: {
      hash: 'e911',
      label: t('numbers:containers.numbers_in_use.section.e911.label'),
      component: <Emergency911Section />,
      isDirty: isTab('dirty', e911Fields),
      isError: isTab('error', e911Fields) || hasError,
    },
    caller_id: {
      hash: 'caller_id',
      label: t('numbers:containers.numbers_in_use.section.caller_id.label'),
      component: <CallerIdSection />,
      isDirty: isTab('dirty', callerIdFields),
      isError: isTab('error', callerIdFields),
    },
    prepend: {
      hash: 'prepend',
      label: t('numbers:containers.numbers_in_use.section.prepend.label'),
      component: <PrependSection />,
      isDirty: isTab('dirty', prependFields),
      isError: isTab('error', prependFields),
    },
  };

  if (sip) {
    items.failover = {
      hash: 'failover',
      label: t('numbers:containers.numbers_in_use.section.failover.label'),
      component: <FailoverSection />,
      isDirty: isTab('dirty', failoverFields),
      isError: isTab('error', failoverFields),
    };
    items.sms_enablement = {
      hash: 'sms_enablement',
      label: t('numbers:containers.numbers_in_use.section.sms_enablement.label'),
      component: <SmsEnablement />,
      isDirty: isTab('dirty', smsEnablementFields),
      isError: isTab('error', smsEnablementFields),
    };
  }

  const { Tabs, TabPanels } = useTabs({ items: Object.values(items) });

  return (
    <EditPanel>
      {Tabs}
      {TabPanels}
    </EditPanel>
  );
};

export default Form;
