export const defaultValues = {
  cycle: 'weekly',
  days: ['1'],
  duration: { start: '0', stop: '0' },
  enabled: 'based_on_time',
  end_date: '',
  has_no_end_date: true,
  interval: '1',
  is_all_day_event: false,
  month: '',
  name: '',
  ordinal: '',
  range_start: '1',
  range_stop: '1',
  start_date: '',
  time_window_start: '',
  time_window_stop: '',
  wdays: [],
};
