import { joiResolver } from '@hookform/resolvers/joi';
import MediaSelect, { SILENCE } from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { ADD_KEY } from 'constant';
import { mediaQueries } from 'models/Media/Queries';
import { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import AddMediaDialog from './AddMediaDialog';
import defaultProps, { defaultValues } from './default';
import { Data, MediaCallflowDialogProps as Props, MediaNodeData } from './definition';
import schema from './schema';

const MediaDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, getValues, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      advanced: Boolean(data?.var),
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const watchId = watch('id');
  const watchAdvanced = watch('advanced');
  const [editId, setEditId] = useState<string>(watchId);
  const [itemName, setItemName] = useState<string>();

  const submitHandler = async (formData: Data) => {
    let name: string;

    switch (formData.id) {
      case '':
        name = t('phone_system:shared.media_select.options.default_music');
        break;
      case SILENCE.id:
        name = t(SILENCE.name_key);
        break;
      default:
        const fetchByIdResponse: any = await dispatch(
          mediaQueries.endpoints.fetchMediaById.initiate({ id: formData.id }),
        );
        name = fetchByIdResponse.data?.name;
        break;
    }
    const data = { ...formData };

    if (!data.advanced) {
      data.var = '';
    }

    const nodeData: MediaNodeData = {
      metadata: {
        name,
        pvt_type: 'media',
      },
      data,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      isOpen
      title={t('phone_system:containers.callflows.callflow_action_dialog.media.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <AddMediaDialog
        editId={editId}
        hasEditButton={(watchId && watchId !== '' && watchId !== SILENCE.id) as boolean}
        selectComponent={
          <MediaSelect
            name="id"
            control={control}
            hasDirtyIcon
            isClearable={false} // todo changed this to false since it was setting id to empty string
            isLabelAbove
            label={t('phone_system:containers.callflows.callflow_action_dialog.media.add.media')}
            {...(itemName ? { itemName } : {})}
          />
        }
        addClickHandler={() => setEditId(ADD_KEY)}
        editClickHandler={() => setEditId(getValues('id'))}
        setItemName={setItemName}
      />

      <HookFormInputWrapper name="advanced" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            feedback={feedback}
            label={t('phone_system:containers.callflows.callflow_action_dialog.media.add.advanced')}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>

      {watchAdvanced && (
        <HookFormInputWrapper name="var" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              isLabelAbove
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.media.add.route_variable',
              )}
              inputWidth="small"
              inputProps={{
                ...formProps,
              }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default MediaDialog;
