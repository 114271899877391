// PhoneNumber/index.ts
import { JOI } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { phoneNumberMutations } from './Mutations';
import { phoneNumberQueries } from './Queries';

export const schema = () =>
  Joi.object({
    prepend: Joi.object({
      name: Joi.string().empty(''),
      enabled: Joi.boolean(),
    }).unknown(true),
    e911: Joi.object({
      customer_name: Joi.string().required(),
      locality: Joi.string().required(),
      postal_code: Joi.string().required(),
      region: Joi.string().max(2).required(),
      street_address: Joi.string().required(),
      notification_contact_emails: Joi.string()
        .email({ ...JOI.EMAIL, multiple: true })
        .optional()
        .allow(''),
    }).unknown(true),
    cnam: Joi.object({
      inbound_lookup: Joi.boolean(),
      display_name: Joi.string().empty('').max(15),
    }).unknown(true),
    failover: Joi.object({
      method: Joi.string().empty(''),
      e164: Joi.string().empty('').when('method', {
        is: 'e164',
        then: Joi.string().required(),
        otherwise: Joi.optional(),
      }),
      sip: Joi.string().empty('').when('method', {
        is: 'sip',
        then: Joi.string().required(),
        otherwise: Joi.optional(),
      }),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());

export const {
  useFetchAvailableNumbersByPrefixQuery,
  useFetchClassifiersQuery,
  useFetchInServiceAndPortInPhoneNumbersQuery,
  useFetchPhoneNumberByIdQuery,
  useFetchPhoneNumbersQuery,
  useLazyCheckAvailableCollectionQuery,
  useLazyFetchPhoneNumbersQuery,
} = phoneNumberQueries;

export const {
  useActivateCollectionMutation,
  useAddPhoneNumbersToCollectionMutation,
  useDeleteListMutation,
  useDeletePhoneNumberMutation,
  useMoveCollectionToMutation,
  useUpdatePhoneNumberMutation,
} = phoneNumberMutations;
