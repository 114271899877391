import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps as Props } from 'apps/PhoneSystem/definition';
import { ADD_KEY } from 'constant';
import Joi from 'joi';
import merge from 'lodash/merge';
import {
  useFetchAccountQuery,
  useFetchAccountSeatCountQuery,
  usePatchAccountByIdMutation,
} from 'models/Account';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { messages } from 'shared/utility/validation';
import { schema } from './components';
import { DEFAULT_LOCATION_ID } from './constant';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';
import {
  buildAccountMutation,
  getLocationAttrsForSaving,
  prepareSaveData,
  removeSessionValue,
} from './utility';

const Edit = ({ id: locationId, handleDeleteSuccess, handleSaveSuccess }: Props) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useShowErrorMessage();

  const { data: accountData, isLoading: isAccountDataLoading } = useFetchAccountQuery();
  const {
    data: seatCountData,
    isLoading: isSeatCountDataLoading,
  } = useFetchAccountSeatCountQuery();

  const [patchAccountById] = usePatchAccountByIdMutation();
  const patchAccount = buildAccountMutation({
    accountId: accountData?.id,
    mutation: patchAccountById,
  });

  const formMethods = useForm<FormInput>({
    defaultValues,
    resolver: joiResolver(
      Joi.object({
        ...schema.options(),
        ...schema.callerId(),
      })
        .unknown(true)
        .messages(messages()),
    ),
  });

  const {
    formState: { isDirty: isPageDirty },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const hasSeats = (seatCountData?.find((item) => locationId in item)?.[locationId].total ?? 0) > 0;
  const isAdd = locationId === ADD_KEY;
  const locations = accountData?.locations;
  const currentLocation = locations?.[locationId];

  const onDelete = async () => {
    try {
      await patchAccount({ data: null, locationId });
      removeSessionValue();
      handleDeleteSuccess?.();
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  const onSave = useCallback(
    () =>
      handleSubmit(
        async (formData: FormInput) => {
          try {
            const { id, message } = getLocationAttrsForSaving(locationId);
            await patchAccount({ locationId: id, data: prepareSaveData(formData) });
            removeSessionValue();
            handleSaveSuccess?.({ shouldRedirect: isAdd, toastMessage: message });
          } catch (exception) {
            showErrorMessage({ isFromException: true, errors: exception, setError });
          }
        },
        (errors) => showErrorMessage({ errors, setError }),
      ),
    [isAdd, locationId, handleSaveSuccess, handleSubmit, patchAccount, setError, showErrorMessage],
  );

  useEffect(() => {
    if (currentLocation) {
      reset(merge({}, defaultValues, currentLocation));
    }
  }, [accountData, currentLocation, locationId, reset]);

  if (isAccountDataLoading || isSeatCountDataLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      isDeleteDisabled={hasSeats}
      isPageDirty={isPageDirty}
      deleteConfirm={t(
        'phone_system:containers.account.submodule.site_locations.edit.delete.message.confirmation',
      )}
      entityLabel={t(
        'phone_system:containers.account.submodule.site_locations.edit.label.breadcrumb',
      )}
      entityName={
        currentLocation?.display_name ??
        t('phone_system:containers.account.submodule.site_locations.edit.label.new_location')
      }
      formMethods={formMethods}
      onSave={onSave()}
      {...(!isAdd && !(locationId === DEFAULT_LOCATION_ID) ? { onDelete } : {})}
      {...(hasSeats
        ? {
            deleteTooltip: t(
              'phone_system:containers.account.submodule.site_locations.edit.delete.message.disabled',
            ),
          }
        : {})}
    >
      <FormProvider {...formMethods}>
        <Form id={locationId} />
      </FormProvider>
    </EditForm>
  );
};

export default Edit;
