export default {
  brio: {
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
    },
    background: {
      default: 'rgb(250, 250, 250)',
      paper: 'rgb(255, 255, 255)',
    },
    common: {
      white: 'rgb(255, 255, 255)',
      black: 'rgb(0, 0, 0)',
    },
    error: {
      container: 'rgb(255, 233, 232)',
      contrast: 'rgba(0, 0, 0, 0)',
      dark: 'rgb(104, 0, 3)',
      highlight: 'rgb(246, 210, 208)',
      light: 'rgb(227, 85, 79)',
      main: 'rgb(221, 55, 48)',
    },
    greyscale: {
      50: 'rgb(250, 250, 250)',
      100: 'rgb(245, 245, 245)',
      200: 'rgb(238, 238, 238)',
      300: 'rgb(224, 224, 224)',
      400: 'rgb(189, 189, 189)',
      500: 'rgb(158, 158, 158)',
      600: 'rgb(117, 117, 117)',
      700: 'rgb(97, 97, 97)',
      800: 'rgb(66, 66, 66)',
      900: 'rgb(33, 33, 33)',
      A100: 'rgb(245, 245, 245)',
      A200: 'rgb(238, 238, 238)',
      A400: 'rgb(189, 189, 189)',
      A700: 'rgb(97, 97, 97)',
    },
    info: {
      container: 'rgb(230, 239, 250)',
      contrast: 'rgba(0, 0, 0, 0)',
      dark: 'rgb(26, 77, 143)',
      highlight: 'rgb(195, 217, 241)',
      light: 'rgb(42, 118, 218)',
      main: 'rgb(35, 102, 189)',
    },
    ooma: {
      burgundy: 'rgb(207, 51, 57)',
      faintgray: 'rgb(248, 248, 248)',
      graphite: 'rgb(49, 62, 72)',
      lgraphite: 'rgb(100, 102, 107)',
      lgrey: 'rgb(227, 227, 227)',
      marine: 'rgb(5, 130, 153)',
      oorange: 'rgb(244, 125, 65)',
      oteal: 'rgb(67, 190, 172)',
      oyellow: 'rgb(254, 196, 111)',
    },
    other: {
      backdrop: 'rgba(0, 0, 0, 0.56)',
      divider: 'rgba(0, 0, 0, 0.12)',
      input: 'rgba(0, 0, 0, 0.04)',
    },
    primary: {
      container: 'rgb(247, 248, 250)',
      contrast: 'rgb(255, 255, 255)',
      dark: 'rgb(31, 41, 48)',
      highlight: 'rgb(223, 234, 243)',
      light: 'rgb(74, 87, 98)',
      main: 'rgb(49, 62, 72)',
    },
    secondary: {
      container: 'rgb(247, 248, 250)',
      contrast: 'rgb(255, 255, 255)',
      dark: 'rgb(31, 41, 48)',
      highlight: 'rgb(223, 234, 243)',
      light: 'rgb(74, 87, 98)',
      main: 'rgb(49, 62, 72)',
    },
    success: {
      container: 'rgb(225, 245, 230)',
      contrast: 'rgba(0, 0, 0, 0)',
      dark: 'rgb(0, 110, 37)',
      highlight: 'rgb(192, 235, 203)',
      light: 'rgb(30, 198, 74)',
      main: 'rgb(23, 168, 61)',
    },
    text: {
      disabled_light: 'rgba(255, 255, 255, 0.4)',
      disabled: 'rgba(0, 0, 0, 0.4)',
      primary_light: 'rgba(255, 255, 255, 0.94)',
      primary: 'rgba(0, 0, 0, 0.94)',
      secondary_light: 'rgba(255, 255, 255, 0.64)',
      secondary: 'rgba(0, 0, 0, 0.64)',
    },
    warning: {
      container: 'rgb(255, 242, 224)',
      contrast: 'rgba(0, 0, 0, 0)',
      dark: 'rgb(167, 92, 13)',
      highlight: 'rgb(253, 222, 179)',
      light: 'rgb(239, 161, 80)',
      main: 'rgb(232, 127, 18)',
    },
  },
};
