import metaAccount from 'apps/PhoneSystem/containers/Account/meta';
import { meta as metaCallBlocking } from 'apps/PhoneSystem/containers/CallBlocking';
import { meta as metaCallflows } from 'apps/PhoneSystem/containers/Callflows';
import { meta as metaConferences } from 'apps/PhoneSystem/containers/Conferences';
import { meta as metaDashboard } from 'apps/PhoneSystem/containers/Dashboard';
import { meta as metaDevices } from 'apps/PhoneSystem/containers/Devices';
import { meta as metaDirectories } from 'apps/PhoneSystem/containers/Directories';
import { meta as metaFaxes } from 'apps/PhoneSystem/containers/Faxes';
import { meta as metaFeatureCodes } from 'apps/PhoneSystem/containers/FeatureCodes';
import { meta as metaGroups } from 'apps/PhoneSystem/containers/Groups';
import { meta as metaMedia } from 'apps/PhoneSystem/containers/Media';
import { meta as metaMenu } from 'apps/PhoneSystem/containers/Menu';
import { meta as metaSeats } from 'apps/PhoneSystem/containers/Seats';
import metaSMS from 'apps/PhoneSystem/containers/SMS/meta';
import { meta as metaTimeOfDay } from 'apps/PhoneSystem/containers/TimeOfDay';
import { meta as metaVoicemails } from 'apps/PhoneSystem/containers/Voicemails';
import { ContainerMeta } from 'apps/shared/definition';
import { SideNavItem } from './components/SideNavItem/definition';

// primary (always visible) containers
const primary = [metaAccount, metaDashboard, metaSeats, metaCallflows];

// container listing; order matters
export const items = [
  metaDashboard,
  metaAccount,
  metaSeats,
  metaCallflows,
  metaDevices,
  metaSMS,
  metaVoicemails,
  metaConferences,
  metaFaxes,
  metaGroups,
  metaTimeOfDay,
  metaFeatureCodes,
  metaMenu,
  metaMedia,
  metaDirectories,
  metaCallBlocking,
].map(
  (container: ContainerMeta): SideNavItem => {
    const { label, icon, slug } = container ?? {};
    return {
      icon: {
        name: icon,
      },
      label,
      slug,
      isPrimary: primary.includes(container),
    };
  },
);
