// Voicemail/index.ts
import { JOI, REGEX, VALIDATION } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';
import { voicemailMutations } from './Mutations';
import { voicemailQueries } from './Queries';

export const schema = () =>
  Joi.object({
    //Basic
    name: Joi.string().min(1).max(128).required(),
    mailbox: Joi.string()
      .min(1)
      .max(30)
      .regex(REGEX.STRING.NUMERIC)
      .required()
      .messages({ 'string.pattern.base': messages()['number.base'] }),
    pin: Joi.string()
      .min(VALIDATION.VOICEMAIL.PIN.MIN)
      .max(VALIDATION.VOICEMAIL.PIN.MAX)
      .regex(VALIDATION.VOICEMAIL.PIN.REGEX)
      .required()
      .messages({
        'string.pattern.base': i18next.t('common:validation.general.number_only'),
        'string.min': i18next.t('common:validation.general.string_range', {
          min: VALIDATION.VOICEMAIL.PIN.MIN,
          max: VALIDATION.VOICEMAIL.PIN.MAX,
        }),
        'string.max': i18next.t('common:validation.general.string_range', {
          min: VALIDATION.VOICEMAIL.PIN.MIN,
          max: VALIDATION.VOICEMAIL.PIN.MAX,
        }),
      }),

    //Extension Dialing
    hunt: Joi.boolean(),
    hunt_allow: Joi.string().min(1).max(256).empty(''),
    hunt_deny: Joi.string().min(1).max(256).empty(''),

    //Advanced Options
    media_extension: Joi.string().valid('mp3', 'mp4', 'wav'),
    is_setup: Joi.boolean(),
    check_if_owner: Joi.boolean(),
    skip_instructions: Joi.boolean(),
    delete_after_notify: Joi.boolean(),
    require_pin: Joi.boolean(),
    skip_greeting: Joi.boolean(),
    announcement_only: Joi.boolean(),
    not_configurable: Joi.boolean(),
    oldest_message_first: Joi.boolean(),
    operator_number: Joi.string().empty(''),

    //Recipients
    include_message_on_notify: Joi.boolean(),
    transcribe: Joi.boolean(),
    vm_message_forward_type: Joi.string().valid('only_forward', 'prepend_forward'),
    after_forward_action: Joi.string().valid('prompt', 'save'),
    play_envelope_action: Joi.string().valid('play_after', 'play_before', 'skip'),
    notify_email_addresses: Joi.array().items(Joi.string().email(JOI.EMAIL)),

    shared_vmbox: Joi.boolean().optional(), //shared_vmbox is calculated on server as !members
    members: Joi.array()
      .items(
        Joi.object({
          id: Joi.string().optional(),
          type: Joi.string().valid('user', 'group').optional(),
          key: Joi.string().optional(),
          name: Joi.string().optional(),
          endpoint_type: Joi.string().optional(),
        }).unknown(true),
      )
      .optional(),

    //Greetings
    media: Joi.object({
      unavailable: Joi.string().min(32).max(32),
      temporary_unavailable: Joi.string(),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());

export const {
  useFetchSharedVoicemailsQuery,
  useFetchVoicemailsQuery,
  useFetchVoicemailByIdQuery,
  useFetchVoicemailsByOwnerIdQuery,
  useLazyFetchVoicemailsByOwnerIdQuery,
} = voicemailQueries;

export const {
  useUpdateVoicemailMutation,
  useDeleteVoicemailMutation,
  useCreateVoicemailMutation,
} = voicemailMutations;
