import { themeColor } from 'theme/local';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      padding: 0,
      '&.MuiTypography-h2': {
        padding: theme.spacing(1, 0),
        color: theme.palette.common.black,
        fontSize: 14,
        fontWeight: 600,
        '&.MuiTypography-error': {
          color: themeColor.core.color.red,
        },
      },
    },
  },
};
