import styled, { css } from 'styled-components';
import { icon } from '../DirtyIcon';
import { StyledInfoIconProps as Props } from './definition';

const StyledInfoIcon = styled.div<Props>`
  ${(props) => css`
    ${icon.default(props)};

    > svg {
      ${icon.svg(props)};
      margin: 1px 0 0;
      fill: ${props.theme.whitelabel.secondary.color};
    }
  `}
`;

export default StyledInfoIcon;
