import { FilterGroup, FilterType, removeFiltersSession } from 'apps/shared/hooks/useFilter';
import { ADD_KEY, SESSION_STORAGE } from 'constant';
import i18next from 'i18next';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { CallerIdProperty, CallerIdType, FormInput } from '../definition';

/**
 * @name buildAccountMutation
 * @description Build a wrapper function using the `patchAccountById` mutation.
 *
 * @param [object]
 * @property accountId
 * @property mutation
 *
 * @param [object]
 * @property data
 * @property locationId
 */
export const buildAccountMutation = ({
  accountId,
  mutation,
}: {
  accountId: string;
  mutation?: any;
}) => async ({ data, locationId }: { data: any; locationId: string }) => {
  await mutation({
    body: {
      locations: {
        [locationId]: data,
      },
    },
    id: accountId,
  });
};

/**
 * @name getLocationAttrsForSaving
 * @description Determine the site location id and messages (during save).
 *
 * @param locationId
 *
 * @returns Site location id and message.
 */
export const getLocationAttrsForSaving = (locationId: string) => {
  const data = {
    id: locationId,
    message: i18next.t('common:saved'),
  };

  if (locationId === ADD_KEY) {
    data.id = uuidv4();
    data.message = i18next.t(
      'phone_system:containers.account.submodule.site_locations.edit.label.new_location_created',
    );
  }

  return data;
};

/**
 * @name prepareSaveData
 * @description Prepare the form data for saving (remove extraneous `caller_id` data)
 *
 * @param formData
 *
 * @returns Prepared save data object.
 */
export const prepareSaveData = (formData: FormInput) =>
  produce(formData, (draft: any) => {
    Object.values(CallerIdType).forEach((type: CallerIdType) => {
      Object.values(CallerIdProperty).forEach((property: CallerIdProperty) => {
        if ((formData.caller_id as any)[type]?.[property] === '') {
          (draft.caller_id as any)[type][property] = null;
        }
      });

      if (
        isEmpty(formData.caller_id?.[type]) ||
        (isEmpty(draft.caller_id?.[type]?.name) && isEmpty(draft.caller_id?.[type]?.number))
      ) {
        (draft.caller_id as any)[type] = null;
      }
    });

    if (
      isEmpty(draft.caller_id?.[CallerIdType.Emergency]) &&
      isEmpty(draft.caller_id?.[CallerIdType.External])
    ) {
      draft.caller_id = null;
    }
  });

/**
 * @name removeSessionValue
 * @description Remove session storage `seat_location` value.
 */
export const removeSessionValue = () => {
  removeFiltersSession({
    filterGroup: FilterGroup.Seats,
    filterType: FilterType.SeatLocation,
    key: SESSION_STORAGE.TABLE.FILTER,
  });
};
