import i18next from 'i18next';

/**
 * @name sortSeatsData
 * @description - Sorts an array of seat data by last name
 *
 * @param seatsData - Array of seat data
 * @returns - Sorted array of seat data
 */
export const sortSeatsData = (seatsData: Seat | undefined) =>
  seatsData.slice().sort((a: Seat, b: Seat) => {
    if (a.last_name === '') {
      return 1;
    }
    if (b.last_name === '') {
      return -1;
    }
    if (a.last_name === b.last_name) {
      return 0;
    }
    return a.last_name.localeCompare(b.last_name);
  });

/**
 * @name getSeatsCsvBlob
 * @description Generates a CSV blob from seat data
 *
 * @param seatsData - Array of seat data
 * @param seatTypes - Object containing seat types
 * @returns - Array of BlobPart representing CSV data
 */
export const getSeatsCsvBlob = (
  seatsData: Seat[] | undefined,
  seatTypes: SeatType | undefined,
): Array<BlobPart> => {
  const defaultTimezone = i18next.t('common:component.labeled_select_time_zone.default_option');

  if (!seatsData || seatsData.length === 0 || !seatTypes) {
    return [];
  }

  return [
    i18next.t('phone_system:containers.seats.action_row.download.file.columns'),
    ...sortSeatsData(seatsData).map(
      ({ first_name, last_name, email, username, seat_type, timezone }: Seat) =>
        `${first_name},${last_name},${email},${username},${seatTypes[seat_type]?.display_name},${
          timezone || defaultTimezone
        }\n`,
    ),
  ];
};
