import styled, { css } from 'styled-components';

const StyledErrorSpan = styled.span`
  ${(props) => css`
    color: ${props.theme.core.states.error.main};
    font-style: italic;
  `};
`;

export default StyledErrorSpan;
