import { ClassifierSet } from 'definition';
import { TEMP_PROPERTY } from './utility';

const defaultCallRestriction = ['closed_groups', ...Object.values(ClassifierSet)].reduce(
  (acc: Record<string, Record<string, 'inherit'>>, classifier: string) => {
    acc[classifier] = { action: 'inherit' };
    return acc;
  },
  {},
);

export const defaultValues = {
  announcement: '',
  caller_id: {
    emergency: {
      name: '',
      number: '',
    },
    external: {
      name: '',
      number: '',
    },
    internal: {
      name: '',
      number: '',
    },
  },
  call_restriction: defaultCallRestriction,
  contact: {
    billing: {
      country: '',
      email: '',
      locality: '',
      name: '',
      number: '',
      postal_code: '',
      region: '',
      street_address: '',
    },
    technical: {
      email: '',
      name: '',
      number: '',
      same_as_billing: false,
    },
  },
  custom_notes: '',
  language: 'en-US',
  name: '',
  realm: '',
  timezone: 'America/Vancouver',

  [TEMP_PROPERTY.NOTIFICATIONS]: {
    voicemail_to_email: {
      from: 'no_reply@voxter.com',
    },
    fax_inbound_error_to_email: {
      enabled: false,
      from: 'no_reply@voxter.com',
      use_voicemail_to_email_from: false,
    },
    deregister: {
      enabled: true,
      from: '',
      subject: '',
      to: {
        email_addresses: [],
        type: '',
      },
    },
  },
};
