import { svg } from 'apps/shared/svg';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Switch from 'shared/components/Switch';
import { HelpDialogContentProps as Props } from './definition';
import StyledHelpDialogContent from './style';

const HelpDialogContent: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledHelpDialogContent>
      <h1>{t('phone_system:containers.call_blocking.dialog.help.content.title')}</h1>
      <h3>{t('phone_system:containers.call_blocking.dialog.help.content.subtitle')}</h3>
      <div>
        <div>
          <div className="intro">
            <span>1</span>
            <h2>
              {t('phone_system:containers.call_blocking.dialog.help.content.panel_0.intro.title')}
            </h2>
            <h3>
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_0.intro.subtitle',
              )}
            </h3>
          </div>
          <div className="content content-0">
            <h2>
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.prompt',
              )}
            </h2>
            <div className="widget widget-0">
              <div>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.widget_0.label.block_anonymous_numbers',
                  )}
                </span>
                <span>
                  <Switch checked={false} onChange={() => {}} />
                </span>
                <div className="cursor cursor-0">{svg.cursor}</div>
              </div>
              <div>
                <span>
                  <Switch checked={false} onChange={() => {}} />
                </span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.widget_0.label.disabled',
                  )}
                </span>
                <span>
                  <Switch checked onChange={() => {}} />
                </span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_0.content_0.widget_0.label.enabled',
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="intro">
            <span>2</span>
            <h2>
              {t('phone_system:containers.call_blocking.dialog.help.content.panel_1.intro.title')}
            </h2>
            <h3>
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_1.intro.subtitle',
              )}
            </h3>
          </div>
          <div className="content content-1">
            <h2>
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_0.prompt',
              )}
            </h2>
            <Button startIcon={<Icon name="plus-circle-outlined" size={16} />} variant="contained">
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_0.add_call_blocking',
              )}
            </Button>
            <div className="cursor cursor-1">{svg.cursor}</div>
          </div>
          <div className="content content-2">
            <h2>
              {t(
                'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.prompt',
              )}
            </h2>
            <div className="widget widget-1">
              <div>
                <span>
                  <Icon name="dropdown-down" subset={16} />
                </span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.name',
                  )}
                </span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.numbers',
                  )}
                </span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.enabled',
                  )}
                </span>
                <span></span>
              </div>
              <div>
                <span></span>
                <span>
                  {t(
                    'phone_system:containers.call_blocking.dialog.help.content.panel_1.content_1.label.list_a',
                  )}
                </span>
                <span>+16043466246</span>
                <span>
                  <Switch checked onChange={() => {}} />
                </span>
                <span>
                  <Icon name="edit" />
                  <div className="cursor cursor-2">{svg.cursor}</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHelpDialogContent>
  );
};

export default HelpDialogContent;
