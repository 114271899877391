import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import {
  getEndpointIcon,
  nameFormatter,
  remapEndpoints,
} from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import { FunctionComponent, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DialogType } from 'shared/components/Dialog';
import Icon from 'shared/components/Icon';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { Column } from 'shared/components/Table';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues, endpointDefaultValues } from './default';
import { Data, PageGroupDialogProps as Props, PageGroupNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const PageGroupDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const updatedEndpoints = data?.endpoints?.map((endpoint: GroupEndpoint) => ({
    ...endpoint,
    key: endpoint.id,
  }));
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      endpoints: updatedEndpoints,
    },
    resolver: joiResolver(schema()),
  });
  const endPointsFieldArray = useFieldArray({
    control,
    name: 'endpoints',
  });
  const { audio_way_options, audio, columns, confirmations, name, title } = useMemo(
    () => translations(),
    [],
  );
  const columnsSelected: Array<Column> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: columns.name.header,
        Cell: ({ row: { original } }: EndpointItemType) => (
          <TableCellTruncatedValue
            icon={getEndpointIcon(original.endpoint_type)}
            text={original.name}
            truncateAfter={40}
          />
        ),
      },
      {
        accessor: 'id',
        Header: '',
        Cell: ({ row: { index } }: { row: { index: number } }) => (
          <Icon
            hasHover
            name="minus-circle-outlined"
            size={22}
            themeColor="core.states.error.main"
            onClick={endPointsFieldArray.remove.bind(null, index)}
          />
        ),
      },
    ],
    [nameFormatter],
  );

  const submitHandler = (formData: Data) => {
    const mappedEndpoints: GroupEndpoint[] = remapEndpoints(formData);
    const nodeData: PageGroupNodeData = {
      metadata: {
        name: formData.name,
        pvt_type: 'ring_group',
      },
      data: {
        ...formData,
        endpoints: mappedEndpoints,
      },
    };
    onSave(nodeData, isDirty);
  };

  const addItemToForm = (item: EndpointItemType, endpointType: EndpointType) =>
    endPointsFieldArray.append({
      ...endpointDefaultValues,
      endpoint_type: endpointType,
      key: item.id,
      name: nameFormatter(endpointType, item),
    });

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={title}
      type={DialogType.XLarge}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <div role="row" className="split split-input-fields">
        <div role="cell">
          <HookFormInputWrapper name="name" control={control}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                isLabelAbove
                label={name.label}
                inputProps={{
                  ...formProps,
                  id: 'input-page-group-name',
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormSelectWrapper name="audio" control={control} options={audio_way_options}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                isLabelAbove
                label={audio.label}
                selectProps={{
                  ...formProps,
                  id: 'select-page-group-audio',
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <TableSelector
        hasActionRow
        columnsSelected={columnsSelected}
        confirmations={confirmations}
        id="page-group"
        selectionFieldArray={endPointsFieldArray}
        addItemHandler={addItemToForm}
      />
    </CallflowActionsDialog>
  );
};

export default PageGroupDialog;
