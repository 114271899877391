import { FunctionComponent } from 'react';
import InputLabel from 'shared/components/InputLabel';
import InputMessage from 'shared/components/InputMessage';
import Switch from 'shared/components/Switch';
import * as utility from 'shared/utility';
import DirtyIcon from '../DirtyIcon';
import InfoIcon from '../InfoIcon';
import defaultProps from './default';
import { LabeledSwitchProps as Props } from './definition';
import StyledLabeledSwitch from './style';

const LabeledSwitch: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    id,
    hasDirtyIcon,
    hasMargin,
    hasSmallMargin, // TODO: Replace with marginSize
    isBelow,
    isSwitchLeft,
    isDirty,
    indentWidth,
    label,
    labelProps,
    labelWidth,
    switchProps,
    feedback,
    tooltip,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const forId = id || utility.generateId('switch');

  const inputLabel: JSX.Element = (
    <InputLabel htmlFor={forId} {...labelProps} width={labelWidth}>
      {label}
    </InputLabel>
  );

  return (
    <StyledLabeledSwitch
      hasMargin={hasMargin}
      hasSmallMargin={hasSmallMargin}
      isBelow={isBelow}
      isSwitchLeft={isSwitchLeft}
      indentWidth={indentWidth}
      labelWidth={labelWidth}
    >
      {!isSwitchLeft && inputLabel}
      <div>
        <div>
          <Switch id={forId} {...switchProps} />
          {isSwitchLeft && inputLabel}
          {!!tooltip && <InfoIcon isInline tooltip={tooltip} />}
          {hasDirtyIcon && <DirtyIcon isDirty={isDirty} isInline />}
        </div>
        {feedback && <InputMessage type={feedback.type} message={feedback.message} />}
      </div>
    </StyledLabeledSwitch>
  );
};

export default LabeledSwitch;
