import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NumbersInUsePicker from 'shared/components/NumbersInUsePicker';
import { useToast } from 'shared/hooks/useToast';
import { FormNumbersInUsePickerProps as Props } from './definition';

const FormNumbersInUsePicker = ({ field, shouldDirty = true, toastMessage }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { setValue } = useFormContext();

  const onApply = (selectedNumber: any) => {
    setValue(field, selectedNumber.id, { shouldDirty });
    showToast.success(toastMessage ?? t('common:component.numbers_in_use_picker.toast.success'));
  };

  return <NumbersInUsePicker onApply={onApply} />;
};

export default FormNumbersInUsePicker;
