import { CallRestrictionOptionType } from 'definition';
import i18next from 'i18next';

export const getClassifierFormName = ({
  hasAction,
  name,
  root,
}: {
  hasAction?: boolean;
  name: string;
  root: string;
}): string => `${root}.${name}${hasAction ? '.action' : ''}`;

export const options = ({ hasInherit }: { hasInherit?: boolean } = {}) => [
  {
    label: i18next.t('common:component.call_restrictions_section.options.inherit'),
    value: hasInherit ? CallRestrictionOptionType.Inherit : CallRestrictionOptionType.Allow,
  },
  {
    label: i18next.t('common:component.call_restrictions_section.options.deny'),
    value: CallRestrictionOptionType.Deny,
  },
];
