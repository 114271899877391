import { ADD_KEY, CHARACTER, FORMAT } from 'constant';
import { format } from 'date-fns';
import { useFetchTimeOfDayQuery } from 'models/TimeOfDay';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import Loading from 'shared/components/Loading';
import Table from 'shared/components/Table';
import { gregorianToDate } from 'shared/utility/date';
import { buildCustomEnabled, buildOnEveryColumn, buildTimeRange } from './utility';

const COLUMN_WIDTH = 220 as const;

const List = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useFetchTimeOfDayQuery();

  const columns: any = useMemo(
    () => [
      {
        Header: t('phone_system:containers.time_of_day.table.column.name'),
        accessor: 'name',
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.repeats'),
        accessor: (row: any) => t(`phone_system:containers.time_of_day.cycle.${row.cycle}`),
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.every'),
        accessor: (row: Row) => buildOnEveryColumn(row)?.every || CHARACTER.EMDASH,
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.on'),
        accessor: (row: Row) => buildOnEveryColumn(row)?.on || CHARACTER.EMDASH,
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.start_date'),
        accessor: (row: any) => format(gregorianToDate(row.start_date), FORMAT.START_DATE),
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.time_range'),
        accessor: (row: any) =>
          buildTimeRange({
            start: row.time_window_start,
            stop: row.time_window_stop,
          }),
        width: COLUMN_WIDTH,
      },
      {
        Header: t('phone_system:containers.time_of_day.table.column.enabled.header'),
        accessor: (row: any) => buildCustomEnabled(row.enabled),
        width: COLUMN_WIDTH,
      },
    ],
    [t],
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table
      title={t('phone_system:containers.time_of_day.table.title')}
      columns={columns}
      data={data ?? []}
      addButton={{
        hasAdd: true,
        label: t(
          'phone_system:containers.time_of_day.table.action_row.button.add_time_of_day.label',
        ),
        onAdd: () => navigate(`./${ADD_KEY}`),
      }}
      hasSearch
    />
  );
};

export default List;
