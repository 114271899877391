import theme from '../core';

export const CONSTANTS = {
  HEIGHT: 104,
};

export default {
  styleOverrides: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(5.33, 4.66),
      borderRadius: theme.spacing(0, 0, 1, 1),
      '&.MuiDialogActions-filter': {
        padding: 0,
      },
      '> div': {
        '&:first-of-type': {
          '> button:not(:last-of-type)': {
            marginRight: theme.spacing(2),
          },
        },
        '&:last-of-type': {
          '> button:not(:first-of-type)': {
            marginLeft: theme.spacing(2),
          },
        },
      },
    },
  },
};
