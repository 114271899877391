import { FetchArgs } from '@reduxjs/toolkit/dist/query';

export const injectUiMetadataToArgs = (args: string | FetchArgs) => {
  if (Object.prototype.hasOwnProperty.call(args, 'body')) {
    const requestPayloadBody = (args as FetchArgs).body;
    if (Object.prototype.hasOwnProperty.call(requestPayloadBody, 'data')) {
      requestPayloadBody.data.ui_metadata = {
        ui: 'nemo',
      };
    }
  }
};
