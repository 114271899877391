import { JOI } from 'constant';
import i18next from 'i18next';
import Joi from 'joi';
import { SelectOption } from 'shared/components/Select';
import { messages } from 'shared/utility/validation';

export const NOTIFICATION_TYPE_ID: string[] = [
  'voicemail_to_email',
  'fax_inbound_error_to_email',
  'deregister',
];

export const getOptions = (): { [key: string]: SelectOption[] } => ({
  DEREGISTER_TO_TYPE: [
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify.option.admins',
      ),
      value: 'admins',
    },
    {
      label: i18next.t(
        'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify.option.specified',
      ),
      value: 'specified',
    },
  ],
});

export const initSchema = (): { [key: string]: any } => ({
  _temp_notifications: Joi.object({
    voicemail_to_email: Joi.object({
      from: Joi.string().required().email(JOI.EMAIL),
    }).unknown(true),
    fax_inbound_error_to_email: Joi.object({
      //use_voicemail_to_email_from exists on the form but is not validated
      from: Joi.when(Joi.ref('use_voicemail_to_email_from'), {
        is: Joi.boolean().valid(false),
        then: Joi.string().required().email(JOI.EMAIL),
        otherwise: Joi.optional(),
      }),
    }).unknown(true),
    deregister: Joi.object({
      //enabled exists on the form but is not validated
      from: Joi.when(Joi.ref('enabled'), {
        is: Joi.boolean().valid(true),
        then: Joi.string().required().email(JOI.EMAIL),
        otherwise: Joi.optional(),
      }),
      //enabled exists on the form but is not validated
      subject: Joi.when(Joi.ref('enabled'), {
        is: Joi.boolean().valid(true),
        then: Joi.string().required(),
        otherwise: Joi.optional(),
      }),
      to: Joi.object({
        //enabled exists on the form but is not validated
        email_addresses: Joi.when(Joi.ref('...enabled'), {
          is: Joi.boolean().valid(true),
          //type exists on the form but is not validated
          then: Joi.when(Joi.ref('type'), {
            is: 'specified',
            then: Joi.array()
              .items(Joi.string().required().email(JOI.EMAIL))
              .required()
              .label(
                i18next.t(
                  'accounts_manager:containers.accounts.section.notifications.field.emails_to_notify_email_addresses.label',
                ),
              ),
            otherwise: Joi.optional(),
          }),
          otherwise: Joi.optional(),
        }),
      }).unknown(true),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages()),
});
