import { useFetchAccountQuery } from 'models/Account';
import { useCallback, useMemo } from 'react';
import { SelectOption } from 'shared/components/Select';

export const useAccountLocations = () => {
  const {
    data: { locations },
  } = useFetchAccountQuery();

  const locationOptions = useMemo(
    () =>
      locations
        ? Object.entries(locations)
            .map(([id, { display_name }]: any) => ({
              label: display_name,
              value: id,
            }))
            .sort((a: SelectOption, b: SelectOption) => a.label?.localeCompare(b.label))
        : [],
    [locations],
  );

  const getLocationLabelById = useCallback((id: string) => locations[id]?.display_name ?? '', [
    locations,
  ]);

  return { locationOptions, getLocationLabelById };
};
