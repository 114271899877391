import { FunctionComponent } from 'react';
import { DialogActions, DialogType } from 'shared/components/Dialog';
import Loading from 'shared/components/Loading';
import defaultProps from '../../default';
import { CallFlowActionsDialogProps as Props } from '../../definition';
import StyledCallflowActionsDialog from '../../style';

const CallflowActionsDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { children, isLoading, title, handleClose, type } = { ...defaultProps, ...props };

  return (
    <StyledCallflowActionsDialog
      open
      renderActions={<DialogActions onAction={handleClose} />}
      title={title}
      type={type as DialogType}
      onClose={handleClose}
    >
      {isLoading ? <Loading /> : children}
    </StyledCallflowActionsDialog>
  );
};

export default CallflowActionsDialog;
