import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType } from 'apps/PhoneSystem/definition';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { getEndpointIcon, nameFormatter } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import { FunctionComponent, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DialogType } from 'shared/components/Dialog';
import Icon from 'shared/components/Icon';
import { LabeledTextarea } from 'shared/components/Labeled';
import { Column } from 'shared/components/Table';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallNotificationDialogProps as Props, CallNotificationNodeData, Data } from './definition';
import schema from './schema';
import translations from './translations';
import { mapEmailsToForm, mapEmailsToNode, mapUsersToForm, mapUsersToNode } from './utility';

const CallNotificationDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<Data>({
    mode: 'onSubmit',
    defaultValues: {
      ...defaultValues,
      users: mapUsersToForm(data?.recipients),
      emails: mapEmailsToForm(data?.recipients),
    },
    resolver: joiResolver(schema()),
  });
  const usersFieldArray = useFieldArray({
    control,
    name: 'users',
  });
  const { columns, content, emails, title } = useMemo(() => translations(), []);
  const columnsSelected: Array<Column> = useMemo(
    () => [
      {
        accessor: 'name',
        Header: columns.selected.header,
        Cell: ({ row: { original } }: EndpointItemType) => (
          <TableCellTruncatedValue
            icon={getEndpointIcon(original.endpoint_type)}
            text={original.name}
            truncateAfter={40}
          />
        ),
      },
      {
        accessor: 'id',
        Header: '',
        Cell: ({ row: { index } }: { row: { index: number } }) => (
          <Icon
            hasHover
            name="minus-circle-outlined"
            size={22}
            themeColor="core.states.error.main"
            onClick={usersFieldArray.remove.bind(null, index)}
          />
        ),
      },
    ],
    [nameFormatter],
  );

  const submitHandler = (formData: Data) => {
    const { users = [], emails = '' } = formData;
    const usersMapped = mapUsersToNode(users);
    const emailsMapped = mapEmailsToNode(emails);
    const nodeData: CallNotificationNodeData = {
      data: {
        nodeId: data?.nodeId,
        recipients: [...usersMapped, ...emailsMapped],
      },
    };
    onSave(nodeData, isDirty);
  };

  const addItemToForm = (item: EndpointItemType, endpointType: EndpointType) =>
    usersFieldArray.append({
      endpoint_type: endpointType,
      key: item.id,
      name: nameFormatter(endpointType, item),
    });

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={title}
      type={DialogType.XLarge}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>{content['0']}</p>
      <HookFormInputWrapper name="emails" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledTextarea
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={emails.label}
            inputProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormInputWrapper>
      <TableSelector
        hasActionRow
        columnsSelected={columnsSelected}
        id="call-notification"
        included={[EndpointType.User]}
        labels={{ user: columns.unselected.header }}
        selectionFieldArray={usersFieldArray}
        addItemHandler={addItemToForm}
      />
    </CallflowActionsDialog>
  );
};

export default CallNotificationDialog;
