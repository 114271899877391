import { FunctionComponent } from 'react';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import defaultProps from './default';
import { InfoIconProps as Props } from './definition';
import StyledInfoIcon from './style';

const InfoIcon: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { className, isInline, isOutlined, isSmall, tooltip }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <StyledInfoIcon isInline={isInline}>
      <Tooltip className={className} title={tooltip}>
        <Icon name={`info-${isOutlined ? 'outlined' : 'filled'}`} size={isSmall ? 14 : 19} />
      </Tooltip>
    </StyledInfoIcon>
  );
};

export default InfoIcon;
