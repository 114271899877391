/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import { metaPhoneSystem, metaSipTrunking } from 'apps/meta';
import { FormFields } from 'apps/PhoneSystem/containers/Account/Settings/components/CellularEnablementSection/definition';
import { meta as metaCallflows } from 'apps/PhoneSystem/containers/Callflows';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { TableCellCheckmarkIcon } from 'apps/shared/components/TableCell';
import { CHARACTER } from 'constant';
import get from 'lodash/get';
import { useFetchAccountQuery } from 'models/Account';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { useFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import Table, { Justify } from 'shared/components/Table';
import { compareStrings } from 'shared/utility';
import { PhoneNumberUsedBy } from './definition';
import StyledErrorSpan from './style';

const List: FunctionComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: phoneNumbersData, isLoading: isPhoneNumbersLoading } = useFetchPhoneNumbersQuery();
  const { data: callflowsData, isLoading: isCallflowsLoading } = useFetchCallflowsQuery();
  const { data: accountData } = useFetchAccountQuery();

  const bridgeNumber = useMemo(() => accountData?.[FormFields.BridgeNumber], [accountData]);

  const { enabled, notApplicable, missingAddress, callflowUnavailable, smsBox, sipTrunk } = useMemo(
    () => ({
      enabled: t('common:enabled') as string,
      notApplicable: t('common:not_applicable') as string,
      missingAddress: t('common:missing_address') as string,
      callflowUnavailable: t('common:callflow_unavailable') as string,
      smsBox: t('common:sms_box') as string,
      sipTrunk: t('common:sip_trunk') as string,
    }),
    [],
  );

  const callflowsName = useMemo(() => {
    if (!callflowsData) {
      return {};
    }
    return callflowsData.reduce((accumulator: Record<string, string>, callflow) => {
      accumulator[callflow.id] = callflow.name;
      return accumulator;
    }, {});
  }, [callflowsData]);

  const getUsedByValue = (row: any, returnAsString?: boolean): string | JSX.Element | null => {
    const usedBy = row.original.used_by;
    const callflowId = row.original.used_by_callflow_id;
    const callflowName = callflowsName[callflowId];

    if (usedBy === PhoneNumberUsedBy.SmsBox) {
      return smsBox;
    }
    if (usedBy === PhoneNumberUsedBy.SipTrunk) {
      return returnAsString ? (
        sipTrunk
      ) : (
        <RouterLink to={`/apps/${metaSipTrunking.slug}`}>{sipTrunk}</RouterLink>
      );
    }
    if (usedBy === PhoneNumberUsedBy.Callflow) {
      if (!callflowName) {
        return callflowUnavailable;
      }
      return returnAsString ? (
        callflowName
      ) : (
        <RouterLink to={`/apps/${metaPhoneSystem.slug}/${metaCallflows.slug}/${callflowId}`}>
          {callflowName}
        </RouterLink>
      );
    }

    return null;
  };

  const columns = useMemo(
    () => [
      {
        Header: t('numbers:containers.numbers_in_use.table.column.number'),
        accessor: 'id',
        width: 300,
      },
      {
        Header: t('numbers:containers.numbers_in_use.table.column.service_address'),
        Cell: (data: any) => {
          const address = get(data, 'row.original.e911.street_address', missingAddress);
          return (
            <>
              {address === missingAddress ? <StyledErrorSpan>{address}</StyledErrorSpan> : address}
            </>
          );
        },
        accessor: 'e911.street_address',
        width: 300,
      },
      {
        Header: t('numbers:containers.numbers_in_use.table.column.outbound'),
        Cell: ({ row }: any) => row.original.cnam?.display_name ?? CHARACTER.EMDASH,
        accessor: 'cnam.display_name',
        width: 200,
      },
      {
        Header: t('numbers:containers.numbers_in_use.table.column.inbound'),
        Cell: ({ row }: any) => (
          <TableCellCheckmarkIcon isVisible={row.original.cnam?.inbound_lookup} />
        ),
        accessor: (row: any) => (row.cnam?.inbound_lookup ? 0 : 1),
        id: `numbers.numbers_in_use.inbound.${Justify.Center}`,
        width: 200,
      },
      {
        Header: t('numbers:containers.numbers_in_use.table.column.prepend'),
        Cell: ({ row }: any) => row.original.prepend?.name ?? CHARACTER.EMDASH,
        accessor: 'prepend.name',
        width: 200,
      },
      {
        Header: t('numbers:containers.numbers_in_use.table.column.used_by'),
        Cell: (data: any) => getUsedByValue(data?.row),
        accessor: 'used_by',
        sortType: (rowA: any, rowB: any) =>
          compareStrings(
            getUsedByValue(rowA, true) as string,
            getUsedByValue(rowB, true) as string,
          ),
        width: 300,
      },
    ],
    [callflowsName],
  );

  const tableData = useMemo(() => {
    const tableDataObject = get(phoneNumbersData, 'usage.assigned', {});
    const result = Object.keys(tableDataObject)
      .map((currentKey: string) => tableDataObject[currentKey])
      .filter(({ id }) => id !== bridgeNumber); // Hide bridge number from the list
    return result;
  }, [phoneNumbersData]);

  if (isPhoneNumbersLoading || isCallflowsLoading) {
    return <Loading hasLargeProgress hasLabel={false} />;
  }

  return (
    <Table
      hasPagination={{ rowsPerPage: true }}
      title={t('numbers:containers.numbers_in_use.table.title')}
      columns={columns}
      data={tableData}
      hasSearch
    />
  );
};

export default List;
