import { joiResolver } from '@hookform/resolvers/joi';
import TimeOfDaySelect from 'apps/PhoneSystem/shared/TimeOfDaySelect';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, TimeOfDayDisableNodeData, TimeOfDayDisableProps as Props } from './definition';
import schema from './schema';

const TimeOfDayDisableDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const nodeData: TimeOfDayDisableNodeData = {
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t(
        'phone_system:containers.callflows.callflow_action_dialog.time_of_day_disable.title',
      )}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <TimeOfDaySelect name="rules" control={control} />
    </CallflowActionsDialog>
  );
};

export default TimeOfDayDisableDialog;
