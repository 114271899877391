import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps as Props } from 'apps/PhoneSystem/definition';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { ADD_KEY } from 'constant';
import {
  schema,
  useCreateTimeOfDayMutation,
  useDeleteTimeOfDayByIdMutation,
  useFetchTimeOfDayByIdQuery,
  useUpdateTimeOfDayByIdMutation,
} from 'models/TimeOfDay';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { fields } from './components';
import { defaultValues } from './default';
import { FormInput } from './definition';
import Form from './Form';
import { initData, saveData } from './utility';

const Edit: FunctionComponent<Props> = (props: Props) => {
  const { id, handleSaveSuccess, handleDeleteSuccess } = props;
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { data, isLoading } = useFetchTimeOfDayByIdQuery({ id }, { skip: id === ADD_KEY });

  const [createTimeOfDay] = useCreateTimeOfDayMutation();
  const [deleteTimeOfDay] = useDeleteTimeOfDayByIdMutation();
  const [updateTimeOfDay] = useUpdateTimeOfDayByIdMutation();

  const formMethods = useForm<FormInput>({
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const {
    formState: { isDirty: isPageDirty },
    handleSubmit,
    reset,
    setError,
  } = formMethods;

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (formData) => {
      try {
        if (id === ADD_KEY) {
          await createTimeOfDay({ body: saveData(formData) });
        } else {
          await updateTimeOfDay({ id, body: saveData(formData) });
        }
        handleSaveSuccess?.({ shouldRedirect: id === ADD_KEY });
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [id, createTimeOfDay, handleSaveSuccess, setError, showErrorMessage, updateTimeOfDay],
  );

  const onDelete = async () => {
    try {
      await deleteTimeOfDay({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  useEffect(() => {
    if (data) {
      const formData = enhancedFormUtility.transformDataToFormData(initData(data), defaultValues);
      enhancedFormUtility.ensureFormData(formData, fields);
      reset(formData);
    }
  }, [data, reset]);

  useEffect(() => enhancedFormUtility.ensureFormDefaultValues(defaultValues, fields), []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <EditForm
      entityLabel={t('phone_system:containers.time_of_day.label')}
      entityName={data?.name}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      onDelete={id !== ADD_KEY ? onDelete : undefined}
      isPageDirty={isPageDirty}
      formMethods={formMethods}
    >
      <Form />
    </EditForm>
  );
};

export default Edit;
