import { INPUT_LABEL_CONSTANTS } from 'shared/components/InputLabel';
import * as utility from 'shared/utility';
import { WidthType } from 'shared/utility/definition';
import * as mixin from 'shared/utility/mixin';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { StyledLabeledCheckboxProps as Props } from './definition';

const margin = (
  { hasMargin, hasSmallMargin, isBelow, isInline, leftMargin, theme }: Props,
  type: string,
): FlattenSimpleInterpolation => {
  const TYPE_MARGIN: Record<string, Record<string, number | Record<string, number>>> = {
    BOTTOM: {
      checkbox: 4.5,
      switch: 6,
    },
    TOP: {
      DEFAULT: {
        checkbox: -1,
        switch: 0,
      },
      IS_BELOW: {
        checkbox: -4.5,
        switch: -3.5,
      },
    },
  };

  const margin = {
    top:
      isBelow || isInline
        ? theme.spacing(
            isBelow ? (TYPE_MARGIN.TOP.IS_BELOW as Record<string, number>)[type] : -0.33,
          )
        : theme.spacing((TYPE_MARGIN.TOP.DEFAULT as Record<string, number>)[type]),
    right: 0,
    bottom:
      hasMargin || hasSmallMargin
        ? theme.spacing(hasSmallMargin ? 2 : TYPE_MARGIN.BOTTOM[type])
        : 0,
    left: leftMargin ? theme.spacing(INPUT_LABEL_CONSTANTS.MARGIN.DEFAULT[leftMargin]) : 0,
  };

  return css`
    margin: ${margin.top} ${margin.right} ${margin.bottom} ${margin.left};
  `;
};

export const getStyle = ({
  isLeft,
  props,
  props: { indentWidth, labelWidth, leftMargin, theme },
  type,
}: {
  isLeft: boolean;
  props: Props;
  type: string;
}): FlattenSimpleInterpolation => css`
  ${mixin.flex({
    alignItems: 'flex-start',
    ...(isLeft ? { direction: 'column' } : {}),
  })};
  ${margin(props, type)};

  ${isLeft
    ? css`
        > div {
          margin: 0 0 0
            ${utility.getWidth({
              type: 'label',
              width: { input: 'none', label: indentWidth as WidthType },
              leftMargin,
              hasMargin: true,
            })};
          transform: ${`translateX(${theme.spacing(0.5)})`};
        }
      `
    : css`
        > label {
          ${mixin.width({ type: 'label', width: labelWidth as WidthType })};
          margin-top: ${theme.spacing(type === 'checkbox' ? 1.66 : 0)};
        }
      `}

  > div > div {
    &:first-of-type {
      ${mixin.flex({ justifyContent: 'flex-start' })};

      > label {
        width: auto;
        margin: ${theme.spacing(0, 0, 0, 2)};
        text-align: left;
      }

      ${type === 'checkbox' &&
      css`
        > span {
          margin: ${theme.spacing(0, -2)};
        }
      `}
    }

    &:last-of-type:not(:first-of-type) {
      margin: ${theme.spacing(-0.66, 0, 0)};

      > div {
        ${mixin.width({ type: 'input', width: 'large' })};
        margin: 0;
      }
    }
  }
`;

const StyledLabeledCheckbox = styled.div<Props>`
  ${(props) => css`
    ${getStyle({ isLeft: props.isCheckboxLeft as boolean, props, type: 'checkbox' })};
  `}
`;

export default StyledLabeledCheckbox;
