import { transformRichTextStateToHtmlString } from 'apps/shared/components/HookForm/RichTextEditorWrapper';
import { ADD_KEY, DESKTOP_APP_DEFAULT_ZONE, REGEX } from 'constant';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { NOTIFICATION_TYPE_ID } from '../components/section/NotificationsSection/utility';
import { OnAccountSubmitSuccessParams } from '../definition';

export const NEW_ADMIN_PREFIX = 'new-';

export const FORM_STATE_NAME = Object.freeze({
  ANNOUNCEMENT: 'announcement',
  NOTES: 'custom_notes',
});

/**
 * These additional (and temporary) account data object properties
 * are used to facilitate value gathering/validation, etc.
 */
export const TEMP_PROPERTY = {
  IS_IN_APPS_STORE: '_temp_is_in_apps_store',
  IS_IN_HERO_APPS: '_temp_is_in_hero_apps',
  ANNOUNCEMENT: '_temp_announcement',
  APPS_STORE: '_temp_apps_store',
  DESKTOP_APP_ZONE: '_temp_desktop_app_zone',
  HERO_APPS: '_temp_hero_apps',
  NOTES: '_temp_notes',
  NOTIFICATIONS: '_temp_notifications',
};

export const TEMP_PROPERTY_TRANSFORMERS: {
  [tempPropertyName: string]: { toFieldName: string; transformer: Function };
} = {
  [TEMP_PROPERTY.NOTES]: {
    toFieldName: FORM_STATE_NAME.NOTES, // TODO: accept nested-level object key naming
    transformer: transformRichTextStateToHtmlString,
  },
  [TEMP_PROPERTY.ANNOUNCEMENT]: {
    toFieldName: FORM_STATE_NAME.ANNOUNCEMENT,
    transformer: transformRichTextStateToHtmlString,
  },
};

export const deleteProperties = (formData: any): any => {
  const data = cloneDeep(formData);
  Object.values(TEMP_PROPERTY).forEach((property: string): void => {
    delete data[property];
  });
  return data;
};

export const filterForAdmins = (data: any) =>
  data?.filter((item: any) => item.priv_level === 'admin'); // TODO: {{baseURL}}/v2/accounts/{{account_id}}/users?filter_priv_level="admin"

export const getStateValue = (isEnabled: any) => {
  const values = Object.values(isEnabled);
  const count = values.filter((isEnabled: any) => isEnabled).length;
  return values.length === count ? 'all' : count === 0 ? 'none' : 'indeterminate';
};

export const getDesktopDefaultAccountZone = (data: any) => {
  const desktopAppZone = data?.[TEMP_PROPERTY.DESKTOP_APP_ZONE];

  if (!desktopAppZone || desktopAppZone === DESKTOP_APP_DEFAULT_ZONE) {
    return null;
  }

  return desktopAppZone;
};

export const updateSection = {
  appExchange: async ({
    id,
    apps,
    data,
    mutation,
  }: {
    id: string;
    apps: Array<string>;
    data: any;
    mutation: any;
  }): Promise<any> => {
    const enabledApps = Object.entries(data[TEMP_PROPERTY.IS_IN_APPS_STORE])
      .map(([appId, isEnabled]: Array<any>) => isEnabled && appId)
      .filter((appId: string) => !!appId);

    const blacklist = apps
      .map(({ id }: any) => id)
      .filter((appId: string) => !enabledApps.includes(appId));

    await mutation({ accountId: id, body: { blacklist } });
  },

  desktopModules: async ({
    id,
    data,
    mutation,
  }: {
    id: string;
    data: any;
    mutation: any;
  }): Promise<any> => {
    await mutation({ id, body: { apps: data[TEMP_PROPERTY.HERO_APPS] } });
  },

  desktopAppZone: async ({
    id,
    data,
    dirtyFields,
    heroConfigData,
    mutation,
  }: {
    id: string;
    data: any;
    dirtyFields: any;
    heroConfigData: any;
    mutation: any;
  }): Promise<any> => {
    if (dirtyFields[TEMP_PROPERTY.DESKTOP_APP_ZONE]) {
      const newBody = heroConfigData ? cloneDeep(heroConfigData) : {};

      newBody.default_zone = getDesktopDefaultAccountZone(data);

      await mutation({
        id,
        body: newBody,
      });
    }
  },

  notifications: async ({
    id,
    data,
    dirtyFields,
    mutation,
  }: {
    id: string;
    data: any;
    dirtyFields: any;
    mutation: any;
  }): Promise<any> => {
    await Promise.all(
      NOTIFICATION_TYPE_ID.map(
        async (typeId: string): Promise<any> => {
          if (dirtyFields[TEMP_PROPERTY.NOTIFICATIONS]?.[typeId]) {
            await mutation({
              accountId: id,
              typeId,
              body: data[TEMP_PROPERTY.NOTIFICATIONS][typeId],
            });
          }
        },
      ),
    );
  },
};

export const useFetch = ({
  accountId,
  parentId,
  data,
  hook,
}: {
  accountId: string;
  parentId: string;
  data: any;
  hook: any;
}) => {
  ({ data: data.account } = hook.useFetchAccountByIdQuery(
    {
      id: accountId,
    },
    { skip: !accountId || accountId === ADD_KEY || data.account },
  ));

  ({ data: data.accountHeroApps } = hook.useFetchAccountHeroAppsQuery(
    {
      id: accountId === ADD_KEY ? parentId : accountId,
    },
    { skip: !accountId || data.accountHeroApps },
  ));

  ({ data: data.heroConfig } = hook.useFetchAccountDesktopAppZoneQuery(
    {
      id: accountId,
    },
    { skip: !accountId || data.heroConfig },
  ));

  ({ data: data.appsStoreAccount } = hook.useFetchAppsQuery(
    { accountId: accountId === ADD_KEY ? parentId : accountId },
    { skip: !accountId || data.appsStoreAccount },
  ));

  ({ data: data.appsStoreCurrentAccount } = hook.useFetchAppsQuery(
    {},
    { skip: !accountId || data.appsStoreCurrentAccount },
  ));

  // REMARK: Keep here until further investigate
  // !data['user'] && ({ data: data['user'], isSuccess: isSuccess['user'] } = hook.useFetchUsersQuery({ accountId: id }));

  data.notification = {};

  ({ data: data.notification.voicemail_to_email } = hook.useFetchNotificationByIdQuery(
    {
      accountId,
      typeId: 'voicemail_to_email',
    },
    {
      skip: !accountId || accountId === ADD_KEY || data.notification.voicemail_to_email,
    },
  ));

  ({ data: data.notification.fax_inbound_error_to_email } = hook.useFetchNotificationByIdQuery(
    {
      accountId,
      typeId: 'fax_inbound_error_to_email',
    },
    { skip: !accountId || accountId === ADD_KEY || data.notification.fax_inbound_error_to_email },
  ));

  ({ data: data.notification.deregister } = hook.useFetchNotificationByIdQuery(
    {
      accountId: accountId === ADD_KEY ? parentId : accountId,
      typeId: 'deregister',
    },
    { skip: data.notification.deregister },
  ));
};

/**
 * Transform form data to fit in API schema
 */
export const transformAccountData = (accountData: any) =>
  produce(accountData, (draft: any) => {
    Object.entries(TEMP_PROPERTY_TRANSFORMERS).forEach(
      ([tempPropertyName, { toFieldName, transformer }]) => {
        const data = draft[tempPropertyName];
        draft[toFieldName] = transformer(data);
      },
    );

    if (!draft.language || draft.language === 'auto') {
      delete draft.language;
    }
  });

/**
 * Transform api account data to form state
 */
export const transformAccountDataToFormState = (accountData: any) =>
  produce(accountData, (draft: any) => {
    if (draft) {
      if (!draft.language) {
        draft.language = 'auto';
      }
    }
  });

/**
 * @name normalizeNameSegment
 * @description Prepare text to be used to autofill field value:
 *              • Normalize unicode characters
 *              • Remove problematic unicode characters
 *              • Remove non-alphanumeric characters
 *              • Convert value to lowercase
 *
 * @param [name='']
 *
 * @returns Normalized string segment
 */
export const normalizeNameSegment = (name = '') =>
  name
    .normalize('NFD')
    .replace(REGEX.ACCOUNT.REALM.SEGMENT.REPLACE_CHARS.UNICODE, '')
    .replace(REGEX.ACCOUNT.REALM.SEGMENT.REPLACE_CHARS.STANDARD, '')
    .toLowerCase();

export const onAccountSubmitSuccess = ({
  handleSaveSuccess,
  isAdd,
}: OnAccountSubmitSuccessParams) => {
  handleSaveSuccess({ hasToast: !isAdd, shouldRedirect: isAdd });
};
