import merge from 'lodash/merge';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import { ApplyButton, ResetButton } from './components';
import defaultProps from './default';
import { FilterDialogProps as Props } from './definition';

const FilterDialog = (props: Props) => {
  const {
    filter: {
      hasFilterValues,
      isApplyDisabled,
      isDialogOpen,
      dialogContent,
      onApply,
      onClose,
      onReset,
    },
  } = merge({}, defaultProps, props);

  return (
    <Dialog
      hideBackdrop
      open={isDialogOpen}
      renderActions={
        <DialogActions
          dialogType={DialogType.Filter}
          rightButtons={
            <>
              <ResetButton isVisible={hasFilterValues.selected} onClick={onReset} />
              <ApplyButton isDisabled={isApplyDisabled} onClick={onApply} />
            </>
          }
        />
      }
      title="Filter"
      type={DialogType.Filter}
      onClose={onClose}
    >
      {dialogContent}
    </Dialog>
  );
};

export default FilterDialog;
