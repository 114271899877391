import { joiResolver } from '@hookform/resolvers/joi';
import Edit from 'apps/PhoneSystem/containers/Directories/Edit';
import DirectorySelect from 'apps/PhoneSystem/shared/DirectorySelect';
import { ADD_KEY } from 'constant';
import { directoryQueries } from 'models/Directory/Queries';
import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../utility';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, DirectoryCallflowDialogProps as Props, DirectoryNodeData } from './definition';
import schema from './schema';

const DirectoryDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [editId, setEditId] = useState<string>(ADD_KEY);
  const { control, getValues, watch, handleSubmit, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const [itemName, setItemName] = useState<string>();

  const submitHandler = async (formData: Data) => {
    const { id } = formData;
    let name = '';
    if (id != '') {
      const fetchByIdResponse: any = await dispatch(
        directoryQueries.endpoints.fetchDirectoryById.initiate({ id }),
      );
      name = fetchByIdResponse.data.name;
    }
    const nodeData: DirectoryNodeData = {
      metadata: {
        name,
        pvt_type: 'directory',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const selectedId = watch('id');
    if (selectedId && selectedId !== '') {
      setShowEditButton(true);
      setEditId(selectedId);
    }
  }, [watch('id')]);

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.directory.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <div role="row" className="split split-with-link">
        <div role="cell">
          <DirectorySelect
            name="id"
            control={control}
            hasDirtyIcon
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.directory.add.label',
            )}
            {...(itemName ? { itemName } : {})}
          />
        </div>
        <div role="cell">
          {showEditButton && (
            <DialogLink
              onClick={() => {
                setIsEditDialogOpen(true);
                setEditId(getValues('id'));
              }}
            />
          )}
        </div>
      </div>

      <div role="row">
        <div role="cell">
          <DialogLink
            type="add"
            isBelow
            text={t(
              'phone_system:containers.callflows.callflow_action_dialog.directory.add.add_new_directory',
            )}
            onClick={() => {
              setIsEditDialogOpen(true);
              setEditId(ADD_KEY);
            }}
          />
        </div>
      </div>
      <Dialog
        open={isEditDialogOpen}
        title={
          editId === ADD_KEY
            ? t(
                'phone_system:containers.callflows.callflow_action_dialog.directory.add.create_directory',
              )
            : t(
                'phone_system:containers.callflows.callflow_action_dialog.directory.edit.edit_directory',
              )
        }
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          id={editId}
          handleCancel={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          setItemName={setItemName}
        />
      </Dialog>
    </CallflowActionsDialog>
  );
};

export default DirectoryDialog;
