import { PhoneNumberUsedBy } from 'apps/Numbers/containers/NumbersInUse/List/definition';
import { TableCellStreetAddress } from 'apps/shared/components/TableCell';
import i18next from 'i18next';
import { Column } from 'react-table';
import { compareStrings } from 'shared/utility';

/**
 * @name getFilteredPhoneNumbers
 * @description Filters out the bridge number from the provided phone numbers data
 *
 * @param phoneNumbersData - The data containing phone numbers
 * @param bridgeNumber - The account's bridge number
 *
 * @returns - A filtered array of phone number objects excluding the bridge number
 */
export const getFilteredPhoneNumbers = (phoneNumbersData: any, bridgeNumber: string) => {
  const tableDataObject = phoneNumbersData?.usage?.assigned ?? {};

  return Object.keys(tableDataObject)
    .map((currentKey) => tableDataObject[currentKey])
    .filter(({ id }) => id !== bridgeNumber);
};

/**
 * @name getUsedByValue
 * @description Returns a string based on the `used_by` value of a phone number
 *
 * @param row - The row object containing phone number data
 * @param callflowsData - Callflows data
 *
 * @returns A descriptive string for the `used_by` value
 */
export const getUsedByValue = (row: any, callflowsData: any[]): string | null => {
  const { used_by: usedBy, used_by_callflow_id: callflowId } = row.original;

  switch (usedBy) {
    case PhoneNumberUsedBy.SmsBox:
      return i18next.t('common:sms_box');
    case PhoneNumberUsedBy.SipTrunk:
      return i18next.t('common:sip_trunk');
    case PhoneNumberUsedBy.Callflow:
      return (
        callflowsData.find(({ id }) => id === callflowId)?.name ??
        i18next.t('common:callflow_unavailable')
      );
    default:
      return null;
  }
};

/**
 * @name columns
 * @description Get the list of columns for the agents table
 *
 * @param callflowsData - Callflows data
 *
 * @returns List of columns
 */
export const columns = (callflowsData: any): Column[] => [
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.number',
    ) as string,
    accessor: 'id',
    width: 300,
  },
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.used_by',
    ) as string,
    Cell: (data) => getUsedByValue(data?.row, callflowsData),
    accessor: 'used_by',
    sortType: (rowA: any, rowB: any) =>
      compareStrings(
        getUsedByValue(rowA, callflowsData) as string,
        getUsedByValue(rowB, callflowsData) as string,
      ),
    width: 300,
  },
  {
    Header: i18next.t(
      'common:component.numbers_in_use_picker.components.numbers_in_use_dialog.table.column.service_address',
    ) as string,
    Cell: ({ row }: { row: { original: { e911: { street_address?: string } } } }) => (
      <TableCellStreetAddress streetAddress={row.original.e911?.street_address} />
    ),
    accessor: 'e911.street_address',
    width: 300,
  },
];
