import { CallflowContextProvider } from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowContext';
import { updateZoom } from 'apps/PhoneSystem/shared/utility';
import { ZoomMode } from 'apps/PhoneSystem/shared/utility/definition';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import { ADD_KEY } from 'constant';
import { RootState } from 'definition';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import {
  useCreateCallflowMutation,
  useDeleteCallflowMutation,
  useForceFetchCallflowByIdQuery,
  useUpdateCallflowMutation,
} from 'models/Callflow';
import { newCallFlow } from 'models/Callflow/store/default';
import { Callflow, CallFlowTree } from 'models/Callflow/store/definition';
import { addCallFlow, createAddCallFlow, removeAddCallFlow } from 'models/Callflow/store/slice';
import { prepareCallflowForSaving } from 'models/Callflow/store/utility';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Dialog, { DialogType } from 'shared/components/Dialog';
import DropdownButton from 'shared/components/DropdownButton';
import Loading from 'shared/components/Loading';
import { useActionRow } from 'shared/hooks/useActionRow';
import { useToast } from 'shared/hooks/useToast';
import { AlertContext, DialogContext } from 'store/contexts';
import HelpDialog from '../components/HelpDialog';
import { HelpDialogType } from '../components/HelpDialog/definition';
import CallflowActionDialogRoot from './components/CallflowActionsDialog';
import CallflowDrawer from './components/CallflowDrawer';
import DragAndDropContainer from './components/DragAndDropContainer';
import InteractionBar, {
  InteractionBarElementType,
  InteractionBarType,
} from './components/InteractionBar';
import SelectKeyDialog from './components/SelectKeyDialog';
import { EditProps as Props } from './definition';
import StyledEdit from './style';
import { getSaveAndDupHandler, historyAction } from './utility';

const Edit = ({ id, handleSaveSuccess, handleDeleteSuccess, handleCancelSuccess }: Props) => {
  const isAdd = id === ADD_KEY;
  const { t } = useTranslation();
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alertCtx = useContext(AlertContext);
  const dialogCtx = useContext(DialogContext);

  const { data, isLoading } = useForceFetchCallflowByIdQuery({ id }, { skip: isAdd });
  const [updateCallflow] = useUpdateCallflowMutation();
  const [deleteCallflow] = useDeleteCallflowMutation();
  const [createCallflow] = useCreateCallflowMutation();

  const callflowPast: any[] = useSelector((state: RootState) => state.callflows.past);
  const callflowFuture: any[] = useSelector((state: RootState) => state.callflows.future);
  const callflow: Callflow = useSelector(
    (state: RootState) => state.callflows.present.entities[id],
  );

  const name = get(data, 'name', t('phone_system:containers.seats.callflow.label'));
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [, setZoom] = useState<number>(1);
  const refZoom = useRef<HTMLDivElement>(null);
  const navigateToAdd = () => navigate(`../${ADD_KEY}`, { replace: true });
  const hasUndo = callflowPast.length > 0;
  const hasRedo = callflowFuture.length > 0;
  const history = historyAction(dispatch);

  useExitConfirmationDialog({ isDirty: callflow?.isDirty, pathWhitelist: [ADD_KEY] });

  const createDuplicateCallflow = (newCallflow?: Partial<Callflow>): Callflow => {
    const dupCallflow = cloneDeep(callflow);
    dupCallflow.id = ADD_KEY;
    dupCallflow.numbers = [];
    dupCallflow.isDirty = true;
    dupCallflow.name = t('phone_system:containers.callflows.duplicate_callflow_name', {
      name: newCallflow?.name || callflow.name,
    });
    return dupCallflow;
  };

  const duplicateAndNavigate = (newCallflow?: Partial<Callflow>) => {
    const dupCallflow = createDuplicateCallflow(newCallflow);
    navigateToAdd();
    history.clear();
    dispatch(createAddCallFlow(dupCallflow));
  };

  const handleBreadcrumbClick = () => {
    navigate('..');
  };

  const handleCancel = async () => {
    handleCancelSuccess?.();
  };

  const handleDelete = async () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.callflows.callflow_actions.delete'),
      confirmMessage: t('phone_system:containers.shared.edit_form.delete.text', { name }),
      onConfirm: async () => {
        await alertCtx
          .call(
            deleteCallflow,
            { id },
            {
              success: {
                message: t('phone_system:containers.callflows.callflow_actions.delete_confirmed'),
              },
              error: true,
            },
          )
          .then(() => handleDeleteSuccess?.({ hasToast: false }));
      },
    });
  };

  const handleSave = useCallback(
    async ({ shouldRedirect = isAdd } = {}) => {
      try {
        const newCallFlow: Partial<CallFlowTree> = prepareCallflowForSaving(callflow);

        if (isAdd) {
          delete newCallFlow.id;
          delete newCallFlow.hasName;

          await alertCtx.call(createCallflow, { body: newCallFlow });
          dispatch(removeAddCallFlow(ADD_KEY));
        } else {
          const isCallflowNameUntouched = newCallFlow.name === data.name;

          // Don't remove the name field if there's already a name even if it's untouched.
          if (isCallflowNameUntouched && !data.hasName) {
            delete newCallFlow.name;
          }
          delete newCallFlow.hasName;
          await alertCtx.call(updateCallflow, { id, body: newCallFlow });
        }

        // always clear undo/redo history
        history.clear();

        handleSaveSuccess?.({ shouldRedirect });
        return { saveResult: 'success', newCallFlow };
      } catch (exception) {
        showToast.error();
        return { saveResult: 'error' };
      }
    },
    [
      isAdd,
      id,
      alertCtx,
      callflow,
      data,
      history,
      showToast,
      createCallflow,
      dispatch,
      handleSaveSuccess,
      updateCallflow,
    ],
  );

  const handleSaveAndCreateNew = async () => {
    const saveResult = await handleSave();

    if (saveResult.saveResult === 'success') {
      showToast.success(t('phone_system:containers.callflows.success.new_callflow_created'));
      navigateToAdd();
    }
  };

  const handleInteractionBarAction = {
    [InteractionBarElementType.UNDO]: () => {
      history.undo(id, callflowPast);
    },
    [InteractionBarElementType.REDO]: () => {
      history.redo(id, callflowFuture);
    },
    [InteractionBarElementType.DUPLICATE]: () => {
      if (!isAdd) {
        duplicateAndNavigate();
        showToast.success(t('phone_system:containers.callflows.callflow_actions.duplicate'));
      }
    },
    [InteractionBarElementType.ZOOM_IN]: () => {
      setZoom((zoom: number) => updateZoom(ZoomMode.IN, refZoom, zoom));
    },
    [InteractionBarElementType.ZOOM_OUT]: () => {
      setZoom((zoom: number) => updateZoom(ZoomMode.OUT, refZoom, zoom));
    },
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: !isAdd,
    hasHelp: true,
    hasSave: true,
    isDirty: Boolean(callflow?.isDirty),
    breadcrumbData: [
      {
        text: t('phone_system:containers.callflows.label'),
        url: handleBreadcrumbClick,
      },
      {
        text: callflow?.name,
      },
    ],
    help: {
      dialogComponent: (
        <Dialog open={isHelpOpen} type={DialogType.Help} onClose={() => setIsHelpOpen(false)}>
          <HelpDialog type={HelpDialogType.Edit} />
        </Dialog>
      ),
      iconTooltip: t('phone_system:containers.callflows.help'),
      setState: setIsHelpOpen,
    },
    saveButton: (
      <DropdownButton
        isDisabled={!callflow?.isDirty}
        label={t('common:save')}
        items={[
          {
            icon: 'document-text',
            label: t('phone_system:containers.callflows.buttons.save_changes'),
            onClick: handleSave,
          },
          {
            icon: 'file-copy-outlined',
            label: t('phone_system:containers.callflows.buttons.save_and_duplicate'),
            onClick: getSaveAndDupHandler(handleSave, duplicateAndNavigate, showToast, t),
          },
          {
            icon: 'add-document',
            label: t('phone_system:containers.callflows.buttons.save_and_create'),
            onClick: handleSaveAndCreateNew,
          },
        ]}
        icon=""
        buttonProps={{
          color: 'success',
          variant: 'contained',
        }}
      />
    ),
    onDelete: handleDelete,
    onCancel: handleCancel,
    onSave: handleSave,
  });

  useEffect(() => {
    return () => {
      dispatch(removeAddCallFlow(id));
    };
  }, [dispatch, id]);

  // Clear undo/redo history on component unmount
  useEffect(() => {
    return () => {
      history.clear();
    };
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(addCallFlow(data));
    }
  }, [data, dispatch]);

  /**
   * • Create new callflow with "add" key if one does not exist
   * • If saving, delete "add" callflow key and insert newly created callflow id
   * • If cancelling, deleting, or navigating away from the page, delete "add" callflow key
   */
  useEffect(() => {
    if (isAdd && !callflow) {
      (async () => {
        await dispatch(createAddCallFlow(newCallFlow));
      })();
    }
  }, [id, callflow, dispatch, isAdd]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledEdit>
      <ActionRow {...actionRowProps} />
      <CallflowActionDialogRoot />
      <SelectKeyDialog />
      <CallflowContextProvider callflow={callflow}>
        <section>
          <InteractionBar
            hasDuplicate={!isAdd}
            hasUndo={hasUndo}
            hasRedo={hasRedo}
            type={InteractionBarType.DEFAULT}
            onAction={{
              [InteractionBarElementType.UNDO]:
                handleInteractionBarAction[InteractionBarElementType.UNDO],
              [InteractionBarElementType.REDO]:
                handleInteractionBarAction[InteractionBarElementType.REDO],
              [InteractionBarElementType.DUPLICATE]:
                handleInteractionBarAction[InteractionBarElementType.DUPLICATE],
              [InteractionBarElementType.ZOOM_IN]:
                handleInteractionBarAction[InteractionBarElementType.ZOOM_IN],
              [InteractionBarElementType.ZOOM_OUT]:
                handleInteractionBarAction[InteractionBarElementType.ZOOM_OUT],
            }}
          />
          <CallflowDrawer />
          <DragAndDropContainer refZoom={refZoom} />
        </section>
      </CallflowContextProvider>
    </StyledEdit>
  );
};

export default Edit;
