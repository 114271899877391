import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

export const StyledHelpDialogContent = styled.div`
  ${(props) => css`
    margin: 0 0 15px;
    text-align: center;

    h1 {
      ${mixin.font({ size: 24, weight: 500 })};
      margin: 18px 0 6px;
    }

    h2 {
      ${mixin.font({ size: 17, weight: 600 })};
    }

    h3 {
      ${mixin.font({ size: 18, weight: 400 })};
    }

    > h3 {
      margin: 0 0 18px;
    }

    > div {
      ${mixin.flex({ alignItems: 'stretch' })};
      margin: 18px 0 0;
      user-select: none;

      > div {
        width: calc(50% - 6px);
        padding: 12px 12px 24px;
        background-color: ${props.theme.core.background.grey.medium};

        &:last-of-type {
          margin: 0 0 0 12px;
        }

        .intro {
          margin: 0 0 36px;

          span {
            ${mixin.boxShadow({ blurRadius: 8 })};
            ${mixin.font({ size: 26, weight: 700 })};
            display: inline-block;
            --size: 60px;
            width: var(--size);
            height: var(--size);
            margin: 12px 0 24px;
            line-height: var(--size);
            background-color: ${props.theme.whitelabel.primary.color};
            border-radius: 50%;
            color: ${props.theme.core.color.white};
          }

          h3 {
            width: 380px;
            margin: 0 auto;
          }
        }

        .content {
          position: relative;

          &.content-1 {
            margin: 0 0 36px;
          }

          h2 {
            ${mixin.font({ size: 14, weight: 400 })};
            margin: 0 0 12px;
          }

          button {
            box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.25) !important;
          }

          .cursor {
            position: absolute;

            &.cursor-0 {
              top: 78px;
              right: 105px;
            }

            &.cursor-1 {
              top: 60px;
              left: 250px;
            }

            &.cursor-2 {
              top: 96px;
              right: 14px;
            }
          }

          .widget {
            padding: 0 12px;
            background-color: ${props.theme.core.color.white};

            > div {
              padding: 12px 0;
              ${mixin.flex({ justifyContent: 'flex-start' })};

              span {
                ${mixin.font({ size: 14 })};
                text-align: left;
              }

              &:first-of-type > span {
                text-transform: uppercase;
                font-weight: 600;
              }
            }

            &.widget-0 {
              padding: 18px 12px;

              > div {
                justify-content: center;

                &:first-of-type {
                  > span:first-of-type {
                    margin: 0 6px 0 0;
                  }
                }

                &:last-of-type {
                  > span {
                    margin: 0 4px 0 0;

                    &:nth-of-type(2) {
                      margin: 0 36px 0 0;
                    }
                  }
                }
              }
            }

            &.widget-1 > div {
              &:first-of-type {
                border-bottom: 1px solid ${props.theme.core.border.color};
              }

              &:last-of-type {
                padding: 6px 0;
              }

              > span {
                --size-icon: 40px;
                flex-basis: calc((100% - var(--size-icon) * 2) / 3);
                flex-grow: 0;
                flex-shrink: 0;

                &:first-of-type,
                &:last-of-type {
                  flex-basis: var(--size-icon);
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  `}
`;

export default StyledHelpDialogContent;
