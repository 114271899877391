import isEmpty from 'lodash/isEmpty';
import FilterSelect from 'shared/components/FilterSelect';
import Typography from 'shared/components/Typography';
import { FilterCallflowTypeSelectProps as Props } from './definition';

const FilterDialogContent = ({
  hasMultiSelect,
  filter,
  heading,
  options,
  setFilterValues,
}: Props) => (
  <>
    <Typography
      classes={{ root: isEmpty(filter.values.selected[filter.type]) ? 'MuiTypography-error' : '' }}
      variant="h2"
    >
      {heading}
    </Typography>
    <FilterSelect
      hasMultiSelect={hasMultiSelect}
      filter={filter}
      options={options}
      setFilterValues={setFilterValues}
    />
  </>
);

export default FilterDialogContent;
