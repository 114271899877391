import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import { LabeledSwitch } from 'shared/components/Labeled';
import { FormFields } from '../../definition';

const EnableSsoSwitch = () => {
  const { t } = useTranslation();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const { setValue } = useFormContext();

  const onClose = () => {
    setIsConfirmDialogOpen(false);
  };

  const onSwitchOffSso = () => {
    setValue(FormFields.SsoEnabled, false, { shouldDirty: true });
    onClose();
  };

  const handleDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        onSwitchOffSso();
        break;
      default:
        break;
    }
  };

  const openConfirmDialog = () => {
    setIsConfirmDialogOpen(true);
  };

  return (
    <>
      <HookFormInputWrapper name={FormFields.SsoEnabled} isCheckbox>
        {({ ref, isDirty, onChange, ...formProps }) => (
          <LabeledSwitch
            isDirty={isDirty}
            labelWidth="auto"
            label={t(
              'phone_system:containers.account.submodule.settings.section.sso.enable_sso.label',
            )}
            switchProps={{
              ...formProps,
              onChange: (e) => {
                const isChecked = e.target.checked;
                if (!isChecked && !isDirty) {
                  openConfirmDialog();
                } else {
                  onChange(isChecked);
                }
              },
            }}
          />
        )}
      </HookFormInputWrapper>
      <Dialog
        open={isConfirmDialogOpen}
        renderActions={
          <DialogActions
            cancelLabel={t('common:cancel')}
            saveButtonProps={{
              color: 'error',
              label: t('common:disable'),
              variant: 'contained',
            }}
            onAction={handleDialogAction}
          />
        }
        title={t('common:confirmation')}
        type={DialogType.Large}
        onClose={onClose}
      >
        <p>
          {t(
            'phone_system:containers.account.submodule.settings.section.sso.enable_sso.confirm_dialog.intro',
          )}
        </p>
        <p>
          <Trans i18nKey="phone_system:containers.account.submodule.settings.section.sso.enable_sso.confirm_dialog.description">
            If you disable Single Sign-On,{' '}
            <strong>
              you are no longer requiring users to authenticate for access to your organization.
            </strong>{' '}
            All associated seats will not be able to log in with their registered email address and
            password. Users will be required to sign in with their account name, extension and PIN.
          </Trans>
        </p>
      </Dialog>
    </>
  );
};

export default EnableSsoSwitch;
