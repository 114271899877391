import { CHARACTER, FORMAT, SECONDS_IN_DAY } from 'constant';
import { addSeconds, format, startOfDay } from 'date-fns';
import i18next from 'i18next';
import first from 'lodash/first';

/**
 * Building the time range given a start and end time in seconds.
 * If the time range represents the entire day, 'All Day' will be returned.
 * Otherwise the start time and stop times will be converted to 12 hour format including am or pm,
 * with minutes included if the time does not land on the hour.
 *
 * @param {Object} seconds - Seconds object
 * @property {number} start - defined start time of the rule given in seconds from the start of the day.
 * @property {number} stop - defined stop time of the rule given in seconds from the start of the day.
 *
 * @return {string} - a human readable representation of the time range given.
 */

export const buildTimeRange = (seconds: { [key: string]: number }): string => {
  if (seconds.start === 0 && seconds.stop === SECONDS_IN_DAY) {
    return i18next.t('phone_system:containers.time_of_day.table.column.time_ranges.all_day');
  }

  const getTime = (seconds: number): string =>
    format(addSeconds(startOfDay(new Date()), seconds), FORMAT.TIME.HOUR_MINUTES);

  return `${getTime(seconds.start)} — ${getTime(seconds.stop)}`;
};

/**
 * Building a simple enabled string custom for the time of day solution
 *
 * @param {?boolean} value - a boolean if forced or undefined if the rule is based on time.
 *
 * @return {string} - a string representing the enabled state of the time of day rule
 */
export const buildCustomEnabled = (value?: boolean): string => {
  if (typeof value === 'undefined') {
    return i18next.t('phone_system:containers.time_of_day.enabled.based_on_time'); // 'Based on Time'
  }
  return value
    ? i18next.t('phone_system:containers.time_of_day.enabled.forced_on') // 'Forced On'
    : i18next.t('phone_system:containers.time_of_day.enabled.forced_off'); // 'Forced Off'
};

/**
 * Build the table columns on and every. These columns use a large amount of the same rule
 * properties, therefore are built together.
 *
 * @param {Object} rule - Time of day rule
 * @property {string[]} rule.wdays - Array of strings representing the days of the week 'monday' -> 'sunday'
 * @property {number[]} rule.days - Array of numbers representing the day of the month [1, 5]
 * @property {string} rule.cycle - String representing how often this rule is active; valid values are ('weekly', 'monthly', 'yearly')
 * @property {number} rule.interval - How many cycles until the rule is active again.
 * @property {string} rule.ordinal - The ordinality of the rule.
 * @property {number} rule.month - A number representing the month of the year, 1 -> 12
 * @property {number[]} rule.range - Array of numbers representing the day range [1, 5]
 *
 * @return {Object}
 * @property {string} every - How often this rule is active.
 * @property {string} on - The times at which this rule is active.
 */
export const buildOnEveryColumn = (rule: any): any => {
  if (rule.cycle === 'weekly') {
    return {
      every: `${rule.interval} ${
        rule.interval === 1
          ? i18next.t('phone_system:containers.time_of_day.week')
          : i18next.t('phone_system:containers.time_of_day.weeks')
      }`,
      on: rule.wdays.length
        ? rule.wdays.map((day: number, i: number): string => {
            const shortDay = i18next.t(`phone_system:containers.time_of_day.wdays.${day}.short`);
            return i === rule.wdays.length - 1 ? shortDay : `${shortDay}, `;
          })
        : CHARACTER.EMDASH,
    };
  }

  if (rule.cycle === 'yearly' || rule.cycle === 'monthly') {
    const day = i18next.t(`phone_system:containers.time_of_day.wdays.${first(rule.wdays)}.long`);
    const ordinal = i18next.t(`phone_system:containers.time_of_day.ordinal.${rule.ordinal}`);
    let every: string = CHARACTER.EMDASH;
    let on = `${ordinal} ${day}`;

    if (rule.month) {
      every = i18next.t(`phone_system:containers.time_of_day.months_numerical.${rule.month}`);
    }

    if (rule.cycle === 'monthly') {
      every = `${rule.interval} ${
        rule.interval === 1
          ? i18next.t('phone_system:containers.time_of_day.month')
          : i18next.t('phone_system:containers.time_of_day.months')
      }`;
    }

    if (rule.days) {
      const FORMAT_PATTERN = 'do';
      const hasLast = rule.days.length > 1;
      const formatted = {
        first: format(new Date(2023, 0, rule.days[0]), FORMAT_PATTERN),
        last: hasLast
          ? format(new Date(2023, 0, rule.days[rule.days.length - 1]), FORMAT_PATTERN)
          : '',
      };

      on = hasLast ? `${formatted.first} ${CHARACTER.EMDASH} ${formatted.last}` : formatted.first;
    }

    return { every, on };
  }
};
