import { joiResolver } from '@hookform/resolvers/joi';
import QueueSelect from 'apps/PhoneSystem/shared/QueueSelect';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchQueueQuery } from 'models/Queues';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, WaitTimeDialogProps as Props, WaitTimeNodeData } from './definition';
import schema from './schema';
import translations from './translations';
import { convertWindowToSeconds, extractWindowValueAndUnit } from './utility';

const WaitTimeDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { data: queueData, isLoading } = useFetchQueueQuery();
  const [
    window = defaultValues.window,
    window_unit = defaultValues.window_unit,
  ] = extractWindowValueAndUnit(data?.window);
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
    reset,
  } = useForm<Data>({
    mode: 'onChange',
    defaultValues,
    resolver: joiResolver(schema()),
  });
  const {
    dialog,
    queue,
    route_to_another_queue,
    title,
    var_input,
    window_input,
    window_units,
  } = useMemo(() => translations(), []);
  const watchShowRouteToAnotherQueue = watch('route_to_another_queue');

  const submitHandler = (formData: Data) => {
    const { window, window_unit, ...rest } = formData;
    const selected = queueData?.find(({ id }) => id === formData.id);
    const windowSeconds = convertWindowToSeconds(Number(window), window_unit).toString();
    const nodeData: WaitTimeNodeData = {
      metadata: {
        name: selected!.name,
        pvt_type: 'queue',
      },
      data: {
        ...rest,
        window: windowSeconds,
        var: formData.route_to_another_queue ? rest.var : '',
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, window, window_unit, route_to_another_queue: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset, window, window_unit]);

  return (
    <CallflowActionsDialog
      isLoading={isLoading}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <p>{dialog.content_0}</p>

      <QueueSelect name="id" control={control} isLabelAbove label={queue.label} />

      <div role="row" className="split">
        <div role="cell">
          <HookFormInputWrapper name="window" control={control}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                isLabelAbove
                feedback={feedback}
                label={window_input.label}
                inputWidth="small"
                inputProps={{
                  type: 'number',
                  ...formProps,
                  id: 'input-wait-time-window',
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          <HookFormSelectWrapper control={control} name="window_unit" options={window_units}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                isLabelAbove
                label=""
                selectWidth="small"
                selectProps={{ ...formProps, id: 'select-window-unit' }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      <HookFormInputWrapper name="route_to_another_queue" control={control} isCheckbox>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            isDirty={isDirty}
            feedback={feedback}
            label={route_to_another_queue.label}
            indentWidth="small"
            checkboxProps={{
              ...formProps,
              id: 'checkbox-wait-time-add-route',
            }}
          />
        )}
      </HookFormInputWrapper>

      {watchShowRouteToAnotherQueue && (
        <HookFormInputWrapper name="var" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              feedback={feedback}
              isLabelAbove
              label={var_input.label}
              inputWidth="small"
              inputProps={{
                ...formProps,
                id: 'input-wait-time-add-var',
              }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default WaitTimeDialog;
