import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { BaseSyntheticEvent, FunctionComponent, useRef } from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';
import { LabeledCheckbox, LabeledPicker, LabeledSelect } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { OptionTypeBase } from 'shared/components/Select/definition';
import { dateToSeconds } from 'shared/utility/date';
import { MarginType } from 'shared/utility/definition';
import { FormInput } from '../../definition';
import { convertWdays, getOptions } from '../../utility';
import Duration from './components/Duration';
import LabeledDaysOfWeek from './components/LabeledDaysOfWeek';
import translations from './translations';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'cycle',
  'days',
  'enabled',
  'end_date',
  'has_no_end_date',
  'interval',
  'is_all_day_event',
  'month',
  'name',
  'ordinal',
  'range_start',
  'range_stop',
  'start_date',
  'time_window_start',
  'time_window_stop',
  'wdays',
];

const OptionsSection: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { components, fields } = translations();
  const { control, getValues, resetField, setValue, watch } = useFormContext();
  const { cycle, duration, has_no_end_date, is_all_day_event, ordinal, start_date } = watch();
  const refPrevious = {
    time: useRef<Record<string, Date>>(),
    endDate: useRef<Record<string, Date>>(),
  };

  /**
   * Toggling "All Day Event" does the following:
   * • Saves the current start/stop times or if necessary, reverts them
   * • Disables/enables both start/stop time pickers
   */
  const handleAllDayEventClick = (e: BaseSyntheticEvent) => {
    if (e.target.checked) {
      refPrevious.time.current = {
        start: getValues('time_window_start'),
        stop: getValues('time_window_stop'),
      };
      setValue('time_window_start', new Date().setHours(0, 0, 0, 0));
      setValue('time_window_stop', new Date().setHours(23, 59, 59, 0));
    } else if (refPrevious.time.current) {
      setValue('time_window_start', refPrevious.time.current.start);
      setValue('time_window_stop', refPrevious.time.current.stop);
    } else {
      setValue('time_window_start', '');
      setValue('time_window_stop', '');
      setValue('duration', { start: 0, stop: 0 });
    }
  };

  /**
   * Toggling "No End Date" does the following:
   * • Saves the current end date or if necessary, reverts it
   * • Disables/enables end date picker
   */
  const handleEndDateClick = (e: BaseSyntheticEvent) => {
    if (e.target.checked) {
      refPrevious.endDate.current = getValues('end_date');
      setValue('end_date', '');
    } else if (refPrevious.endDate.current) {
      setValue('end_date', refPrevious.endDate.current);
    } else {
      setValue('end_date', '');
    }
  };

  return (
    <>
      <div role="row" className="underline">
        <div role="cell">
          {/* Name */}
          <HookFormInputWrapper name="name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isDirty={isDirty}
                feedback={feedback}
                label={fields.name.label}
                inputProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="start_date">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                type="date"
                isDirty={isDirty}
                feedback={feedback}
                label={fields.start_date.label}
                datePickerProps={{
                  ...formProps,
                  onChange: (value) => onChange(value ?? ''),
                  renderInput: () => <></>,
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row" className="one-column">
        <div role="cell">
          {/* Start Time */}
          <HookFormInputWrapper name="time_window_start">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                type="time"
                isDirty={isDirty}
                feedback={feedback}
                label={fields.time_window_start.label}
                pickerWidth="medium"
                timePickerProps={{
                  ...formProps,
                  disabled: is_all_day_event,
                  readOnly: is_all_day_event,
                  onAccept: (value) => {
                    if (value) {
                      setValue('duration', {
                        ...getValues('duration'),
                        start: dateToSeconds(value),
                      });
                    }
                  },
                  onChange: (value) => onChange(value ?? ''),
                  renderInput: () => <></>,
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* End Time */}
          <HookFormInputWrapper name="time_window_stop">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledPicker
                type="time"
                isDirty={isDirty}
                feedback={feedback}
                label={fields.time_window_stop.label}
                labelProps={{ leftMargin: MarginType.xmedium }}
                labelWidth="auto"
                pickerWidth="medium"
                timePickerProps={{
                  ...formProps,
                  disabled: is_all_day_event,
                  readOnly: is_all_day_event,
                  onAccept: (value) => {
                    if (value) {
                      setValue('duration', {
                        ...getValues('duration'),
                        stop: dateToSeconds(value),
                      });
                    }
                  },
                  onChange: (value) => onChange(value ?? ''),
                  renderInput: () => <></>,
                }}
              />
            )}
          </HookFormInputWrapper>

          {/* All Day Event? */}
          <HookFormInputWrapper name="is_all_day_event" isCheckbox>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledCheckbox
                isInline
                isDirty={isDirty}
                feedback={feedback}
                indentWidth="auto"
                label={fields.is_all_day_event.label}
                labelProps={{ leftMargin: MarginType.xmedium }}
                checkboxProps={{
                  ...formProps,
                  onClick: (e: BaseSyntheticEvent): void => handleAllDayEventClick(e),
                }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row" className="underline">
        <div role="cell">
          <Duration isAllDayEvent={is_all_day_event} time={duration} />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Repeats */}
          <HookFormSelectWrapper name="cycle" options={getOptions('cycle')}>
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={fields.cycle.label}
                selectProps={{
                  ...formProps,
                  onChange: (option: OptionTypeBase) => {
                    onChange(option);
                    resetField('wdays', { defaultValue: convertWdays(getValues, option) });
                  },
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>

      {cycle !== 'date' && (
        <>
          <div role="row">
            <div role="cell">
              {/* Every */}
              {cycle === 'yearly' ? (
                <HookFormSelectWrapper name="month" options={getOptions('month')}>
                  {({ ref, isDirty, feedback, ...formProps }) => (
                    <LabeledSelect
                      isDirty={isDirty}
                      feedback={feedback}
                      label={fields.interval.label}
                      selectProps={{
                        ...formProps,
                      }}
                    />
                  )}
                </HookFormSelectWrapper>
              ) : (
                <HookFormInputWrapper name="interval">
                  {({ ref, isDirty, feedback, ...formProps }) => (
                    <LabeledInput
                      isDirty={isDirty}
                      feedback={feedback}
                      label={fields.interval.label}
                      inputProps={{
                        ...formProps,
                        adornment: {
                          type: 'text',
                          position: 'end',
                          value: fields.interval.suffix[cycle === 'monthly' ? 'months' : 'weeks'],
                        },
                      }}
                    />
                  )}
                </HookFormInputWrapper>
              )}
            </div>
          </div>

          <div role="row" className="one-column">
            <div role="cell">
              {/* On */}
              {cycle === 'weekly' ? (
                <Controller
                  control={control}
                  name="wdays"
                  render={({
                    field: { value, onChange },
                    fieldState: { isDirty },
                  }: {
                    field: Partial<ControllerRenderProps>;
                    fieldState: ControllerFieldState;
                  }) => (
                    <LabeledDaysOfWeek
                      isDirty={isDirty}
                      label={components.days_of_week.label}
                      tooltip={components.days_of_week.tooltip}
                      daysOfWeekProps={{
                        data: value || [],
                        onChange: (value: Array<string>) => onChange?.(value),
                      }}
                    />
                  )}
                />
              ) : (
                <>
                  <HookFormSelectWrapper name="ordinal" options={getOptions('ordinal')}>
                    {({ ref, isDirty, feedback, ...formProps }) => (
                      <LabeledSelect
                        isDirty={isDirty}
                        feedback={feedback}
                        label={fields.ordinal.label}
                        selectProps={{
                          ...formProps,
                        }}
                      />
                    )}
                  </HookFormSelectWrapper>

                  {!['every', 'range'].includes(ordinal) && (
                    <HookFormSelectWrapper name="wdays" options={getOptions('wdays')}>
                      {({ ref, isDirty, feedback, ...formProps }) => (
                        <LabeledSelect
                          isDirty={isDirty}
                          feedback={feedback}
                          labelProps={{ leftMargin: MarginType.small }}
                          labelWidth="none"
                          selectProps={{
                            ...formProps,
                          }}
                          selectWidth="small"
                        />
                      )}
                    </HookFormSelectWrapper>
                  )}

                  {ordinal === 'every' && (
                    <HookFormSelectWrapper name="days" options={getOptions('days')}>
                      {({ ref, isDirty, feedback, onChange, ...formProps }) => (
                        <LabeledSelect
                          isDirty={isDirty}
                          feedback={feedback}
                          labelProps={{ leftMargin: MarginType.small }}
                          labelWidth="none"
                          selectProps={{
                            ...formProps,
                            onChange: (option: OptionTypeBase) => {
                              onChange([option]);
                            },
                          }}
                          selectWidth="small"
                        />
                      )}
                    </HookFormSelectWrapper>
                  )}

                  {ordinal === 'range' && (
                    <>
                      <HookFormSelectWrapper name="range_start" options={getOptions('days')}>
                        {({ ref, isDirty, feedback, onChange, ...formProps }) => (
                          <LabeledSelect
                            isDirty={isDirty}
                            feedback={feedback}
                            labelProps={{ leftMargin: MarginType.small }}
                            labelWidth="none"
                            selectProps={{
                              ...formProps,
                              onChange: (option: OptionTypeBase) => {
                                onChange(option);
                                if (option.value > getValues('range_stop')) {
                                  setValue('range_stop', option.value);
                                }
                              },
                            }}
                            selectWidth="small"
                          />
                        )}
                      </HookFormSelectWrapper>

                      <HookFormSelectWrapper
                        name="range_stop"
                        options={getOptions('days', watch('range_start'))}
                      >
                        {({ ref, isDirty, feedback, ...formProps }) => (
                          <LabeledSelect
                            isDirty={isDirty}
                            feedback={feedback}
                            labelProps={{ leftMargin: MarginType.small }}
                            labelWidth="none"
                            selectProps={{
                              ...formProps,
                            }}
                            selectWidth="small"
                          />
                        )}
                      </HookFormSelectWrapper>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div role="row" className="one-column">
            <div role="cell">
              {/* End Date */}
              <HookFormInputWrapper name="end_date">
                {({ ref, isDirty, feedback, onChange, ...formProps }) => {
                  const disabled = !start_date || has_no_end_date;
                  return (
                    <LabeledPicker
                      type="date"
                      isDirty={isDirty}
                      feedback={feedback}
                      label={fields.end_date.label}
                      pickerWidth="medium"
                      datePickerProps={{
                        ...formProps,
                        disabled,
                        readOnly: disabled,
                        onChange: (value) => onChange(value ?? ''),
                        renderInput: () => <></>,
                      }}
                    />
                  );
                }}
              </HookFormInputWrapper>

              {/* No End Date? */}
              <HookFormInputWrapper name="has_no_end_date" isCheckbox>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledCheckbox
                    isInline
                    isDirty={isDirty}
                    feedback={feedback}
                    indentWidth="auto"
                    label={fields.has_no_end_date.label}
                    labelProps={{ leftMargin: MarginType.medium }}
                    checkboxProps={{
                      ...formProps,
                      disabled: !start_date,
                      onClick: (e: BaseSyntheticEvent) => handleEndDateClick(e),
                    }}
                  />
                )}
              </HookFormInputWrapper>
            </div>
          </div>
        </>
      )}

      <div role="row" className="overline">
        <div role="cell">
          {/* Enabled */}
          <HookFormSelectWrapper name="enabled" options={getOptions('enabled')}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                feedback={feedback}
                label={fields.enabled.label}
                selectProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>
    </>
  );
};

export default OptionsSection;
