import StyledEditForm from 'apps/shared/components/StyledEditForm';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { BaseSyntheticEvent, FunctionComponent, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActionRow } from 'shared/hooks/useActionRow';
import { DialogContext } from 'store/contexts';
import { EditFormProps as Props } from './definition';

const EditForm: FunctionComponent<Props> = ({
  isDeleteDisabled,
  isPageDirty,
  AuxButton,
  children,
  deleteConfirm,
  deleteLabel,
  deleteTooltip,
  entityLabel,
  entityName = '',
  formMethods,
  navBlockPathWhitelist,
  onCancel,
  onDelete,
  onSave,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dialogCtx = useContext(DialogContext);
  const [saveHit, setSaveHit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const isMounted = useIsMounted();

  useExitConfirmationDialog({ isDirty: isPageDirty, pathWhitelist: navBlockPathWhitelist });

  const handleCancelClick = onCancel ?? (() => navigate('..'));

  const onConfirmDelete = () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.shared.edit_form.delete.title', { entity: entityLabel }),
      confirmMessage:
        deleteConfirm ||
        t('phone_system:containers.shared.edit_form.delete.text', {
          name: entityName,
        }),
      onConfirm: () => onDelete && onDelete(),
    });
  };

  const onSaveHandler = async () => {
    setIsSaving(true);
    setSaveHit(true);
    try {
      await onSave?.();
    } finally {
      if (isMounted()) {
        setIsSaving(false);
      }
    }
  };

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: !!onDelete,
    hasSave: true,
    isDeleteDisabled,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: entityLabel,
        url: handleCancelClick,
      },
      {
        text: entityName,
      },
    ],
    deleteLabel,
    deleteTooltip,
    leftButtons: AuxButton,
    onCancel: handleCancelClick,
    onDelete: onConfirmDelete,
    onSave: onSaveHandler,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()}>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
