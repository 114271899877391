import UseAccountButton from 'apps/AccountsManager/containers/Accounts/List/components/UseAccountButton';
import StyledEditForm from 'apps/shared/components/StyledEditForm';
import useIsMounted from 'apps/shared/hooks/useIsMounted';
import { ExitConfirmationDialogContext } from 'apps/store/contexts';
import { BaseSyntheticEvent, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useActionRow } from 'shared/hooks/useActionRow';
import { EditFormProps as Props } from './definition';

const EditForm = ({
  isAdd,
  isPageDirty,
  children,
  entityLabel,
  entityName = '',
  formMethods,
  onSave,
  onCancel,
  ...rest
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { createExitConfirmationDialogHandler } = useContext(ExitConfirmationDialogContext);
  const { getValues } = formMethods;
  const accountId = getValues('id');

  const onSaveHandler = async () => {
    setIsSaving(true);
    await onSave?.();
    if (isMounted()) {
      setIsSaving(false);
    }
  };

  const onCancelClick = onCancel
    ? createExitConfirmationDialogHandler?.(onCancel)
    : () => navigate('..');

  const { ActionRow, actionRowProps } = useActionRow({
    hasDelete: false,
    hasSave: true,
    isDirty: isPageDirty,
    isSaveDisabled: isSaving,
    breadcrumbData: [
      {
        text: entityLabel,
        url: onCancelClick,
      },
      {
        text: entityName,
      },
    ],
    leftButtons: (
      <UseAccountButton
        account={{
          id: accountId,
          name: entityName,
        }}
        buttonProps={{
          size: 'large',
          variant: 'outlined',
          disabled: !accountId || isPageDirty || isAdd,
        }}
        navigateTo=".."
        tooltip={
          isPageDirty || isAdd ? t('accounts_manager:component.use_account_button.tooltip') : ''
        }
      />
    ),
    onCancel: onCancelClick,
    onSave: onSaveHandler,
  });

  return (
    <StyledEditForm onSubmit={(e: BaseSyntheticEvent) => e.preventDefault()} {...rest}>
      <ActionRow {...actionRowProps} />
      <FormProvider {...formMethods}>{children}</FormProvider>
    </StyledEditForm>
  );
};

export default EditForm;
