import FormNumbersInUsePicker from 'apps/shared/components/FormNumbersInUsePicker';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'caller_id.emergency.name',
  'caller_id.emergency.number',
  'caller_id.external.name',
  'caller_id.external.number',
  'caller_id.internal.name',
  'caller_id.internal.number',
  'enabled',
  'presence_id',
  'should_create_caller_id',
];

const CallerIdSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="presence_id">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.presence')}
                inputProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>

          <HookFormInputWrapper name="should_create_caller_id" isCheckbox>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                label={t('phone_system:containers.seats.section.caller_id.should_create_caller_id')}
                indentWidth="large"
                checkboxProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.caller_id.in_house_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.caller_id.outbound_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.caller_id.emergency_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
          <FormNumbersInUsePicker field="seat.caller_id.emergency.number" />
        </div>
      </div>
    </>
  );
};

export default CallerIdSection;
