import { LOCAL_STORAGE } from 'constant';
import useAccount from 'shared/hooks/useAccount';
import { setAttrs } from './utility';

const useSetNewRelicAttrs = () => {
  const { accountId, accountName } = useAccount();

  const setNewRelicAttrs = () =>
    setAttrs({
      accountId,
      accountName,
      userId: localStorage.getItem(LOCAL_STORAGE.AUTH.USER_ID) as string,
    });

  return { setNewRelicAttrs };
};

export default useSetNewRelicAttrs;
