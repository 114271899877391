const defaultProps = {};

export default defaultProps;

export const defaultValues = {
  name: '',
  mailbox: '',
  pin: '',
  media_extension: 'mp3',
  after_forward_action: 'prompt',
  play_envelope_action: 'play_after',
  vm_message_forward_type: 'only_forward',

  announcement_only: false,
  delete_after_notify: false,
  hunt: false,
  hunt_allow: '',
  hunt_deny: '',
  include_message_on_notify: true,
  is_setup: false,
  operator_number: '',
  oldest_message_first: false,
  require_pin: true,
  skip_envvelope: false,
  skip_greeting: false,
  skip_instructions: false,
  timezone: undefined,
  transcribe: false,
  check_if_owner: true,
  not_configurable: false,

  hasTranscibe: true,

  media: {
    unavailable: undefined,
    temporary_unavailable: undefined,
  },

  numbers: '',
  notify_email_addresses: [],
  shared_vmbox: false,
};
