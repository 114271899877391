import { useFetchClassifiersQuery } from 'models/PhoneNumber';
import {
  CallRestrictionsSection,
  getClassifierFormName,
} from './components/CallRestrictionsSection';
import { UseCallRestrictionsSection } from './definition';

export const useCallRestrictionsSection = ({
  hasCallbacksEnabled,
  hasClosedGroups,
  fields: origFields,
}: UseCallRestrictionsSection) => {
  const { data = {} } = useFetchClassifiersQuery();

  const component = (
    <CallRestrictionsSection
      hasCallbacksEnabled={hasCallbacksEnabled}
      hasClosedGroups={hasClosedGroups}
      classifiers={data}
      fields={{
        classifiers: origFields.Classifiers,
        closedGroups: origFields.ClosedGroups,
        enabled: origFields.Enabled,
      }}
    />
  );

  const fields = [
    ...Object.entries(origFields)
      .filter(([key]) => key !== 'Classifiers')
      .map(([, value]) => value as string),
    ...Object.keys(data).map((name: string) =>
      getClassifierFormName({ hasAction: hasClosedGroups, root: origFields.classifiers, name }),
    ),
  ];

  return { component, fields };
};
