import * as mixin from 'shared/utility/mixin';
import styled, { css } from 'styled-components';

const style = (props: any) => css`
  margin: 0 0 ${props.theme.spacing(2.5)};
  text-align: center;

  h1 {
    ${mixin.font({ size: 24, weight: 500 })};
    margin: ${props.theme.spacing(3)} 0 ${props.theme.spacing(1)};
  }

  h2 {
    ${mixin.font({ size: 17, weight: 600 })};
  }

  h3 {
    ${mixin.font({ size: 15, weight: 400 })};
  }

  > h3 {
    margin: 0 0 ${props.theme.spacing(3)};
  }
`;
export const StyledHelpDialogEdit = styled.div`
  ${(props) => css`
    ${style(props)};

    > div {
      ${mixin.flex({ alignItems: 'stretch', justifyContent: 'space-around' })};
      margin: ${props.theme.spacing(3)} 0 0;
      user-select: none;

      > div {
        width: 33%;
        height: 500px;
        padding: ${props.theme.spacing(2)} ${props.theme.spacing(2)} 24px;
        background-color: ${props.theme.core.background.grey.medium};

        &:not(:first-of-type) {
          margin: 0 0 0 ${props.theme.spacing(1)};
        }

        .intro {
          margin: 0 0 ${props.theme.spacing(6)};

          span {
            ${mixin.boxShadow({ blurRadius: 8 })};
            ${mixin.font({ size: 26, weight: 700 })};
            display: inline-block;
            --size: 60px;
            width: var(--size);
            height: var(--size);
            margin: ${props.theme.spacing(2)} 0 24px;
            line-height: var(--size);
            background-color: ${props.theme.whitelabel.primary.color};
            border-radius: 50%;
            color: ${props.theme.core.color.white};
          }

          h3 {
            margin: ${props.theme.spacing(5)} auto 0;
          }
        }

        .content {
          position: relative;

          &.content-1 {
            margin: 0 0 ${props.theme.spacing(6)};
          }

          h2 {
            ${mixin.font({ size: 14, weight: 400 })};
            margin: 0 0 ${props.theme.spacing(2)};
          }

          button {
            box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.25) !important;
          }

          .svg {
            position: absolute;
            &.callflow-2 {
              width: 170px;
            }

            &.callflow-tree {
              width: 220px;
              right: 40px;
              top: -10px;
            }
            &.actions {
              top: 5px;
              right: -5px;
              width: 125px;
            }
            &.arrow {
              left: 140px;
              top: 68px;
              width: 50px;
              z-index: 1;
            }
            &.callflow-1 {
              left: 15px;
              width: 260px;
            }

            &.cursor-2 {
              top: 96px;
              right: 14px;
            }
          }
        }
      }
    }
  `}
`;

export const StyledHelpDialogList = styled.div`
  ${(props) => css`
    ${style(props)};

    > div {
      ${mixin.flex({ alignItems: 'stretch', justifyContent: 'space-around' })};
      margin: ${props.theme.spacing(3)} 0 0;
      user-select: none;

      > div {
        width: 25%;
        height: 500px;
        padding: ${props.theme.spacing(2)} ${props.theme.spacing(2)} ${props.theme.spacing(4)};
        background-color: ${props.theme.core.background.grey.medium};

        &:not(:first-of-type) {
          margin: 0 0 0 ${props.theme.spacing(1)};
        }

        .intro {
          margin: 0 0 ${props.theme.spacing(6)};

          span {
            ${mixin.boxShadow({ blurRadius: 8 })};
            ${mixin.font({ size: 26, weight: 700 })};
            display: inline-block;
            --size: 60px;
            width: var(--size);
            height: var(--size);
            margin: ${props.theme.spacing(2)} 0 ${props.theme.spacing(4)};
            line-height: var(--size);
            background-color: ${props.theme.whitelabel.primary.color};
            border-radius: 50%;
            color: ${props.theme.core.color.white};
          }

          h3 {
            margin: 0 auto;
            margin-top: ${props.theme.spacing(5)};
          }
        }

        .content {
          position: relative;

          &.content-1 {
            margin: 0 0 ${props.theme.spacing(6)};
          }

          h2 {
            ${mixin.font({ size: 14, weight: 400 })};
            margin: 0 0 ${props.theme.spacing(2)};
          }

          button {
            box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.25) !important;
          }

          .svg {
            position: absolute;

            &.callflow-2 {
              width: 120px;
            }

            &.callflow-tree {
              width: 200px;
              right: 5px;
              top: -10px;
              height: 230px;
            }
            &.actions {
              top: 5px;
              right: -5px;
              width: 90px;
            }
            &.arrow {
              left: 100px;
              top: 45px;
              width: 40px;
              z-index: 1;
            }
            &.callflow-1 {
              left: 15px;
              width: 260px;
            }

            &.cursor {
              top: 30px;
              right: 70px;
            }

            &.callflow {
              left: 5px;
              width: 200px;
            }
          }
        }
      }
    }
  `}
`;
