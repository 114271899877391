import { FunctionComponent } from 'react';
import Labeled from '../..';
import defaultProps from './default';
import { LabeledTextProps as Props } from './definition';
import StyledLabeledText from './style';

const LabeledText: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const {
    hasDirtyIcon,
    hasMargin,
    hasNoWrap,
    hasRightMargin,
    hasSmallMargin,
    isDirty,
    isLabelAbove,
    label,
    labelProps,
    labelWidth,
    text,
    textWidth,
    feedback,
    tooltip,
    tooltipWidth,
    copyToClipboard,
  }: Props = {
    ...defaultProps,
    ...props,
  };

  return (
    <Labeled
      type="text"
      hasDirtyIcon={hasDirtyIcon}
      hasMargin={hasMargin}
      hasNoWrap={hasNoWrap}
      hasRightMargin={hasRightMargin}
      hasSmallMargin={hasSmallMargin}
      isDirty={isDirty}
      isLabelAbove={isLabelAbove}
      label={label}
      labelProps={labelProps}
      labelWidth={labelWidth}
      inputWidth={textWidth}
      feedback={feedback}
      tooltip={tooltip}
      tooltipWidth={tooltipWidth}
      styledComponent={StyledLabeledText}
      copyToClipboard={copyToClipboard}
    >
      {typeof text === 'string' ? <span>{text}</span> : text}
    </Labeled>
  );
};

export default LabeledText;
