import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import { ContainerMeta } from 'apps/shared/definition';
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import Edit from './Edit';
import List from './List';

export const meta: ContainerMeta = {
  // t('phone_system:containers.time_of_day.label')
  label: 'phone_system:containers.time_of_day.label',
  icon: 'calendar',
  slug: 'timeofday',
};

const TimeOfDay = () => {
  const EditWithNav = useNavigation(Edit);
  const routes = useRoutes([
    { path: '/', element: <List /> },
    { path: '/:id', element: <EditWithNav /> },
  ]);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};

export default TimeOfDay;

/**
 * Week Days (long)
 *  Monday: t('phone_system:containers.time_of_day.wdays.monday.long')
 *  Tuesday: t('phone_system:containers.time_of_day.wdays.tuesday.long')
 *  Wednesday: t('phone_system:containers.time_of_day.wdays.wensday.long') // The ever pressent Kazoo bug...
 *  Wednesday: t('phone_system:containers.time_of_day.wdays.wednesday.long')
 *  Thursday: t('phone_system:containers.time_of_day.wdays.thursday.long')
 *  Friday: t('phone_system:containers.time_of_day.wdays.friday.long')
 *  Saturday: t('phone_system:containers.time_of_day.wdays.saturday.long')
 *  Sunday: t('phone_system:containers.time_of_day.wdays.sunday.long')
 *
 * Week Days (short)
 *  M: t('phone_system:containers.time_of_day.wdays.monday.short')
 *  T: t('phone_system:containers.time_of_day.wdays.tuesday.short')
 *  W: t('phone_system:containers.time_of_day.wdays.wednesday.short')
 *  W: t('phone_system:containers.time_of_day.wdays.wensday.short') // The ever pressent Kazoo bug...
 *  TH: t('phone_system:containers.time_of_day.wdays.thursday.short')
 *  F: t('phone_system:containers.time_of_day.wdays.friday.short')
 *  S: t('phone_system:containers.time_of_day.wdays.saturday.short')
 *  S: t('phone_system:containers.time_of_day.wdays.sunday.short')
 *
 * Months List
 *  "January": t('phone_system:containers.time_of_day.months_numerical.1')
 *  "February": t('phone_system:containers.time_of_day.months_numerical.2')
 *  "March": t('phone_system:containers.time_of_day.months_numerical.3')
 *  "April": t('phone_system:containers.time_of_day.months_numerical.4')
 *  "May": t('phone_system:containers.time_of_day.months_numerical.5')
 *  "June": t('phone_system:containers.time_of_day.months_numerical.6')
 *  "July": t('phone_system:containers.time_of_day.months_numerical.7')
 *  "August": t('phone_system:containers.time_of_day.months_numerical.8')
 *  "September": t('phone_system:containers.time_of_day.months_numerical.9')
 *  "October": t('phone_system:containers.time_of_day.months_numerical.10')
 *  "November": t('phone_system:containers.time_of_day.months_numerical.11')
 *  "December": t('phone_system:containers.time_of_day.months_numerical.12')
 *
 * Ordinal List
 *  "First": t('phone_system:containers.time_of_day.ordinal.first')
 *  "Second": t('phone_system:containers.time_of_day.ordinal.second')
 *  "Third": t('phone_system:containers.time_of_day.ordinal.third')
 *  "Fourth": t('phone_system:containers.time_of_day.ordinal.fourth')
 *  "Fifth": t('phone_system:containers.time_of_day.ordinal.fifth')
 *  "Last": t('phone_system:containers.time_of_day.ordinal.last')
 *  "Day": t('phone_system:containers.time_of_day.ordinal.every')
 *  "Day Range": t('phone_system:containers.time_of_day.ordinal.range')
 *
 * Cycle
 *  "Never": t(`phone_system:containers.time_of_day.cycle.date`)
 *  "Weekly": t(`phone_system:containers.time_of_day.cycle.weekly`)
 *  "Yearly": t(`phone_system:containers.time_of_day.cycle.yearly`)
 *  "Monthly": t(`phone_system:containers.time_of_day.cycle.monthly`)
 */
