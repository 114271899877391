import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledRadioGroup } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, MatchCallerIdDialogProps as Props, MatchCallerIdNodeData } from './definition';
import schema from './schema';

const MatchCallerIdDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, watch, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
      use_absolute_mode: data?.use_absolute_mode == true ? 'true' : 'false',
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;
  const watchUseAbsoluteMode = watch('use_absolute_mode');
  const options = [
    {
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.match_caller_id.add.regex_mode',
      ),
      value: 'false',
    },
    {
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.match_caller_id.add.absolute_mode',
      ),
      value: 'true',
    },
  ];

  const submitHandler = (formData: Data) => {
    // todo if mode is changed then what to do with children? Double check this behaviour
    const useAbsoluteMode = formData.use_absolute_mode == true;
    const nodeData: MatchCallerIdNodeData = {
      data: {
        ...data,
        regex: useAbsoluteMode ? defaultValues.regex : formData.regex,
        use_absolute_mode: useAbsoluteMode,
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.match_caller_id.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormRadioGroupWrapper name="use_absolute_mode" options={options} control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            isDirty={isDirty}
            feedback={feedback}
            label={t('common:options')}
            radioGroupProps={{ ...formProps, id: 'radio-absolute-mode' }}
            radioProps={options}
          />
        )}
      </HookFormRadioGroupWrapper>

      {watchUseAbsoluteMode === 'false' && (
        <HookFormInputWrapper name="regex" control={control}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isDirty={isDirty}
              labelWidth="none"
              inputWidth="medium"
              feedback={feedback}
              inputProps={{ ...formProps, id: 'input-regex' }}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default MatchCallerIdDialog;
