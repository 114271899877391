import {
  service as advancedProvisionerApi,
  TAGS as ADV_PROV_TAGS,
} from 'services/advancedProvisioner';
import { bizProvApi } from 'services/bizProv';
import { iamApi, TAGS as IAM_TAGS } from 'services/iam';
import { kazooApi, TAGS } from 'services/kazoo';
import {
  service as legacyProvisionerApi,
  TAGS as LEGACY_PROV_TAGS,
} from 'services/legacyProvisioner';
import { accountSlice } from './Account';
import { authSlice } from './Auth';
import { undoableCallflowReducer } from './Callflow/store/slice';
import { deviceSlice } from './Device/slice';

export const reducer = {
  auth: authSlice.reducer,
  account: accountSlice.reducer,
  callflows: undoableCallflowReducer,
  device: deviceSlice.reducer,
  [advancedProvisionerApi.reducerPath]: advancedProvisionerApi.reducer,
  [bizProvApi.reducerPath]: bizProvApi.reducer,
  [iamApi.reducerPath]: iamApi.reducer,
  [kazooApi.reducerPath]: kazooApi.reducer,
  [legacyProvisionerApi.reducerPath]: legacyProvisionerApi.reducer,
};

// Utilities that put in this object will be automatically triggered when switching accounts
export const invalidateCacheUtilities = {
  kazoo: kazooApi.util.invalidateTags(Object.values(TAGS)),
  iam: iamApi.util.invalidateTags(Object.values(IAM_TAGS)),
  advancedProvisioner: advancedProvisionerApi.util.invalidateTags(Object.values(ADV_PROV_TAGS)),
  provisioner: legacyProvisionerApi.util.invalidateTags(Object.values(LEGACY_PROV_TAGS)),
};
