import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import uniqBy from 'lodash/uniqBy';
import { useFetchCallflowsByOwnerUsernameQuery } from 'models/Callflow';
import { useFetchSMSByUserIdQuery } from 'models/SMS';
import { useFetchUsersQuery } from 'models/User';
import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DialogActionsCloseReasons } from 'shared/components/Dialog/definition';
import Loading from 'shared/components/Loading';
import { TEMP_PROPERTY } from '../../..';
import { FormInput } from '../../../definition';
import { CREATING_SEAT_USERNAME_FALLBACK_KEY, SEARCH_PARAMS } from './constants';
import SMSListView from './presenter';
import { createNumbersSet } from './utility';

type Props = TabPanelProps<FormInput>;

const SMSListViewContainer: FunctionComponent<Props> = React.memo(() => {
  const { id = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { reset, setValue, getValues, watch } = useFormContext();

  const isCreatingSeat = id === ADD_KEY;

  const { data: smsData, isLoading: isSMSDataLoading } = useFetchSMSByUserIdQuery(
    { id },
    { skip: isCreatingSeat },
  );
  const { data: usersData } = useFetchUsersQuery();

  const seatUsername = getValues('seat.username');
  const { data: callflowData } = useFetchCallflowsByOwnerUsernameQuery(
    { username: seatUsername },
    { skip: isCreatingSeat || !seatUsername },
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
  const navigate = useNavigate();

  const addSmsBoxParam = searchParams.get(SEARCH_PARAMS.ADD_SMS_BOX);

  // Transform data for table
  const data = useMemo(
    () => smsData?.map((smsbox) => ({ ...smsbox, phone_number: smsbox.numbers[0] })),
    [smsData],
  );

  // Open creation dialog when add search param is 'true'
  useEffect(() => {
    if (addSmsBoxParam === 'true') {
      setIsCreateDialogOpen(true);
    }
  }, [addSmsBoxParam]);

  const seatPhoneNumber = watch('seat.phone_number');
  const availableDIDS = watch(`seat.${TEMP_PROPERTY.AVAILABLE_DIDS_FOR_SMS}`) || [];

  // Phone numbers avaliable for creating a new SMS box
  useEffect(() => {
    const numbersSet = createNumbersSet(callflowData, availableDIDS);

    // Build list of phone numbers with SMS box linked already to be filtered out
    const phoneNumbersWithSMSSet = new Set<string>();
    data?.forEach((smsbox: SMS) => {
      phoneNumbersWithSMSSet.add(smsbox.phone_number || '');
    });

    // Build list of phone numbers created locally to be filtered out
    Object.values<SMS>(getValues('sms') || {}).forEach((smsbox) => {
      phoneNumbersWithSMSSet.add(smsbox?.phone_number || '');
    });

    const availablePhoneNumbersSet = new Set<string>();
    numbersSet.forEach((number) => {
      if (number.startsWith('+') && !phoneNumbersWithSMSSet.has(number)) {
        availablePhoneNumbersSet.add(number);
      }
    });

    const availablePhoneNumbers = Array.from(availablePhoneNumbersSet);

    setValue(`seat.${TEMP_PROPERTY.AVAILABLE_DIDS_FOR_SMS}`, availablePhoneNumbers);
  }, [data, seatPhoneNumber, callflowData]);

  const handlePhoneNumberSelect = (e: ChangeEvent<HTMLInputElement>, number: string) =>
    setSelectedPhoneNumber(number);

  const handleCreateDialogOpen = () => setIsCreateDialogOpen(true);

  const handleCreateDialogClose = () => {
    setIsCreateDialogOpen(false);
    navigate('.');
  };

  /** Create new sms box locally (i.e., add to form state) and navigate to it */
  const createAndRedirectToNewSMSBox = () => {
    // For multiple phone number, use the selected phone number. For single phone number, use the only one number.
    const _selectedPhoneNumber = availableDIDS.length > 1 ? selectedPhoneNumber : availableDIDS[0];
    const newSMSId = `${ADD_KEY}${_selectedPhoneNumber}`;

    reset(
      (formValues) => ({
        ...formValues,
        sms: {
          ...formValues.sms,
          [newSMSId]: {
            phone_number: _selectedPhoneNumber,
            owner: !isCreatingSeat ? id : CREATING_SEAT_USERNAME_FALLBACK_KEY,
            shared_box: false,
          },
        },
      }),
      { keepDirty: true, keepErrors: true },
    );
    setValue(`sms.${newSMSId}.id`, newSMSId, { shouldDirty: true });
    navigate(newSMSId);
  };

  const handleCreateDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        handleCreateDialogClose();
        break;
      case 'saveClicked':
        if (!availableDIDS.length) {
          handleCreateDialogClose();
        } else {
          createAndRedirectToNewSMSBox();
        }
        break;
      default:
        break;
    }
  };

  if (isSMSDataLoading) {
    return <Loading />;
  }

  const tableData =
    uniqBy([...((data ?? []) as any[]), ...Object.values(getValues('sms'))], 'phone_number')
      .filter((item) => !getValues(`sms.${item.id}._toDelete`))
      .map((item) => {
        const _item = { ...item };
        if (!isCreatingSeat && _item.owner !== id) {
          _item.hideEditIcon = true;
        }
        return _item;
      }) || [];

  return (
    <SMSListView
      tableData={tableData}
      phoneNumbers={availableDIDS}
      usersData={usersData || []}
      selectedPhoneNumber={selectedPhoneNumber}
      isCreateDialogOpen={isCreateDialogOpen}
      handlePhoneNumberSelect={handlePhoneNumberSelect}
      handleCreateDialogOpen={handleCreateDialogOpen}
      handleCreateDialogClose={handleCreateDialogClose}
      handleCreateDialogAction={handleCreateDialogAction}
      hasActionRow={false}
    />
  );
});

export default SMSListViewContainer;
