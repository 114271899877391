import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTimeZoneSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, TimeOfDayDialogProps as Props, TimeOfDayNodeData } from './definition';
import schema from './schema';

const TimeOfDayDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState, getValues } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const nodeData: TimeOfDayNodeData = {
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.time_of_day.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormTimeZoneSelectWrapper
        label={t(
          'phone_system:containers.callflows.callflow_action_dialog.time_of_day.add.timezone',
        )}
        name="timezone"
        control={control}
      />
    </CallflowActionsDialog>
  );
};

export default TimeOfDayDialog;
