import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { AgentResumeDialogProps as Props, AgentResumeNodeData, Data } from './definition';
import schema from './schema';

const AgentResumeDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const presenceId = t(
      'phone_system:containers.callflows.callflow_action_dialog.agent_resume.add.presence_id',
    );
    const nodeData: AgentResumeNodeData = {
      metadata: {
        name: `${presenceId}: ${formData.presence_id}`,
        pvt_type: 'acdc_agent_resume',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      title={t('phone_system:containers.callflows.callflow_action_dialog.agent_resume.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="presence_id" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.agent_resume.add.presence_id',
            )}
            inputWidth="small"
            inputProps={{
              ...formProps,
              id: 'input-agent-resume-presence-id',
            }}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default AgentResumeDialog;
