import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, PivotDialogProps as Props, PivotNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const PivotDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm<Data>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { method, methods, req_format, req_formats, voice_url, title } = useMemo(
    () => translations(),
    [],
  );

  const submitHandler = (formData: Data) => {
    const nodeData: PivotNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper name="voice_url" control={control}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={voice_url.label}
            inputWidth="xlarge"
            inputProps={{
              ...formProps,
              id: 'input-pivot-add-voice-url',
            }}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper name="method" control={control} options={methods}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={method.label}
            selectProps={{
              ...formProps,
              id: 'select-pivot-add-method',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper name="req_format" control={control} options={req_formats}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isDirty={isDirty}
            feedback={feedback}
            isLabelAbove
            label={req_format.label}
            selectProps={{
              ...formProps,
              id: 'select-pivot-add-req-format',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default PivotDialog;
