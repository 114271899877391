import { joiResolver } from '@hookform/resolvers/joi';
import { metaAdvancedReporting } from 'apps/meta';
import { KOSHER_APPS } from 'apps/shared/constant';
import { KosherApp } from 'apps/shared/definition';
import withNavigation from 'apps/shared/hocs/withNavigation';
import { useExitConfirmationDialog } from 'apps/shared/hooks/useExitConfirmationDialog';
import { ADD_KEY, DESKTOP_APP_DEFAULT_ZONE } from 'constant';
import produce from 'immer';
import Joi from 'joi';
import merge from 'lodash/merge';
import {
  useCreateAccountByIdMutation,
  useFetchAccountByIdQuery,
  useFetchAccountDesktopAppZoneQuery,
  useFetchAccountHeroAppsQuery,
  useUpdateAccountByIdMutation,
  useUpdateAccountDesktopAppZoneByIdMutation,
  useUpdateAccountHeroAppsByIdMutation,
} from 'models/Account';
import { selectAccountId } from 'models/Account/slice';
import { useFetchAppsQuery, useUpdateAppsBlocklistMutation } from 'models/AppsStore';
import { useCreateByAccountIdMutation } from 'models/Callflow';
import { CALLFLOW_ACTION } from 'models/Callflow/constants';
import { useFetchNotificationByIdQuery, useUpdateNotificationMutation } from 'models/Notification';
import { FunctionComponent, useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAccount from 'shared/hooks/useAccount';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import TEST_ID from 'shared/utility/testing/constants/testId';
import { messages } from 'shared/utility/validation';
import EditForm from '../../../components/EditForm';
import { EditFormProps as Props } from '../../../definition';
import { schema as basicsSchema } from './components/section/BasicsSection';
import { schema as callerIdSchema } from './components/section/CallerIdSection/schema';
import { schema as contactsSchema } from './components/section/ContactsSection';
import { schema as notificationsSchema } from './components/section/NotificationsSection';
import { defaultValues } from './default';
import { App, FormInput } from './definition';
import Form from './Form';
import { AccountField, useAccountInfo } from './hooks/useAccountInfo';
import { useHandleSaveError } from './hooks/useHandleSaveError';
import {
  deleteProperties,
  onAccountSubmitSuccess,
  TEMP_PROPERTY,
  TEMP_PROPERTY_TRANSFORMERS,
  transformAccountData,
  transformAccountDataToFormState,
  updateSection,
  useFetch,
} from './utility';

/**
 * These additional (and temporary) account data object properties
 * are used to facilitate value gathering/validation, etc.
 */
export { TEMP_PROPERTY, TEMP_PROPERTY_TRANSFORMERS };

const Edit = ({ id: accountId = ADD_KEY, handleSaveSuccess }: Props) => {
  const { t, i18n } = useTranslation();
  const { showToast } = useToast();
  const { handleSaveError } = useHandleSaveError();
  const parentId = useSelector<string, string>(selectAccountId);

  const [createAccount] = useCreateAccountByIdMutation();
  const [createCallflow] = useCreateByAccountIdMutation();
  const [updateAccount] = useUpdateAccountByIdMutation();
  const [updateAccountHeroApps] = useUpdateAccountHeroAppsByIdMutation();
  const [updateAppsStoreBlocklist] = useUpdateAppsBlocklistMutation();
  const [updateNotification] = useUpdateNotificationMutation();
  const [updateAccountDesktopAppZone] = useUpdateAccountDesktopAppZoneByIdMutation();
  const { isAccountFieldUnique } = useAccountInfo(accountId);

  const { isSuperDuper } = useAccount();
  const isAdd = accountId === ADD_KEY;

  const data: Record<string, any> = {};
  useFetch({
    accountId,
    parentId,
    data,
    hook: {
      useFetchAccountByIdQuery,
      useFetchAccountHeroAppsQuery,
      useFetchAppsQuery,
      useFetchNotificationByIdQuery,
      useFetchAccountDesktopAppZoneQuery,
    },
  });

  const formMethods = useForm<FormInput>({
    reValidateMode: 'onSubmit',
    defaultValues: merge({}, defaultValues, isSuperDuper ? { account_type: '' } : {}),
    resolver: joiResolver(
      Joi.object({
        ...basicsSchema,
        ...contactsSchema,
        ...notificationsSchema,
        ...callerIdSchema(),
      })
        .unknown(true)
        .messages(messages()),
    ),
  });

  const {
    formState: { dirtyFields },
    getValues,
    handleSubmit,
    reset,
    setError,
    trigger,
  } = formMethods;
  const isPageDirty = checkFormEntities(dirtyFields);

  useExitConfirmationDialog({ isDirty: isPageDirty });

  useEffect(() => {
    if (!isAdd && accountId) {
      const transformedAccountData = transformAccountDataToFormState(data.account);

      reset(
        merge({}, getValues(), transformedAccountData, {
          [TEMP_PROPERTY.NOTIFICATIONS]: data.notification,
        }),
      );
    }

    if (isAdd && data.notification?.deregister) {
      reset(
        merge({}, getValues(), {
          [TEMP_PROPERTY.NOTIFICATIONS]: data.notification,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    data.account,
    data.notification.deregister,
    data.notification.fax_inbound_error_to_email,
    data.notification.voicemail_to_email,
    getValues,
    reset,
  ]);

  useEffect(() => {
    if (data.accountHeroApps) {
      const heroAppsIsEnabled = data.accountHeroApps?.reduce(
        (acc: { [key: string]: boolean }, app: App) => {
          acc[app.id] = !isAdd ? app.available : false;
          return acc;
        },
        {},
      );

      const heroApps = data.accountHeroApps.map((app: App) => ({
        ...app,
        available: !isAdd ? app.available : false,
      }));

      reset(
        merge(
          {},
          getValues(),
          { [TEMP_PROPERTY.HERO_APPS]: heroApps },
          { [TEMP_PROPERTY.IS_IN_HERO_APPS]: heroAppsIsEnabled },
        ),
      );
    }
  }, [data.accountHeroApps, getValues, reset, isAdd]);

  useEffect(() => {
    reset(
      merge({}, getValues(), {
        [TEMP_PROPERTY.DESKTOP_APP_ZONE]: data.heroConfig?.default_zone ?? DESKTOP_APP_DEFAULT_ZONE,
      }),
    );
  }, [data.heroConfig]);

  useEffect(() => {
    if (accountId) {
      // filter apps to display
      let appsStore = data.appsStoreCurrentAccount?.filter(({ name }: any) =>
        KOSHER_APPS.some((app: KosherApp) => name === app.name && app.isEnabled),
      );

      // Temporary fix for overriding advanced reporting app name. Should be revisit once the Kazoo app structure is implemented.
      const advancedReportingIdx = appsStore?.findIndex(
        (app: any) => app.name === metaAdvancedReporting.name,
      );
      if (advancedReportingIdx > -1) {
        appsStore = produce(appsStore, (draft: any) => {
          const app = draft[advancedReportingIdx];
          app.i18n[i18n.language].label = metaAdvancedReporting.i18n.label;
        });
      }

      // set flag for each app currently in the account "apps_store" object
      const appsStoreIsEnabled = data.appsStoreCurrentAccount?.reduce((acc: any, app: any) => {
        acc[app.id] = data.appsStoreAccount?.some((_app: any) => _app.id === app.id);
        return acc;
      }, {});

      reset(
        merge(
          {},
          getValues(),
          { [TEMP_PROPERTY.APPS_STORE]: appsStore },
          { [TEMP_PROPERTY.IS_IN_APPS_STORE]: appsStoreIsEnabled },
        ),
      );
    }
  }, [
    accountId,
    data.appsStoreAccount,
    data.appsStoreCurrentAccount,
    i18n.language,
    getValues,
    reset,
  ]);

  const onSubmit: SubmitHandler<FormInput> = async (formData: FormInput) => {
    const { name, realm } = formData;
    let id = accountId;

    const isUnique = {
      [AccountField.Name]: isAccountFieldUnique(AccountField.Name, name),
      [AccountField.Realm]: isAccountFieldUnique(AccountField.Realm, realm),
    };

    if (isUnique.name && isUnique.realm) {
      if (isAdd && (await trigger())) {
        const response: Account = await createAccount({
          id: parentId,
          body: { name },
        });

        if (response.data) {
          id = response.data.data.id;
          await createCallflow({
            accountId: id,
            body: CALLFLOW_ACTION.NO_MATCH.DATA,
          });
          await createCallflow({
            accountId: id,
            body: CALLFLOW_ACTION.CONFERENCE_SERVICE.DATA,
          });
        } else {
          handleSaveError({ errorData: response.error.data.data, setError });
        }
      }

      await updateSection.desktopModules({
        id,
        data: formData,
        mutation: updateAccountHeroApps,
      });

      await updateSection.notifications({
        id,
        data: formData,
        dirtyFields,
        mutation: updateNotification,
      });

      await updateSection.desktopAppZone({
        id,
        data: formData,
        dirtyFields,
        heroConfigData: data.heroConfig,
        mutation: updateAccountDesktopAppZone,
      });

      // transform temporary properties
      const transformedAccountData = transformAccountData(formData);

      // remove temporary properties
      const accountData = deleteProperties(transformedAccountData);

      const response: Account = await updateAccount({ id, body: accountData });

      if (response.data) {
        await updateSection.appExchange({
          id,
          data: formData,
          apps: data.appsStoreCurrentAccount,
          mutation: updateAppsStoreBlocklist,
        });

        reset(
          merge(formData, {
            announcement: accountData.announcement,
            custom_notes: accountData.custom_notes,
          }),
        );

        if (isAdd) {
          showToast.success(
            t('accounts_manager:containers.accounts.toast.success.new_account_created'),
          );
        }
        onAccountSubmitSuccess({ handleSaveSuccess, isAdd });
      } else {
        handleSaveError({ errorData: response.error.data, setError });
      }
    } else {
      handleSaveError({ isUnique, setError });
    }
  };

  const onError: SubmitErrorHandler<FormInput> = (error) => {
    showToast.error();
    console.error('**Form Error**', JSON.stringify(error));
  };

  return (
    <EditForm
      isAdd={isAdd}
      isPageDirty={isPageDirty}
      entityLabel={t('accounts_manager:containers.accounts.label')}
      entityName={
        isAdd
          ? t('accounts_manager:containers.accounts.breadcrumb.new_account')
          : data.account?.name
      }
      onSave={handleSubmit(onSubmit, onError)}
      formMethods={formMethods}
      data-test-id={TEST_ID.ACCOUNTS_MANAGER.EDIT.CONTAINER}
    >
      <Form id={accountId} />
    </EditForm>
  );
};

export default withNavigation(Edit as FunctionComponent);
