import { HookFormRichTextEditorWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionProps as Props } from '../../../definition';
import { FORM_STATE_NAME, TEMP_PROPERTY } from '../../../utility';
import { StyledSection } from './style';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields: string[] = [TEMP_PROPERTY.NOTES, TEMP_PROPERTY.ANNOUNCEMENT];

const NotesSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <StyledSection>
        <h2>{t('accounts_manager:containers.accounts.section.notes.heading.announcement')}</h2>
        <p>{t('accounts_manager:containers.accounts.section.notes.sub_heading.announcement')}</p>
        <div role="row" className="one-column">
          <div role="cell">
            <HookFormRichTextEditorWrapper
              richTextStateName={`${TEMP_PROPERTY.ANNOUNCEMENT}`}
              name={FORM_STATE_NAME.ANNOUNCEMENT}
            />
          </div>
        </div>
      </StyledSection>
      <StyledSection>
        <div>
          <h2>{t('accounts_manager:containers.accounts.section.notes.heading.notes')}</h2>
          <p>{t('accounts_manager:containers.accounts.section.notes.sub_heading.notes')}</p>
          <div role="row" className="one-column">
            <div role="cell">
              <HookFormRichTextEditorWrapper
                richTextStateName={`${TEMP_PROPERTY.NOTES}`}
                name={FORM_STATE_NAME.NOTES}
              />
            </div>
          </div>
        </div>
      </StyledSection>
    </>
  );
};

export default NotesSection;
