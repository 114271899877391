import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { buildCallflowName } from 'models/Callflow/utility';
import { useFetchUsersQuery } from 'models/User';
import { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallflowCallflowDialogProps as Props, CallflowNodeData, Data } from './definition';
import schema from './schema';
import { buildCallflowOptions } from './utility';

const CallflowDialog: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { data, onSave, onClose }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const { id } = useParams();
  const [options, setOptions] = useState<SelectOption[]>([]);
  const { data: callFlowData, isLoading } = useFetchCallflowsQuery();
  const { data: usersData } = useFetchUsersQuery();
  const { handleSubmit, control, formState } = useForm<Data>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });
  const { isDirty } = formState;

  const submitHandler = (formData: Data) => {
    const selected = callFlowData?.find(({ id }) => id === formData.id);
    const nodeData: CallflowNodeData = {
      metadata: {
        name: buildCallflowName(selected, usersData),
        pvt_type: 'callflow',
      },
      data: formData,
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    if (callFlowData) {
      setOptions(buildCallflowOptions(callFlowData));
    }
  }, [callFlowData]);

  return (
    <CallflowActionsDialog
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.callflow.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="id" control={control} options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            feedback={feedback}
            isDirty={isDirty}
            isLabelAbove
            isWindowed
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.callflow.add.callflow',
            )}
            selectProps={{
              options: callFlowData?.map((item: any) => ({
                value: item.id,
                label: buildCallflowName(item),
              })),
              ...formProps,
              id: 'select-callflow-add-callflow',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default CallflowDialog;
